﻿/// <reference path = "../../main.scss" />
// File Name: _search-page.scss
// Description: Styles for the Search Page
// ------------------------------------------------------------

.cityLocation, .searchNoLocationLandingPage {
    main > .content-grid .content-body {
        min-height: 15px;
    }

    .horizontalIconListCTA-section {
        .horizontalIconListCTA {
            .horizontal-icon-list-cta-widget {
                padding: 0 !important;

                .list {
                    margin-bottom: 0;
                }
            }
        }
    }

    .under-banner {
        margin-top: 125px;

        .headline-under-banner {
            text-align: center;
            font-size: 46px;
            font-weight: 800;
            line-height: 1.2;
        }

        .paragraph-under-banner {
            text-align: center;
            margin-top: 60px;
        }
    }

    @include media-breakpoint-down(md) {
        .under-banner {
            margin-top: 15px;

            .headline-under-banner {
                p {
                    line-height: 1.1;
                    font-size: 38px;
                }
            }

            .paragraph-under-banner {
                margin-top: 40px;
                font-size: 16px;
                line-height: 1.5em;
            }
        }
    }

    .after-content {
        background-color: unset;
        margin-bottom: 0;
    }
}