﻿///<reference path="../../main.scss" />
// File Name: _multicolumn-bg-grid-editor.scss
// Description: Styles Multicolumn BG Grid Editor
// ------------------------------------------------------------

.multiColumnBackgroundCTA-section {
    > .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        > .body {
            margin-left: 0;
            margin-right: 0;

            > .column.column-12 {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.multicolumn-background-cta {
    position: relative;

    .bg-image {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background-size: cover;
        background-position: center center;
    }

    .bg-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background-color: $brand-blue-darkest-blue;
        opacity: 0.78;
    }

    .inner-content {
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;
        z-index: 1;

        @include media-breakpoint-down(sm) {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    .row-multicolumn-bg .multicolumn-column:last-child {
        margin-top: 30px;
    }

    .row-multicolumn-bg {
        @include make-row();

        .multicolumn-column {
            margin-bottom: 30px;

            @include media-breakpoint-up(xs) {
                @include make-col(12);
            }

            @include media-breakpoint-up(lg) {
                @include make-col(6);
                margin-bottom: 0;
                margin-top: 0;
            }

            @include make-col-ready();
            //Content Column
            &.content-column {
                .headline {
                    margin-bottom: 1.5rem;

                    span.line {
                        display: block;
                    }

                    @include media-breakpoint-down(md) {
                        text-align: center;

                        span.line {
                            display: inline-block;
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: 40px;
                    }
                }

                .text {
                    min-height: 148px;

                    p {
                        font-size: 20px;

                        a {
                            color: $brand-blue-lighter;
                            text-decoration: underline;

                            &:hover {
                                color: $brand-blue-mint-tulip;
                            }
                        }
                    }

                    ul {
                        margin-top: 55px;

                        li {
                            line-height: 1.05em;
                            margin-bottom: 1.0em;

                            &::before {
                                transform: translateY(-10%);
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        p {
                            font-size: 18px;
                            line-height: 1.5em;
                            margin-bottom: 25px;
                        }

                        ul {
                            margin-top: 0px;

                            li {
                                line-height: 1.05em;
                                padding-bottom: 0px;
                                display: block;
                                width: 100%;

                                &:before {
                                    top: 1px;
                                    position: relative;
                                }

                                &:last-child {
                                    margin-bottom: 1.2em;
                                }
                            }
                        }
                    }

                    .list-as-two-columns-separate {
                        margin-bottom: 2rem;
                        margin-top: 55px;

                        ul.list-column {
                            margin-bottom: 0;
                            width: 50%;
                            float: left;
                            padding-left: 0;
                            margin-top: 0;

                            @include media-breakpoint-down(lg) {
                                width: 50%;
                            }

                            @include media-breakpoint-down(xs) {
                                width: 100%;
                            }

                            li {
                                line-height: 1.05em;
                                margin-bottom: 1.0em;

                                &::before {
                                    transform: translateY(-10%);
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        &::after {
                            content: " ";
                            clear: both;
                            display: block;
                        }

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 2rem;
                            margin-top: 2rem;

                            ul.list-column {
                                margin-bottom: 0;
                                margin-top: 0;

                                li {
                                    line-height: 1.05em;
                                    padding-bottom: 0px;
                                    display: block;
                                    width: 100%;

                                    &:before {
                                        top: 1px;
                                        position: relative;
                                    }

                                    &:last-child {
                                        margin-bottom: 1.2em;
                                    }
                                }
                            }
                        }
                    }
                }

                .buttons {
                    margin-top: 70px;

                    .btn-blue {
                        padding: 10px 40px;
                        font-size: 18px;
                    }
                }
            }

            &.list-column {
                .list-item {
                    display: flex;
                    margin-bottom: 70px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .col-list-image {
                        min-width: 90px;
                        background-color: $white;
                        border-radius: 50%;
                        height: 90px;
                        width: 90px;
                        padding: 15px;
                        margin-right: 30px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .col-list-text {
                        .title {
                            font-weight: 700;
                            font-size: 24px;
                            line-height: 1.20em;
                            margin-bottom: 0;
                        }

                        .text {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: 1.5em;
                        }
                    }
                }
            }

            &.form-column {
                margin-top: 0;

                .form-title {
                    font-size: 24px;
                }

                form {

                    .umbraco-forms-page {
                        margin-bottom: 0;
                    }

                    .umbraco-forms-page .umbraco-forms-indicator {
                        color: $brand-red;
                    }

                    input[type="submit"] {
                        background-color: $brand-mariner-blue;
                        color: $white;
                        font-weight: 500;
                        transition: .2s;

                        &:hover {
                            background-color: $brand-deep-koamaru;
                            color: $white;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    form, .form-title {
                        max-width: 432px;
                        margin-left: auto;
                    }
                }
            }
        }
        //sibling-based column sizing rules
        &.multiColumnBGItem-type.listIconContentListNestedContent-type {
            .multicolumn-column.content-column {
                @include media-breakpoint-up(lg) {
                    flex: 0 0 55.0%;
                    max-width: 55.0%;
                    padding-right: 40px;
                }
            }

            .multicolumn-column.list-column {
                @include media-breakpoint-up(lg) {
                    flex: 0 0 45.0%;
                    max-width: 45.0%;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .row-multicolumn-bg .multicolumn-column.content-column .text {
            min-height: 0;
        }

        .row-multicolumn-bg .multicolumn-column.list-column .list-item {
            margin-bottom: 30px;
        }

        .row-multicolumn-bg .multicolumn-column.content-column .buttons {
            margin-top: 0px;

            .btn {
                width: 100%;
            }
        }
    }

    &.has-bg-image {
        background-color: $brand-dark-gray;

        .multicolumn-column {
            color: $white;

            .headline {
                color: $white;
            }

            p {
                color: $white;
            }

            &.form-column {

                .form-title {
                    color: $white;
                }

                form {
                    color: $white;

                    label {
                        color: $white;
                    }

                    .field-validation-error {
                        color: $white;
                    }

                    input[type="text"], textarea {
                        border-color: $white;
                        color: $white;
                        background-color: transparent;
                    }

                    input[type="submit"] {
                        background-color: $brand-anakiwa-blue;
                        color: $black;

                        &:hover {
                            background-color: $brand-mariner-blue;
                            color: $white;
                        }
                    }
                }
            }

            &.list-column {
                .text {
                    a {
                        color: $brand-malibu-blue;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
