﻿.btn {
    transition: .3s;

    &:hover {
        color: $white;
        text-decoration: none;
    }

    &:focus {
        box-shadow: 0 0 0 2px rgba(77, 144, 254, 0.5);
    }
} 

.btn-green {
    @include btn-green;
}

.btn-red {
    @include btn-red;
}
/* ada */
.bg-f3f3f3 .btn-red, .bg-dcebef .btn-red {
    background-color: $brand-red-on-gray-blue;

    &:hover {
        background-color: $brand-red-on-gray-blue-hover;
    }
}

.btn-blue, .btn-mariner-blue, .btn-2f80d2 {
    @include btn-blue;
}

.btn-lightblue, .btn-anakiwa-blue, .btn-74e0ff {
    @include btn-light-blue;
}

.btn-darkblue, .btn-deep-koamaru-blue, .btn-12317b, .btn-darkblue-wt {
    @include btn-darkblue;
}


.btn-darkblue-outline {
    @include btn-darkblue-outline;
}
.btn-lightblue-outline {
    @include btn-lightblue-outline;
}

.banner-button-parent > div > div > a {
    vertical-align: middle !important;
    display: table-cell !important;
}

.banner-button-parent > div > div {
    margin-left: 10px;
    margin-right: 10px;
}

