﻿///<reference path="../../main.scss" />
// File Name: _search-landing-location-widget-grid-editor.scss
// Description: Search Landing Location Widget Grid Editor
// ------------------------------------------------------------

.searchLandingLocationWidget {
    .search-landing-location-widget-container {
        padding-top: 85px;
        padding-bottom: 75px;

        @include media-breakpoint-down(md) {
            padding-top: 35px;
            padding-bottom: 35px;
        }

        .body-section {
            display: flex;
            margin-top: 75px;

            @include media-breakpoint-down(md) {
                display: block;
                margin-top: 15px;
            }

            .subtitle {
                margin: 0;
                padding-bottom: 15px;
                font-size: 30px;

                @include media-breakpoint-down(md) {
                    text-align: center;
                    line-height: 1.1;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }

            .mobile-img-wrapper {
                display: none;

                > img {
                    width: 100%;
                    height: auto;
                }

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }

            .two-columns {
                display: flex;
                padding: 15px 0 25px 0;

                > div {
                    width: 50%;
                    display: inline-block;
                }

                ul {
                    display: inline-block;
                    padding-left: 0;

                    li {
                        padding-left: 35px;
                        line-height: 1.85;
                    }
                }
            }

            @include media-breakpoint-down(sm) {
                .two-columns {
                    display: block;

                    > div {
                        width: 100%;
                    }
                }
            }

            .bottom-text, .btn-wrapper {
                text-align: center;
            }

            .btn {
                font-size: 20px;
                font-weight: 600;
                min-width: 350px;

                @media (max-width: 400px) {
                    min-width: 300px;
                }
            }

            .bottom-text {
                font-weight: 700;
                color: $brand-mariner-blue;
                padding-bottom: 15px;
            }

            > div {
                display: inline-block;
            }

            @include media-breakpoint-down(md) {
                .btn-wrapper {
                    padding-bottom: 0;
                }

                .bottom-text {
                    //font-size: 16px;
                    line-height: 1.2;
                }
            }
        }

        &.img-right {
            .body-section {
                .left-col {
                    @include make-col(8);
                    @include make-col-ready();
                }

                .right-col {
                    @include make-col(4);
                    @include make-col-ready();
                }
            }
        }

        &.img-left {
            .body-section {
                .left-col {
                    @include make-col(4);
                    @include make-col-ready();
                }

                .right-col {
                    @include make-col(8);
                    @include make-col-ready();
                }
            }
        }

        @include media-breakpoint-down(md) {
            &.img-right {
                .body-section {
                    .left-col {
                        @include make-col(12);
                    }

                    .right-col {
                        @include make-col(12);
                        @include make-col-ready();
                    }
                }
            }

            &.img-left {
                .body-section {
                    .left-col {
                        @include make-col(12);
                    }

                    .right-col {
                        @include make-col(12);
                    }
                }
            }
        }

        .title-section {
            .title {
                text-align: center;
                font-size: 46px;
                font-weight: 800;
                line-height: 1.2;

                @include media-breakpoint-down(md) {
                    line-height: 1.1;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 38px;
                }
            }
        }

        .squareImage {
            .square-image {
                margin: 0;

                img {
                    width: 100%;
                    height: auto;
                }

                .image-fit {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    background-size: cover;
                    background-position: center center;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .squareImage {
                display: none;
            }
        }
    }
}
.searchLandingLocationWidget-section.bg-0e283f {
    .searchLandingLocationWidget .search-landing-location-widget-container .body-section .bottom-text {
        color: $brand-malibu-blue;
    }
}