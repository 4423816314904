﻿/// <reference path = "../../main.scss" />
// File Name: _nav-primary.scss
// Description: Primary navigation view styles
// ------------------------------------------------------------
header {
    position: fixed;
    background-color: $white;
    width: 100%;
    z-index: 3;
    height: 105px;
    top: 0;
    font-family: $headings-font-family;

    @include media-breakpoint-down(md) {
        height: 43px;
        border-bottom: 1px solid $brand-super-light-gray2;
    }

    .nav-utility, #primary-collapsing-navbar {
        padding-right: 30px;
    }

    .nav-utility {
        z-index: 10000;

        .search-bar {
            display: flex;
            align-items: center;
            background-color: $white;

            form > * {
                height: 34px;
            }

            .dates {
                display: flex;
                align-items: center;
                height: 32px;
            }

            .date-start-span, .date-end-span {
                position: relative;
                display: flex;
                align-items: center;


                .fa {
                    position: absolute;
                    color: $black;
                    font-size: 14px;
                    margin-left: 10px;
                    pointer-events: none;
                    transition: 0.3s color;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover {
                    .fa {
                        color: $black;
                    }
                }

                &.selected {
                    .fa {
                        color: $black;
                    }
                }
            }

            .date-start-span {
                margin-left: 0px;
            }

            .date-end-span {
                margin-left: 5px;
                margin-right: 5px;
            }

            .text-location-span {
                position: relative;
                border-radius: 8px 0 0 8px;
                border: 1px solid $brand-form-gray;
                width: 280px;

                .text-location {
                    width: 100%;
                    height: 100%;
                    border: 1px solid transparent;

                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 2px rgba(77, 144, 254, 0.5);
                        border-radius: 7px 0 0 7px;
                    }
                }

                .icon {
                    position: absolute;
                    top: 9px;
                    width: 15px;
                    height: 14px;
                    left: 20px;
                    pointer-events: none;
                    background-image: url("/dist/img/map-point-black.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    color: $black;
                }
            }

            .location-state-holder {
                position: relative;
                border: 1px solid $brand-form-gray;
                border-left: 0;

                .location-state {
                    background-size: 20px;
                    background-position: 20px center;
                    background-repeat: no-repeat;
                    height: 100%;
                    -webkit-appearance: none;
                    appearance: none;
                    padding: 5px 25px 5px 6px;
                    font-size: 14px;
                    color: $brand-dark-gray;
                    border: 1px solid transparent;

                    &::-ms-expand {
                        display: none;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: 0 0 0 2px rgba(77, 144, 254, 0.5);
                    }
                }

                .arrow {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    display: inline-block;
                    transform: translateY(-50%);
                    margin-right: 10px;
                    pointer-events: none;

                    &::after {
                        display: inline-block;
                        font-family: "FontAwesome";
                        font-size: 14px;
                        content: "\f0d7";
                        vertical-align: middle;
                    }
                }
            }

            input[type="text"] {
                padding: 6px 6px;
                border: 0;
                margin: 0;
                font-size: 14px;
                color: $black;
                background-color: transparent;
                border-radius: 8px 0px 0px 8px;

                &::placeholder {
                    color: $black;
                }

                &.text-location {
                    padding-left: 37px;
                }

                &.datepicker {
                    padding-left: 30px;
                    width: 114px;
                    letter-spacing: -0.05em;

                    &::placeholder {
                        /* text-align: center;*/
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .separator-arrow {
                width: 21px;
                padding-left: 4px;
                padding-right: 4px;
                margin-top: 0px;

                svg {
                    width: 100%;
                }

                .a {
                    fill: $brand-form-gray;
                    stroke: $brand-form-gray;
                }
            }

            .btn {
                padding: 0px 1rem;
                //padding-left: 0.85rem;
                padding-top: 0px;
                padding-bottom: 0px;
                color: $white;
                border-radius: 0;
                background-color: $brand-deep-koamaru;
                cursor: pointer;
                border-radius: 0px 8px 8px 0px;
                border: 1px solid $brand-deep-koamaru;
                height: 100%;

                .fa {
                    font-size: 14px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-right: 1px;
                    //margin-top: 5px;
                    //margin-bottom: 5px;
                    //height:20px;
                }

                &:hover {
                    background-color: $brand-mariner-blue;
                    border: 1px solid $brand-mariner-blue;
                }
            }

            .border-item {
                border-left: 1px solid $brand-very-light-gray;
                height: 26px;
            }
            /*errors*/
            label.error {
                position: absolute;
                background-color: white;
                padding: 5px 15px;
                border-radius: 0px 0px 5px 5px;
                box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.2784313725490196);
                font-family: $font-family-sans-serif;
                font-size: 14px;
                /* width: 100%; */
                /* text-align: left; */
                top: 100%;
                border-top: 1px solid gainsboro;
            }
        }
    }

    nav.nav-primary {
        margin-top: -45px;
        height: 100%;
        z-index: 1000;

        > nav {
            height: 100%;

            > .nav-wrapper {
                height: 100%;
            }
        }

        nav .navbar-brand {
            padding-bottom: 0.75rem;
            padding-left: 30px;
            display: flex;
            align-items: center;

            .img-fluid {
                width: 184px;
            }

            span {
            }

            @include media-breakpoint-down(md) {
                padding-top: 0.6rem;
                padding-bottom: 0.4rem;
            }
        }

        .nav-wrapper {
            padding-left: 0;
            padding-right: 0;

            .left-side {
                display: flex;
                background-color: $white;
                margin-left: 0px;
                padding-right: 30px;
                padding-left: 0px;
            }

            #primary-collapsing-navbar {
                align-self: flex-end;
                background-color: $white;

                > .navbar-nav {
                    flex-wrap: nowrap;
                }

                .navbar-nav > .nav-item.dropdown > .nav-link:after {
                    font-size: 14px;
                    content: "\f0d7";
                    vertical-align: middle;
                    padding-left: 0.3rem;
                    color: $brand-form-gray;
                }

                .navbar-nav > .nav-item.dropdown.hovered > .dropdown-menu {
                    display: block;
                    opacity: 1;
                }

                .navbar-nav > .nav-item {
                    > a.nav-link {
                        color: $black;
                        font-weight: 600;
                        font-size: 18px;
                        padding: 4px 7px 7px 7px;
                        text-align: center;
                        padding-top: 16px;
                        margin-left: 15px;
                        margin-right: 15px;
                        margin-bottom: 0px;
                        border-bottom: 4px solid transparent;
                        height: 100%;
                        line-height: 1.2 !important;
                        white-space: pre;

                        &:hover {
                            color: $brand-blue-darker-3;
                        }

                        &.current {
                            color: $brand-blue-darker-3;
                        }
                    }

                    &:last-child {
                        > a.nav-link {
                            margin-right: 0px;
                        }
                    }

                    &.dropdown:hover > a.nav-link {
                        color: $brand-blue-darker-3;

                        &::after {
                            color: $brand-blue-darker-3;
                        }
                    }
                }

                .navbar-nav > .nav-item {
                }

                .dropdown {
                    position: inherit;
                }
                /* level 2 */
                .navbar-nav li.nav-item.dropdown {


                    .dropdown-menu {
                        padding: 0;
                        border: none;
                        min-width: 170px;
                        box-shadow: 0px 4px 10px rgba(0,0,0,0.35);
                        display: none;
                        opacity: 0;
                        transition: opacity 0.3s;

                        li.nav-item {
                            &:first-child a {
                                border-radius: 0.25rem 0.25rem 0 0;
                            }

                            &:last-child a {
                                border-radius: 0 0 0.25rem 0.25rem;
                            }

                            a.nav-link {
                                color: $black;
                                text-align: center;

                                &:hover {
                                    color: $brand-blue-darker-3;
                                }

                                &.current {
                                    color: $brand-blue-darker-3;
                                }
                            }
                        }
                    }

                    &:last-child:hover .dropdown-menu {
                        display: block;
                    }
                }
                /* megamenu */
                .navbar-nav li.nav-item.dropdown.megamenu > .dropdown-menu {
                    background-color: $white;
                    width: 100%;

                    .megamenu-container {
                        max-width: none;
                        padding-left: 0;
                        padding-right: 0;

                        .btn {
                            transition: none;
                        }

                        .menu-main {
                            padding-left: 15px;
                            padding-right: 15px;
                        }

                        .menu-locations-bottom {
                            text-align: center;
                            background-color: $brand-blue-lighter-5;

                            .content {
                                display: inline-block;
                                padding: 40px;

                                .left, .right {
                                    display: inline-block;
                                    position: relative;
                                }

                                .left {
                                    padding-right: 20px;
                                }

                                p {
                                    font-size: 26px;
                                    font-weight: bold;
                                    line-height: 1;
                                    margin-bottom: 0;
                                    position: relative;
                                    top: 3px;
                                }

                                .btn {
                                    transition: none;
                                }
                            }
                        }
                    }

                    .megamenu-row {
                        > .col-lg-2, > .col-lg-3, > .col-lg-4, > .col-lg-5 {
                            padding-top: 20px;
                            padding-bottom: 20px;
                            flex: 0 1;
                            max-width: none;
                            display: flex;
                            align-items: flex-start;
                            margin-left: 30px;
                            margin-right: 30px;

                            &:first-child {
                                margin-left: 30px;
                            }

                            &:last-child {
                                margin-right: 0px;
                            }
                        }

                        .dropdown:hover .dropdown-menu {
                            left: 0;
                            top: 0;
                        }

                        .menu-heading {
                            font-size: 16px;
                            font-weight: bold;
                            text-transform: uppercase;
                            margin-bottom: 12px;
                            min-height: 24px;
                        }

                        .dropdown-menu {
                            display: block;
                            position: relative;
                            box-shadow: none;
                            color: $black;

                            li.nav-item {
                                a.nav-link {
                                    text-align: left;
                                    padding: 0.0rem 0 0.0rem 0;
                                    font-weight: 300;
                                    line-height: 1.4em;
                                }
                            }
                        }

                        > .col-lg-6 {
                            padding-left: 0;
                            padding-right: 0;
                        }
                        /*content*/
                        .content-menu-column {
                            padding: 40px 90px;
                            display: flex;
                            height: 100%;
                            width: 100%;
                            align-content: center;
                            justify-content: center;
                            flex-direction: column;

                            &.content-centered {
                                text-align: center;
                            }

                            .content-menu-image {
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                top: 0;

                                > img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: contain;
                                    object-position: center;
                                }
                            }

                            .content-menu-info {
                                position: relative;
                            }

                            &.blueBackground {
                                background-color: $brand-blue-lighter-5;
                            }

                            .content-menu-heading {
                                font-size: 26px;
                                font-weight: 800;
                                margin-bottom: 20px;
                            }

                            .content-menu-text {
                                font-size: 18px;
                                margin-bottom: 20px;
                            }

                            .content-menu-button {
                                a {
                                    text-transform: none;
                                    font-size: 18px;
                                    border-radius: 8px;
                                    padding: 10px 30px;
                                    min-width: 326px;
                                    margin-top: 12px;
                                }
                            }

                            @include media-breakpoint-down(lg) {
                                padding-left: 40px;
                                padding-right: 40px;

                                .content-menu-heading {
                                    line-height: 1.3em;
                                }
                            }
                        }

                        .col-locations-content {
                            padding: 15px 50px 15px 50px;
                        }

                        .content-menu-locations {
                            .heading {
                                font-size: 20px;
                                font-weight: bold;
                                //text-transform: uppercase;
                            }

                            .location-columns {
                                display: flex;
                                flex-wrap: wrap;
                                margin-bottom: 15px;

                                > .location-column {
                                    @include media-breakpoint-up(xs) {
                                        @include make-col(6);
                                    }

                                    @media (min-width: 1400px) {
                                        @include make-col(3);
                                    }

                                    @include make-col-ready();
                                    display: inline-block;
                                    padding-left: 0;

                                    .nav-item {
                                        line-height: 1.5;

                                        > a {
                                            font-size: 16px;
                                            text-align: left;
                                            line-height: 1;
                                            padding: 5px 5px 0 0;
                                            font-weight: 300;

                                            > strong {
                                                font-weight: bold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        /* content multicolumn */
                        .col-multicolumn-content {
                            display: flex;
                            margin-left: 0;
                            margin-right: 0;
                            align-items: center;
                            justify-content: center;

                            .content-menu-multicolumn {
                                @include make-row();
                                justify-content: center;
                                align-items: flex-start;
                                width: 100%;

                                .content-menu-col {
                                    flex: 1 1;
                                    padding-left: 15px;
                                    padding-right: 15px;
                                    min-height: 135px;

                                    .light-heading {
                                        text-align: right;
                                        //padding-left: 2em;
                                        //padding-right: 2em;
                                        font-weight: 300;
                                        text-transform: uppercase;
                                        font-size: 16px;
                                        //margin-top: -2.4em;
                                        padding: 0.5rem 1rem;
                                    }

                                    ul li.nav-item a.nav-link {
                                        text-align: left;
                                        font-weight: 700;
                                        font-size: 16px;
                                        margin-bottom: 6px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media(max-width: 1594px) {
        .nav-utility, #primary-collapsing-navbar {
            padding-right: 50px;
        }

        nav.nav-primary nav .navbar-brand {
            padding-left: 50px;
        }
    }

    @media (max-width: 1594px) {
        nav.nav-primary .nav-wrapper {

            .left-side {
                .search-bar {
                    input[type="text"] {
                        padding: 14px 6px;

                        &.text-location {
                            width: 180px;
                        }

                        &.datepicker {
                            padding-left: 34px;
                            width: 120px;
                        }
                    }

                    .separator-arrow {
                        width: 20px;
                        margin-top: 3px;
                        padding-left: 2px;
                        padding-right: 2px;
                    }

                    .btn {
                        padding-left: 0.75rem;
                        padding-right: 0.75rem;
                    }
                }
            }
        }
    }

    @media (max-width: 1460px) {
        nav.nav-primary {
            .nav-wrapper .left-side {
                padding-left: 0px;
                padding-right: 10px;

                .navbar-brand {
                    margin-right: 20px;
                    /*width: 150px;*/
                }
            }

            .nav-wrapper {
                .left-side {
                    .search-bar {
                        .text-location-span {
                            position: relative;

                            .icon {
                                top: 4px;
                                height: 22px;
                                left: 10px;
                            }
                        }

                        .dates {
                            border-top: 1px solid $brand-super-light-gray;
                        }

                        .separator-arrow {
                            width: 12px;
                            padding-left: 1px;
                            padding-right: 1px;
                        }

                        input[type="text"] {
                            padding: 10px 6px;
                            font-size: 12px;

                            &.text-location {
                                padding-left: 34px;
                            }

                            &.datepicker {
                                padding-left: 34px;
                            }
                        }

                        .btn {
                            padding: 10px 10px 4px 10px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1330px) {
        nav.nav-primary {
            .nav-wrapper {
                .left-side {
                    .search-bar {
                        .form-inline {
                            display: block;

                            .border-item {
                                display: none;
                            }
                        }

                        input[type="text"] {
                            &.text-location {
                                width: 100%;
                            }

                            &.datepicker {
                                padding-left: 34px;
                                width: 104px;
                            }
                        }
                    }
                }

                #primary-collapsing-navbar .navbar-nav > .nav-item > a.nav-link {
                    margin-left: 0px;
                    margin-right: 15px;
                    font-size: 16px;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        nav.nav-primary .nav-wrapper #primary-collapsing-navbar .navbar-nav li.nav-item.dropdown.megamenu > .dropdown-menu .megamenu-row .col-multicolumn-content .content-menu-multicolumn .content-menu-col {
            .light-heading {
                padding-left: 0;
                padding-right: 0;
            }

            ul li.nav-item a.nav-link {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    /* double header */
    @media (min-width: 992px) and (max-width: 1168px) {

        nav.nav-primary {
            .nav-wrapper {
                #primary-collapsing-navbar .navbar-nav > .nav-item > a.nav-link {
                    margin-left: 0px;
                    margin-right: 15px;
                    font-size: 14px;
                }
            }
        }
    }

    @media (max-width: 1102px) {
        nav.nav-primary {
            .nav-wrapper {
                #primary-collapsing-navbar .navbar-nav > .nav-item > a.nav-link {
                    margin-right: 5px;
                }
            }
        }
    }

    @media (max-width: 1078px) {
        nav.nav-primary nav .nav-wrapper .left-side .navbar-brand {
            margin-right: 10px;

            .img-fluid {
                width: 140px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        height: 43px;

        nav.nav-primary {
            margin-top: 0;
            height: 43px;

            nav .navbar-brand {
                padding-top: 5px;
                padding-bottom: 5px;
                height: 100%;

                img {
                    max-height: 100%;
                    max-width: none;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .nav-utility, #primary-collapsing-navbar {
            padding-right: 15px;
        }

        nav.nav-primary nav .navbar-brand {
            padding-left: 15px;
        }
    }
}
.pac-container {
    z-index: 1001;
}