﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the widget callout
// ------------------------------------------------------------

.widgetPicker {
    .callout {
        text-align: center;

        .card-img-top {
            margin-bottom: 30px;

            &.svg img {
                max-width: 85px;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }
        }

        .business-img-top {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;

            &.svg img {
                max-width: 85px;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }
        }

        h4 {
            font-size: 28px;
            color: $black;
            margin-bottom: 24px;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
                margin-bottom: 2px;
            }
        }
    }
}
body.subpage {
    .widgetPicker {
        .widget-picker-callout {
            margin-top: 100px;
            margin-bottom: 120px;
        }
    }

    @include media-breakpoint-down(md) {
        .widgetPicker {
            .widget-picker-callout {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

.widgetPicker {
    .widget-picker-businessCallout {
        .flex-row {
            .inner-content {                
                height: 100%;
                padding-left: 20px;
                padding-right: 20px;
                
                .callout {
                    height: 100%;
                }
            }
        }
    }
}

.widgetPicker {
    .widget-picker-testimonial {
        padding-top: 15px;
        padding-bottom: 15px;

        .flex-row {
            .inner-content {
                .callout {
                    .card-block {
                        .testimonial-block {
                            text-align: center;
                            padding-right: 2%;
                            padding-left: 2%;
                            color: $white;
                            font-family: $testimonial-font-family;
                            font-size: 28px;
                            line-height: 42px;
                            font-weight: bold;
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }
}

.widgetPicker {
    .widget-picker-includedCallout {
        .flex-row {
            .column-12 {
                width: 20%;
            }
            .inner-content {
                .callout {

                    .included-parent-img {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        height: 150px;
                        margin-bottom: 15px;

                        .card-img-top {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            margin-bottom: unset;
                            height: 100%;
                            width: 150px;
                            background-color: $white;
                            border-radius: 100px;

                            img {
                                width: auto;
                            }
                        }
                    }

                    h4 {
                        font-size: 22px;
                        color: $white;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
