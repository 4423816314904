﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

/*JQuery UI */
.ui-datepicker td {
    a, &.ui-datepicker-unselectable span.ui-state-default {
        text-align: center;
    }
}
.ui-datepicker {
    .ui-datepicker-header {
        background-color: transparent;
        border-color: transparent;
    }

    .ui-datepicker-prev, .ui-datepicker-next {
        height: 100%;
    }

    .ui-widget-header .ui-icon {
        background-image: none;
    }

    .ui-datepicker-prev, .ui-datepicker-next {
        top: 0;
    }

    .ui-datepicker-prev {
        left: 0;
    }

    .ui-datepicker-next {
        right: 0;
    }

    .ui-datepicker-prev > span {
        background-image: none;
        margin-left: -5px;

        &::before {
            content: "\f104";
            font-family: FontAwesome;
            font-size: 18px;
            font-weight: normal;
            margin-top: -5px;
            color: $black;
            text-indent: 0;
            display: block;
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }
    }

    .ui-datepicker-next > span {
        background-image: none;
        margin-left: -2px;

        &::before {
            content: "\f105";
            font-family: FontAwesome;
            font-size: 18px;
            font-weight: normal;
            margin-top: -5px;
            color: $black;
            text-indent: 0;
            display: block;
            opacity: 0.7;
        }
    }

    @include media-breakpoint-down(sm) {
        .ui-datepicker-prev > span, .ui-datepicker-next > span {
            background-image: none;
            margin-left: 0;
            margin-top: 0;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            text-align: center;

            &::before {
                margin-top: 0;
            }
        }
    }

    button.clear-dates {
        position: absolute;
        right: 0;
        background-color: white;
        border: 0;
        padding: 5px 15px;
        color: $brand-green;
        height: 31px;

        &:hover {
            text-decoration: underline;
        }
    }

    &.ui-datepicker-multi:after {
        content: " ";
        height: 31px;
        padding: 5px 15px;
        background-color: white;
        display: block;
        text-align: right;
    }

    @media (max-width: 320px) {
        .ui-datepicker-header {
            padding: 0.2em 0;
        }

        .ui-datepicker-next > span, .ui-datepicker-prev > span {
        }
    }
}

/*.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next{
    top: 3px;
}
.ui-datepicker .ui-datepicker-prev {
    left: 3px;
}
.ui-datepicker .ui-datepicker-next{
    right: 3px;
}
.ui-datepicker .ui-datepicker-next.ui-state-hover span, .ui-datepicker .ui-datepicker-prev.ui-state-hover span {
    margin-top: -8px;
}*/
.ui-datepicker select.ui-datepicker-year {
    /*padding: 0 0 0 5px;
    margin-left: 10px;
    background-color: white;
    font-weight: bold;
    color: #333333;*/
    padding: 0;
    margin-left: 0px;
    background-color: transparent;
    -webkit-appearance: none;
    background-image: url("/dist/img/year-arrow-down.png");
    background-repeat: no-repeat;
    background-position: center right 10px;
    font-weight: bold;
    color: #333333;
    border: 0;
    width: 60px;
    padding-left: 5px;
    margin: 0;

    &::-ms-expand {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
}

.ui-datepicker-inline select.ui-datepicker-year {
    width: 70px;
}

.ui-datepicker td {
    padding: 0;
}

.ui-datepicker.ui-widget {
    font-size: 14px;

    .ui-datepicker td {
        a {
            text-align: center;
        }
    }

    .ui-state-default {
        background-color: transparent;
        border: 1px solid transparent;

        &:hover {
            background-color: $brand-jquery-ui-light-blue;
        }
    }

    .ui-state-active {
        border: 1px solid $brand-jquery-ui-blue;
        background: $brand-jquery-ui-blue;
        color: $white;

        > a {
            color: $white;
        }

        &:hover {
            background-color: $brand-jquery-ui-blue;
        }
    }

    .ui-state-active-parent {
        background: $brand-jquery-ui-blue;
        border: 0;

        > a {
            border: 1px solid $brand-jquery-ui-blue;
            background: $brand-jquery-ui-blue;
            color: $white;

            &:hover {
                background-color: $brand-jquery-ui-blue;
            }
        }
    }

    .ui-state-highlight-parent {
        background: #d1f5fb;
        border: 0;

        > a {
            border: 1px solid #d1f5fb;
            background: #d1f5fb;
            color: #000000;
        }
    }

    td.current-day-unselected a.ui-state-active, td.min-day-unselected a.ui-state-active {
        background-color: transparent;
        border: 1px solid transparent;
        color: #454545;
    }

    .ui-state-active-parent.ui-state-disabled, .ui-state-highlight-parent.ui-state-disabled {
        opacity: 1;
    }

    .ui-state-active-parent.ui-state-disabled {
        span {
            color: $white;
        }
    }
}