﻿/// <reference path = "../../main.scss" />
// File Name: _search.scss
// Description: Styles for the Locations page (country)
// ------------------------------------------------------------

.locationsPage {
    main {
        padding-top: 0;
    }



    .page-content {
        margin-top: 34px;

        .locations-container {
            @media (min-width: 1530px) {
                max-width: 1500px;
            }
        }

        #detail-tabs {
            margin-bottom: 80px;

            &.number-tabs-4, &.number-tabs-3, &.number-tabs-2, &.number-tabs-1 {
                .tab-buttons {
                    button {
                        flex-grow: 1;
                    }
                }
            }

            .tab-buttons {
                display: flex;
                border-bottom: 1px solid $brand-form-gray;

                button {
                    background-color: transparent;
                    color: $brand-mariner-blue;
                    font-weight: 800;
                    font-size: 22px;
                    border-radius: 0;
                    border: 0;
                    border-bottom: 4px solid $brand-mariner-blue;
                    padding: 8px 15px;
                    min-width: 0;
                    transition: none;

                    &.collapsed {
                        color: $brand-gray;
                    }
                }
            }

            .tab-content {
                padding-top: 30px;
                padding-bottom: 30px;

                .mobile-only {
                    display: none;
                }

                .countries-row {
                    @include make-row();

                    .countries-col-2 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(2);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-3 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(3);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-4 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(4);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-5 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(7);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-6 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(6);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-7 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(7);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-8 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(8);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-9 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(9);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-10 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(10);
                        }

                        @include make-col-ready();
                    }

                    .country.mobile-only {
                        display: none;
                    }
                }

                .states-row {
                    @include make-row();

                    .states-col {
                        flex-grow: 1;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }

                .country-title {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: bold;

                    a {
                        color: $black;
                        font-weight: bold;
                    }
                }
                /*non-us countries */
                .short-countries-row {
                    @include make-row();

                    .country-block-col {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(6);
                        }

                        @include make-col-ready();
                        margin-bottom: 16px;
                    }
                }


                .state-item, .city-item {
                    margin-bottom: 16px;
                    font-family: $font-family-sans-serif;

                    .province {
                        font-size: 14px;
                        margin-bottom: 3px;

                        a {
                            color: $black;
                            font-weight: bold;
                        }
                    }

                    a.city {
                        color: $black;
                        display: block;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 1.4em;
                    }
                }

                .city-item {
                    margin-bottom: 0;
                }
            }
        }
    }


    .recently-viewed-properties-horizontal-grid-editor {
        padding: 80px 70px;

        @include media-breakpoint-down(sm) {
            padding: 30px 10px;
        }
    }
    /* Tablet sizes, make the most of the real estate */
    @media (max-width: 768px) {
    }

    @include media-breakpoint-down(lg) {
        .page-content #detail-tabs {
            .tab-buttons {
                display: none;
            }

            .tab-content {
                .countries-row {
                    .country {
                        &.desktop-only {
                            display: none;
                        }

                        &.mobile-only {
                            display: block;
                        }
                    }
                }

                .country {
                    padding-top: 0px;
                }

                .mobile-only {
                    display: block;
                }

                .btn-tab {
                    width: 100%;
                    color: $brand-mariner-blue;
                    font-weight: 800;
                    background-color: transparent;
                    border: 2px solid $brand-mariner-blue;
                    border-left: 0;
                    border-right: 0;
                    border-top: 0;
                    border-radius: 0;
                }

                &:first-child {
                    .btn-tab {
                        border-top: 2px solid $brand-mariner-blue;
                    }
                }

                .tab-item {
                    margin-top: 15px;

                    > div {
                    }

                    border-bottom: 2px solid $brand-mariner-blue;
                }


                .states-row .states-col {
                    max-width: 50%;
                    flex: 0 0 50%;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
    }
}


