﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------
/* styles if no fluid bg set on row config*/
.footerQuote-section {
    > .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        > .body {
            margin-left: 0;
            margin-right: 0;

            > .column.footerQuote {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.footer-quote-grid-editor {
    position: relative;
    padding: 50px 30px;
    /*background: linear-gradient($brand-blue-darker-2, #0C4C63);*/
    background: linear-gradient($brand-deep-koamaru, $brand-deep-koamaru);

    .slick-prev {
        left: 0px;
        z-index: 1;
    }

    .slick-next {
        right: 0px;
        z-index: 1;
    }



    .quote-item {
        z-index: 0;

        &:focus {
            outline: none;
        }

        .quote-container {
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;

            .quote {
                margin-bottom: 0;

                p {
                    color: $white;
                    font-family: $testimonial-font-family;
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 1.5em;
                    font-style: italic;
                    text-align: center;
                }

                p::before {
                    content: "\"";
                }

                p::after {
                    content: "\"";
                }
            }

            .blockquote-footer {
                color: $white;
                text-align: center;
                margin-top: 2em;

                &::before {
                    font-size: 16px;
                    line-height: 1.4em;
                    display: inline-block;
                    margin-top: 5px;
                }

                > div {
                    display: inline-block;
                    vertical-align: top;
                }

                .author {
                    display: block;
                    font-size: 24px;
                    line-height: 1.4em;
                    font-weight: 300;
                }

                .location {
                    display: block;
                    font-size: 16px;
                    line-height: 1.5em;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }


    @include media-breakpoint-up(md) {
        .quote-slider {
            max-height: 170px;
            overflow: hidden;

            &.slick-initialized {
                max-height: none;
                overflow: inherit;
            }
        }
    }

    @media (max-width: 1320px) {
        .slick-prev {
            left: -25px;
        }

        .slick-next {
            right: -25px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 100px;
        padding-top: 100px;

        .quote-item .quote-container {
            overflow: hidden;

            .quote {
                padding-left: 2px;
                padding-right: 2px;

                p {
                    font-size: 24px;
                }
            }
        }
    }
}
