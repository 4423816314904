﻿/// <reference path = "../marathon.scss" />
// File Name: _nav-utility.scss
// Description: Utility navigation styles
// ------------------------------------------------------------

.nav-utility {
    .navbar {
        padding: .5rem 1rem;
    }

    .navbar-nav {
        flex-direction: row;
        flex: 1 1 auto;
        justify-content: flex-end;
        padding-right: 1rem;

        li + li {
            margin-left: .5rem;
        }
    }
}
