﻿/// <reference path = "../../main.scss" />
// File Name: _home.scss
// Description: Home view styles
// ------------------------------------------------------------

.home {
    .container {
        @media (min-width: 1400px) {
            max-width: 1340px;
        }

        @media (min-width: 1535px) {
            max-width: 1505px;
        }

        @media (min-width: 1760px) {
            max-width: 1700px;
        }

        @media (min-width: 1840px) {
            max-width: 1800px;
        }
    }
}