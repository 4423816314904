﻿// File Name: mixins.scss
// Description: Mixins
// ------------------------------------------------------------

@mixin btn-green {
    background-color: $brand-green;
    color: $white;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        background-color: $brand-green-darker;
    }

    &:active {
        cursor: pointer;
        background-color: $brand-green-lighter;
    }
}
@mixin btn-red {
    background-color: $brand-red;
    color: $white;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 40px;
    font-size: 18px;

    &:hover {
        cursor: pointer;
        background-color: $brand-red-btn-hover;
    }

    &:active {
        cursor: pointer;
        background-color: $brand-red-btn-hover;
    }
}
@mixin btn-green-outline {
    border-color: $brand-green;
    background-color: transparent;
    color: $brand-green;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        background-color: $brand-green-darker;
        color: $white;
    }

    &:active {
        cursor: pointer;
        background-color: $brand-green-darker;
        color: $white;
    }
}
@mixin btn-blue {
    background-color: $brand-mariner-blue;
    color: $white;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        background-color: $brand-deep-koamaru;
    }

    &:active {
        cursor: pointer;
        background-color: $brand-deep-koamaru;
    }
}
@mixin btn-blue-outline {
    border-color: $brand-blue;
    background-color: transparent;
    color: $brand-blue;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        background-color: $brand-blue-darker;
        color: $white;
    }

    &:active {
        cursor: pointer;
        background-color: $brand-blue-darker;
        color: $white;
    }
}
@mixin btn-light-blue {
    background-color: $brand-anakiwa-blue;
    color: $black;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 30px;
    font-size: 18px;

    &:hover {
        cursor: pointer;
        color: $white;
        background-color: $brand-mariner-blue;
    }

    &:active {
        cursor: pointer;
        color: $white;
        background-color: $brand-mariner-blue;
    }
}
@mixin btn-darkblue {
    background-color: $brand-deep-koamaru;
    color: $white;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 40px;
    font-size: 18px;

    &:hover {
        cursor: pointer;
        background-color: $brand-mariner-blue;
    }

    &:active {
        cursor: pointer;
        background-color: $brand-mariner-blue;
    }
}
/*@mixin btn-darkblue-wt {
    background-color: $brand-blue-darker-2;
    color: $white;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 40px;
    font-size: 18px;

    &:hover {
        cursor: pointer;
        background-color: $brand-blue-other-hover;
    }

    &:active {
        cursor: pointer;
        background-color: $brand-blue-other-hover;
    }
}*/
@mixin btn-lightblue-outline {
    background-color: transparent;
    color: $brand-malibu-blue;
    border-width: 2px;
    border-style: solid;
    border-color: $white;
    border-radius: 8px;
    margin-left: 0;
    display: inline-block;
    text-transform: none;
    font-weight: 500;
    padding: 10px 40px;
    font-size: 18px;

    &:hover, &:active {
        cursor: pointer;
        background-color: $white;
        color: $brand-blue-other-hover;
    }
    /*&:active {
        cursor: pointer;
        background-color: $brand-blue-other-hover;
    }*/
}
@mixin btn-darkblue-outline {
    border-color: $brand-blue-other;
    background-color: transparent;
    color: $brand-blue-other;
    border-radius: 0;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        background-color: $brand-blue-other-hover;
        color: $white;
    }

    &:active {
        cursor: pointer;
        background-color: $brand-blue-other-hover;
        color: $white;
    }
}
@mixin btn-white {
    background-color: $white;
    color: $brand-blue;
    border-radius: 0;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        color: $white;
        background-color: $brand-blue-btn-hover;
    }

    &:active {
        cursor: pointer;
        color: $white;
        background-color: $brand-blue-btn-active;
    }
}
@mixin btn-brand-blue-white {
    background-color: $white;
    color: $brand-blue-btn-text;
    border-radius: 0;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        background-color: $brand-slight-blue-white;
    }

    &:active {
        cursor: pointer;
        color: $brand-blue-btn;
        background-color: $brand-slight-blue-white;
    }
}



@mixin btn-btn-blue-white {
    background-color: $white;
    color: $brand-blue-btn;
    border-radius: 0;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;

    &:hover {
        cursor: pointer;
        background-color: $brand-slight-blue-white;
    }

    &:active {
        cursor: pointer;
        color: $brand-blue-btn-text;
        background-color: $brand-slight-blue-white;
    }
}
@mixin btn-brand-dark-blue-outline {
    background-color: transparent;
    color: $white;
    border-radius: 0;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;
    border: 1px solid $white;

    &:hover {
        cursor: pointer;
        color: $brand-blue;
        background-color: $white;
    }

    &:active {
        cursor: pointer;
        color: $brand-blue;
        background-color: $brand-slight-blue-white;
    }
}
@mixin btn-orange {
    background-color: $brand-orange;
    color: $white;
    border-radius: 0;
    margin-left: 0;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 600;
    padding: 20px 30px;
    font-size: 20px;
    letter-spacing: 1px;

    &:hover {
        cursor: pointer;
        background-color: $brand-orange;
    }

    &:active {
        cursor: pointer;
        color: $white;
        background-color: $brand-orange;
    }
}