﻿/// <reference path = "../marathon.scss" />
// File Name: _typography.scss
// Description: Typography styles to override bootstrap.
//              These should be HTML elements or base
//              bootstrap typography classes (e.g. lead, mark,etc.)
// ------------------------------------------------------------

article.content {
    h2:not(:first-child) {
        margin-top: 3rem;
    }

    h3 {
        margin-top: 2rem;
    }

    
    h4 {
        margin-top: 1.5rem;
    }

    ul li,
    ol li {
        margin-bottom: .25rem;
    }

    blockquote {

    }
}