﻿/// <reference path = "../../main.scss" />
// File Name: _eventIndex.scss
// Description: Event Index view styles
// ------------------------------------------------------------

.eventIndex {
    article {
        @include make-col(8);
        @include make-col-ready();
    }
    aside{
        @include make-col(4);
        @include make-col-ready();
    }
}
