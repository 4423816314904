﻿/// <reference path = "../../main.scss" />
// File Name: _nav-primary.scss
// Description: Primary navigation view styles
// ------------------------------------------------------------
header {
    .navbar-toggler {
        .sr-only {
            position: absolute !important;
            left: 15px !important;
            width: auto !important;
            height: auto !important;
            padding: unset !important;
            clip: auto !important;
            white-space: normal !important;
            border: 0 !important;
            -webkit-clip-path: unset !important;
            font-size: 10px !important;
            text-transform: uppercase !important;
            color: $black !important;
            transform: none !important;
        }
    }

    .mobile-header-wrapper {
        position: relative;
        width: 100%;
        height: 43px;

        .navbar-brand {
            img {
                max-width: 126px;
            }
        }

        #mobileNavbarToggler.navbar-toggler {
            padding-top: 0px;
            padding-bottom: 0px;
            border-bottom: 0px;
            height: 43px;
            width: 58px;
            padding: 0px 20px;
            cursor: pointer;
            position: relative;
            bottom: 7px;

            .fa {
                color: $black;
                font-size: 22px;
                transform: scaleX(1.4);
            }

            .close-icon {
                display: none;
                color: $breadcrumb-gray;
                font-size: 36px;
                font-weight: 500;
                padding-top: 7px;
                padding-left: 1px;
            }

            &.open {
                .open-icon {
                    display: none;
                }

                .close-icon {
                    display: block;
                }
            }
        }
    }

    nav.nav-primary nav .mobile-header-wrapper {
        display: none;
    }

    .mobile-search-dropdown {
        transition: height 0.3s ease-in-out;
        height: 0px;
        position: absolute;
        top: 44px;
        width: 100%;
        overflow: hidden;
        margin-top: -1px;
        background-color: $white;
        display: none;


        .date-actions {
            display: none;
            width: 100%;

            .close-datepicker {
                float: left;
                color: $breadcrumb-gray;
                font-size: 26px;
                font-weight: 600;
                margin-top: -8px;

                &:hover {
                    text-decoration: none;
                }
            }

            .clear-datepicker {
                float: right;
                color: $brand-green;
                font-size: 14px;
            }
        }

        &.open {
            height: 145px;
            box-shadow: 0 4px 6px $brand-dark-gray-faded;

            @include media-breakpoint-up(lg) {
                height: 1px;
                display: none;
            }

            &.start-date-open, &.end-date-open {
                height: 586px;
                max-height: calc(100vh - 41px);
                overflow-y: auto;

                .date-actions {
                    display: block;
                }
            }

            &.start-date-open {
                .datepicker-inline #mobile-date-both-datepicker-inline {
                    display: block;
                    width: 100%;
                }
            }

            &.end-date-open {
                .datepicker-inline #mobile-date-both-datepicker-inline {
                    display: block;
                    width: 100%;
                }
            }

            .datepicker-inline {
                width: 100%;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;

                .ui-datepicker-inline {
                    width: 100%;
                    padding: 0;
                    border-color: $brand-super-light-gray2;

                    .ui-datepicker-header {
                        padding: 10px 0;
                    }

                    .ui-datepicker-title {
                        font-size: 18px;
                    }

                    .ui-widget-header {
                        background-color: transparent;
                        border: 0;
                        border-bottom: 1px solid $brand-super-light-gray2;
                        margin: 0;
                    }

                    .ui-datepicker-prev, .ui-datepicker-next {
                        height: 100%;
                    }

                    .ui-datepicker-prev > span {
                        background-image: none;


                        &::before {
                            content: "\f104";
                            font-family: FontAwesome;
                            font-size: 18px;
                            font-weight: normal;
                            margin-top: -6px;
                            color: $black;
                            text-indent: 0;
                            display: block;
                            opacity: 0.7;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    .ui-datepicker-next > span {
                        background-image: none;
                        margin-left: 0px;

                        &::before {
                            content: "\f105";
                            font-family: FontAwesome;
                            font-size: 18px;
                            font-weight: normal;
                            margin-top: -6px;
                            color: $black;
                            text-indent: 0;
                            display: block;
                            opacity: 0.7;
                        }
                    }

                    @media (max-width: 320px) {
                        .ui-datepicker-header {
                            padding: 0.2em 0;
                        }

                        .ui-datepicker-next > span, .ui-datepicker-prev > span {
                            margin-top: -3px;
                        }
                    }
                }

                td {
                    text-align: center;

                    a, span.ui-state-default {
                        height: 41px;
                        /*width: 38px;*/
                        text-align: center;
                        padding: 10px;
                        margin-left: auto;
                        margin-right: auto;

                        @media (max-width: 320px) {
                            height: 34px;
                            /*width: 25px;*/
                            padding: 6px;
                        }
                    }
                }
            }
        }

        .inner-content {
            padding: 20px 25px;

            .form-mobile-header-search {
                display: block;

                .text-location-holder {
                    position: relative;
                    display: block;
                    width: 100%;
                    border: 1px solid $brand-very-light-gray;
                    box-shadow: 0px 2px 5px $brand-very-light-gray;

                    span.icon {
                        position: absolute;
                        font-size: 20px;
                        pointer-events: none;
                        transition: 0.3s color;
                        width: 16px;
                        height: 25px;
                        margin-left: 12px;
                        top: 50%;
                        transform: translateY(-50%);

                        svg {
                            width: 100%;

                            .a {
                                fill: $brand-very-light-gray;
                            }
                        }
                    }

                    &.selected {
                        span.icon svg .a {
                            fill: $brand-blue;
                        }
                    }

                    .text-location {
                        padding-left: 40px;
                        font-size: 16px;
                        width: 100%;
                        border: 0;
                    }
                }

                .location-state-holder {
                    position: relative;
                    display: block;
                    width: 100%;
                    margin-top: 15px;
                    border: 1px solid $brand-very-light-gray;
                    box-shadow: 0px 2px 5px $brand-very-light-gray;

                    .location-state {
                        background-size: 20px;
                        background-position: 20px center;
                        background-repeat: no-repeat;
                        height: 100%;
                        width: 100%;
                        -webkit-appearance: none;
                        appearance: none;
                        padding: 8px 35px 8px 15px;
                        font-size: 16px;
                        color: $brand-dark-gray;
                        border: none;

                        &::-ms-expand {
                            display: none;
                        }
                    }

                    .arrow {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        display: inline-block;
                        transform: translateY(-50%);
                        margin-right: 15px;
                        pointer-events: none;

                        &::after {
                            display: inline-block;
                            font-family: "FontAwesome";
                            font-size: 14px;
                            content: "\f0d7";
                            vertical-align: middle;
                        }
                    }
                }

                .actions {
                    margin-top: 15px;

                    .btn-blue {
                        @include btn-blue;
                        padding: 10px 15px;
                        font-size: 1em;
                        letter-spacing: 0.05em;
                        text-transform: none;
                        font-weight: normal;
                        width: 100%;
                    }
                }
            }


            .border-item {
                border-left: 1px solid $brand-very-light-gray;
                height: 26px;
            }
            /*errors*/
            label.error {
                position: absolute;
                left: 0;
                z-index: 2;
                background-color: white;
                padding: 5px 15px;
                border-radius: 0px 0px 5px 5px;
                box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.2784313725490196);
                font-family: $font-family-sans-serif;
                font-size: 14px;
                /* width: 100%; */
                /* text-align: left; */
                top: 100%;
                border-top: 1px solid gainsboro;
            }
        }
    }

    @include media-breakpoint-down(md) {

        .mobile-search-dropdown {
            display: block;
        }

        nav.nav-utility {
            display: none;
        }

        nav.nav-primary nav .mobile-header-wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;


            .mobile-search-toggler {
                margin-right: 20px;
                background-color: transparent;
                border: none;

                .fa {
                    color: $black;
                    margin-top: 2px;
                    font-size: 22px;
                }

                &.open {
                    .fa {
                        color: $brand-somewhat-light-gray;
                    }
                }
            }
        }

        #menu, #menu.domReady {
            display: block;
            position: absolute;
            top: 43px;
            left: 0px;
            margin-left: -100%;
            background-color: $white;
            /*box-shadow: 0 0 18px $brand-dark-gray;*/
            padding: 0px;
            transition: margin 0.4s;
            backface-visibility: hidden;
            width: 100%;
            max-height: calc(100vh - 43px);
            height: calc(100vh - 43px);
            overflow-y: auto;

            &.open {
                margin-left: 0%;
            }
            /*.header-bar {
                padding: 15px;
                border-bottom: 1px solid $brand-super-light-gray2;

                .mobile-logo {
                    padding-left: 15px;

                    img {
                        width: 150px;
                    }
                }
            }*/
            nav.mobile-navigation {
                padding: 0px 14px 20px 14px;


                > ul {
                    margin-left: 0;
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        margin-left: 0;
                        list-style-type: none;
                        border-bottom: 1px solid $brand-super-light-gray2;
                        position: relative;

                        a {
                            color: $brand-dark-gray;
                            font-size: 18px;
                            font-weight: 600;
                            display: block;
                            padding: 12px 10px;

                            &:hover {
                                /*background-color: $brand-green;
                                color: $white;*/
                                //text-decoration: none;
                            }

                            &.current {
                                text-decoration: underline;
                            }
                        }

                        &.has-dropdown {
                            > a {
                                padding-right: 30px;
                                cursor: pointer;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }

                            &::before {
                                content: "\f107";
                                font-family: FontAwesome;
                                font-size: 26px;
                                color: $brand-super-light-gray3;
                                position: absolute;
                                right: 10px;
                                margin-top: 6px;
                                pointer-events: none;
                            }

                            > ul {
                                height: 1px;
                                margin-top: -1px;
                                overflow: hidden;
                                padding-left: 10px;
                                /*transition: max-height 0.8s ease-in-out;*/
                                transition: height 0.3s ease-in-out;

                                li {
                                    border: none;

                                    a {
                                        color: $brand-somewhat-light-gray;
                                        font-size: 16px;
                                        font-weight: normal;
                                        padding: 6px;

                                        &.current {
                                            text-decoration: underline;
                                        }
                                    }

                                    &.repeat-nav-link {
                                        > a {
                                            font-weight: 600 !important;
                                        }

                                        &:before {
                                            padding-top: 6px;
                                            position: absolute;
                                            content: " ";
                                            right: 100%;
                                        }
                                    }

                                    &.has-dropdown {
                                        > a {
                                            padding-right: 30px;
                                            cursor: pointer;
                                        }

                                        &::before {
                                            margin-top: 0;
                                        }
                                    }
                                }

                                > li:last-child {
                                    margin-bottom: 15px;
                                }
                            }

                            &.open {

                                &::before {
                                    content: "\f106";
                                }

                                > ul {
                                    /*max-height: 120vh;
                                    overflow-y: auto;*/
                                }
                            }
                        }
                    }
                }
            }

            .login-actions {
                text-align: center;
                padding-top: 30px;
                padding-bottom: 30px;

                .or {
                    font-size: 14px;
                    font-family: $headings-font-family;
                    font-weight: 600;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                .btn-blue {
                    @include btn-blue;
                }

                .btn-blue-outline {
                    @include btn-blue-outline;
                }

                a.btn {
                    text-transform: none;
                    font-size: 14px;
                    padding: 10px 20px;
                    min-width: 110px;
                }
            }

            .loggedin-section {
                background-color: $brand-super-light-gray2;


                .user-section {
                    padding-bottom: 15px;
                    border-bottom: 1px solid $brand-very-light-gray;
                    padding: 20px;

                    .name {
                        font-weight: bold;
                        font-size: 18px;
                    }

                    .role {
                        color: $brand-green;
                        font-style: italic;
                        font-weight: bold;
                        font-size: 14px;
                    }

                    .username {
                        color: $brand-somewhat-light-gray;
                        font-size: 14px;
                    }
                }

                .account-section {
                    padding: 20px;

                    .pages > div {
                        margin-bottom: 15px;

                        a {
                            color: $brand-somewhat-light-gray;
                            font-size: 16px;
                            font-weight: bold;
                            font-family: $headings-font-family;
                        }

                        .icon {
                            vertical-align: middle;
                            display: inline-block;
                            margin-top: -3px;

                            svg {
                                vertical-align: middle;
                                display: inline-block;
                                margin-right: 10px;
                                width: 14px;

                                .a {
                                    fill: $brand-blue-other;
                                }
                            }
                        }
                    }

                    .button-actions {
                        margin-top: 50px;

                        .btn-blue {
                            @include btn-blue;
                            text-transform: none;
                            font-size: 16px;
                            padding: 10px 20px;
                            width: auto;
                            display: block;
                        }
                    }
                }
            }
        }

        .nav-menu-footer {
            background-color: $brand-blue-btn-text;
            padding: 15px;
            color: $white;
            text-align: center;

            .contact-title {
                font-size: 18px;
                color: $white;
                margin-bottom: 5px;
            }

            .btn.btn-brand-dark-blue-outline {
                @include btn-brand-dark-blue-outline;
                padding: 4px 25px;
                font-size: 18px;
                border: 1px solid $white;
                font-weight: normal;
            }

            .contact-phone::before {
                font-family: FontAwesome;
                font-size: 20px;
                color: $white;
                content: "\f095";
                display: block;
            }

            .contact-address {
                margin-top: 15px;

                .icon {
                    display: block;

                    svg {
                        position: relative;
                        width: 14px;

                        .a {
                            fill: $white;
                        }
                    }
                }
            }
        }
    }
}
