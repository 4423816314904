﻿///<reference path="../../main.scss" />
// File Name: _card.scss
// Description: Styles for Subpage cards
// ------------------------------------------------------------

section.card-section {
    padding-top: 0px;
    padding-bottom: 0px;

    .container {
        /*shorter widths*/
        @media (min-width: 1200px) {
            max-width: 991px;
        }

        @media (min-width: 1350px) {
            max-width: 991px;
        }
    }
}

.card {
    border-radius: unset;
    border: none;
    margin-bottom: 0px;
    background-color: $white;

    .card-container {
        flex: none;
    }

    &.column {
        background-color: transparent !important;
    }

    .img-wrapper {
        //border-bottom: 1px solid $black;
        height: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }

    .content-section {
        padding: 20px 45px 0 45px;
        text-align: center;

        .buzzword {
            display: block;
            font-weight: lighter;
            margin-bottom: 0;
            line-height: 1.2em;
        }

        .title {
            font-size: 26px;
            font-weight: 800;
            margin-bottom: 10px;
        }

        .content {
            padding-top: 15px;
            font-size: 18px;
            line-height: 1.45em;

            &:last-child {
                margin-bottom: 70px;
            }
        }
    }

    &.renderingStyle2 {
        border-width: 1px;

        .img-wrapper {
            border-width: 1px;
        }

        .content-section {
            padding: 20px 20px 0 20px;

            .title {
                text-transform: uppercase;
                font-size: 26px;
                font-weight: 900;
                line-height: 1.1em;
                margin-bottom: 22px;
            }

            .content {
                font-size: 16px;
                line-height: 1.45em;
                padding-top: 0px;
                margin-bottom: 32px;
            }

            .buzzword {
                padding-bottom: 3px;
            }
        }
    }

    .link-wrapper {
        text-align: center;
        margin-bottom: 30px;
        padding-left: 30px;
        padding-right: 30px;

        a {
            width: 100%;
            min-width: unset;
        }
    }
}

@include media-breakpoint-down(md) {
    .card-container {
        text-align: center;

        .card {
            //width: 75%;
            display: inline-block;

            &.renderingStyle1 {
                .content-section {
                    padding-left: 30px;
                    padding-right: 30px;
                    .content {
                        font-size: 16px;
                        line-height: 1.45em;
                        margin-bottom: 45px;
                    }
                }
            }

            &.renderingStyle2 {
                .content-section {
                    .buzzword {
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .card-container {
        .card {
            width: 100%;
        }
    }
}
