﻿///<reference path="../../main.scss" />
// File Name: _googlemaps.scss
// Description: Google Maps Grid Editor (Frontend) view styles
// ------------------------------------------------------------

span.videoWrapper {
    display: block;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    margin-bottom: 1rem;
    height: 0;

    &.map {
        -moz-box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
        -webkit-box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
        box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
            margin-bottom: 4rem;
        }

        iframe {
            border: none;
        }
    }

    .mapWrap, object, embed, iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.column-6 {
    .videoWrapper {
        &.map {
            padding-top: 100px;
        }
    }
}

.column-4 {
    .videoWrapper {
        &.map {
            padding-top: 150px;
        }
    }
}
