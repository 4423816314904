﻿/// <reference path = "../../main.scss" />
// File Name: _search.scss
// Description: Styles for the Locations page (country)
// ------------------------------------------------------------

body.countryLocation {
    main {
        padding-top: 0;

        .mobile-overlay + .breadcrumbs {
            margin-top: 0;
        }
    }

    .headline {
        text-align: center;
    }

    .page-content {
        margin-top: 50px;

        h1 {
            font-size: 30px;
            margin-bottom: 0.5rem;
        }

        .locations-container {
            margin-top: 20px;
            margin-bottom: 100px;

            .locations-row {
                @include make-row();
                display: flex;

                .locations-column {
                    margin-bottom: 0px;
                    line-height: 1.25em;

                    @include media-breakpoint-up(xs) {
                        @include make-col(6);
                    }

                    @include media-breakpoint-up(md) {
                        @include make-col(6);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(2);
                    }

                    @include make-col-ready();



                    .state-title {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 1.25em;
                        margin-bottom: 10px;

                        a {
                            color: $black;

                            &:hover {
                                color: $brand-blue;
                                text-decoration: underline;
                            }
                        }
                    }

                    .item-wrapper {
                        font-size: 16px;
                        line-height: 1.5em;

                        a {
                            color: $black;
                            display: inline-block;
                            font-size: 14px;
                            line-height: 1.4em;

                            &:hover {
                                color: $brand-blue;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .country {
                &.mobile-only {
                    display: none;
                }

                .countries-row {
                    @include make-row();

                    .countries-col-2 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(2);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-3 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(3);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-4 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(4);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-5 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(7);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-6 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(6);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-7 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(7);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-8 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(8);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-9 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(9);
                        }

                        @include make-col-ready();
                    }

                    .countries-col-10 {
                        @include media-breakpoint-up(xs) {
                            @include make-col(12);
                        }

                        @include media-breakpoint-up(lg) {
                            @include make-col(10);
                        }

                        @include make-col-ready();
                    }

                    .country.mobile-only {
                        display: none;
                    }
                }

                .states-row {
                    @include make-row();

                    .states-col {
                        flex-grow: 1;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }

                .country-title {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: bold;

                    a {
                        color: $black;
                        font-weight: bold;
                    }
                }


                .state-item, .city-item {
                    margin-bottom: 16px;
                    font-family: $font-family-sans-serif;

                    .province {
                        font-size: 14px;
                        margin-bottom: 3px;

                        a {
                            color: $black;
                            font-weight: bold;
                        }
                    }

                    a.city {
                        color: $black;
                        display: block;
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 1.4em;
                    }
                }
            }
        }
    }

    .recently-viewed-properties-horizontal-grid-editor {
        padding: 80px 70px;

        @include media-breakpoint-down(sm) {
            padding: 30px 10px;
        }
    }

    @include media-breakpoint-down(lg) {

        .page-content .locations-container .country {
            &.desktop-only {
                display: none;
            }

            &.mobile-only {
                display: block;
            }
        }
    }
}

/* Tablet sizes, make the most of the real estate */
@media (max-width: 768px) {
}

@include media-breakpoint-down(sm) {
}

