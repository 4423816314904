﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

.trusted-businesses-widget {
    text-align: center;
    padding-top: 72px;
    padding-bottom: 52px;

    .headline {
        font-size: 34px;
    }

    .businesses {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 10px;
        padding-left: 0px;
        padding-right: 0px;

        .business {
            //flex: 1 1;
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            > div {
                min-width: 145px;
            }

            img {
                max-width: 100%;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .businesses {
            display: block;
            .business {
                width: 100%;
            }
        }
    }
}