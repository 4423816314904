﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

main {
    &.has-banner {
        padding-top: 0;
    }

    .banner, .search-landing-banner, .subpage-banner, .locations-page-banner {
        &.overlay {
            .content {
                color: white;
            }
        }
        //When overlay is applied to the banner, text color will change to not get lost in the overlay / filter
        .banner-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $banner-overlay;

            &.opacity-level-5 {
                background-color: rgba(0,0,0,0.05);
            }

            &.opacity-level-10 {
                background-color: rgba(0,0,0,0.1);
            }

            &.opacity-level-15 {
                background-color: rgba(0,0,0,0.15);
            }

            &.opacity-level-20 {
                background-color: rgba(0,0,0,0.2);
            }

            &.opacity-level-25 {
                background-color: rgba(0,0,0,0.25);
            }

            &.opacity-level-30 {
                background-color: rgba(0,0,0,0.3);
            }

            &.opacity-level-35 {
                background-color: rgba(0,0,0,0.35);
            }

            &.opacity-level-40 {
                background-color: rgba(0,0,0,0.4);
            }

            &.opacity-level-45 {
                background-color: rgba(0,0,0,0.45);
            }

            &.opacity-level-50 {
                background-color: rgba(0,0,0,0.5);
            }

            &.opacity-level-55 {
                background-color: rgba(0,0,0,0.55);
            }

            &.opacity-level-60 {
                background-color: rgba(0,0,0,0.6);
            }

            &.opacity-level-65 {
                background-color: rgba(0,0,0,0.65);
            }

            &.opacity-level-70 {
                background-color: rgba(0,0,0,0.7);
            }

            &.opacity-level-75 {
                background-color: rgba(0,0,0,0.75);
            }

            &.opacity-level-80 {
                background-color: rgba(0,0,0,0.8);
            }

            &.opacity-level-85 {
                background-color: rgba(0,0,0,0.85);
            }

            &.opacity-level-90 {
                background-color: rgba(0,0,0,0.9);
            }

            &.opacity-level-95 {
                background-color: rgba(0,0,0,0.95);
            }

            &.opacity-level-100 {
                background-color: rgba(0,0,0,1);
            }

            &.mobile {
                display: none;
            }
        }

        .title {
            /*.blue-color, .seagull-blue-color {
                color: $brand-seagull-blue !important;
            }*/
        }
    }
    /* Homepage Banner */
    .banner {
        .banner-container {
            position: relative;
            background-color: $brand-dark-gray;
        }

        .banner-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: $brand-dark-gray;
        }

        .content {
            padding: 184px 15px 172px 15px;
            @include make-col-ready();
            @include make-col(12);

            @include media-breakpoint-down(sm) {
                padding-top: 60px;
                padding-bottom: 150px;
            }

            .title {
                margin: 0;
                font-family: $headings-font-family;
                font-weight: 800;
                font-size: 26px;
                line-height: 1.25;
                color: $white;
                text-align: center;
                text-transform: none;
            }

            .sub-title {
                margin: 0;
                font-family: $headings-font-family;
                color: $white;
                text-align: center;
                font-weight: 300;
                font-size: 16px;
                margin-top: 10px;
                position: relative;
                padding-left: 15px;
                padding-right: 15px;
            }

            .search-bar-container {
                padding-top: 68px;
                text-align: center;
                display: flex;
                justify-content: center;


                .desktop-search-bar, .mobile-search-bar {
                    display: flex;
                    align-items: center;
                    background-color: $white;
                    max-width: 100%;
                    box-shadow: 0px 2px 6px $brand-dark-gray-25;
                    border-radius: 8px;

                    .form-inline {
                        max-width: 100%;
                    }

                    .dates, .date-fields {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        position: relative;
                    }

                    .date-start-span, .date-end-span {
                        position: relative;
                        display: flex;
                        align-items: center;

                        .fa {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            color: $brand-very-light-gray;
                            font-size: 20px;
                            margin-left: 22px;
                            pointer-events: none;
                            transition: 0.3s color;
                        }

                        &:hover {
                            .fa {
                                color: $brand-mid-gray;
                            }
                        }

                        &.selected {
                            .fa-calendar {
                                color: $brand-green;
                            }
                        }
                    }

                    input[type="text"] {
                        padding: 24px 20px;
                        min-height: 75px;
                        border: 0;
                        margin: 0;
                        font-size: 20px;
                        color: $brand-dark-gray;
                        line-height: 1.3em;
                        border-radius: 8px 0 0 8px;

                        &::placeholder {
                            color: $black;
                        }

                        &.text-location {
                            padding-left: 60px;
                            /*background-image: url("/dist/img/map-point.svg");*/
                            background-size: 20px;
                            background-position: 20px center;
                            background-repeat: no-repeat;
                            width: 450px;
                            border-right: 2px solid $brand-super-light-gray6;

                            &:focus {
                                border-color: transparent;
                            }
                        }

                        &.datepicker {
                            padding-left: 60px;
                            width: 190px;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }

                    .text-location-holder {
                        position: relative;

                        span.icon {
                            position: absolute;
                            font-size: 20px;
                            margin-left: 20px;
                            pointer-events: none;
                            transition: 0.3s color;
                            height: 30px;
                            width: 20px;
                            top: 50%;
                            transform: translateY(-50%);

                            svg {
                                width: 100%;

                                .a {
                                    fill: $brand-very-light-gray;
                                }
                            }
                        }

                        &.selected {
                            span.icon svg .a {
                                fill: $brand-blue;
                            }
                        }
                    }

                    .location-state-holder {
                        position: relative;

                        .location-state {
                            background-size: 20px;
                            background-position: 20px center;
                            background-repeat: no-repeat;
                            height: 100%;
                            -webkit-appearance: none;
                            appearance: none;
                            padding: 5px 35px 5px 20px;
                            font-size: 20px;
                            color: $brand-dark-gray;
                            border: none;
                            margin-right: 2px;

                            &::-ms-expand {
                                display: none;
                            }
                        }

                        .arrow {
                            position: absolute;
                            top: 50%;
                            right: 0;
                            display: inline-block;
                            transform: translateY(-50%);
                            margin-right: 15px;
                            pointer-events: none;

                            &::after {
                                display: inline-block;
                                font-family: "FontAwesome";
                                font-size: 14px;
                                content: "\f0d7";
                                vertical-align: middle;
                            }
                        }
                    }

                    input[type="text"], select, input[type="submit"] {
                        &:focus {
                            outline: none;
                            box-shadow: 0 0 0 2px rgba(77, 144, 254, 0.5);
                            border-color: transparent;
                        }
                    }

                    .separator-arrow {
                        height: 22px;
                        width: 48px;
                        padding-left: 10px;
                        padding-right: 10px;
                        margin-top: 2px;

                        svg {
                            width: 100%;
                        }

                        .a {
                            fill: #d4d9dd;
                            stroke: #d4d9dd;
                        }
                    }

                    input[type="submit"], .btn-green, .btn-orange {
                        padding: 20px 30px;
                        min-height: 75px;
                        font-size: 20px;
                        min-width: 0;
                        text-transform: none;
                        font-weight: 600;
                        border-bottom-left-radius: 0;
                        border-top-left-radius: 0;
                    }
                    /*errors*/
                    label.error {
                        position: absolute;
                        background-color: white;
                        padding: 5px 15px;
                        border-radius: 0px 0px 5px 5px;
                        box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.28);
                        border-top: 1px solid gainsboro;
                        top: 100%;
                        font-size: 1rem;
                        line-height: 1.4em;
                        text-align: center;
                    }
                }
            }
        }

        @include media-breakpoint-up(sm) {
            .content .title {
                font-size: 38px;
            }
        }

        @include media-breakpoint-up(md) {
            .content .title {
                font-size: 58px;
            }

            .content .sub-title {
                font-size: 26px;
            }
        }

        @include media-breakpoint-up(xl) {
            .content .title {
                font-size: 50px;
            }
        }
        /* Mobile Search styles */
        .content .search-bar-container .mobile-search-bar {
            font-size: 16px;
            padding: 15px;
            display: none;
            width: 100%;
            border-radius: 0px;
            background-color: transparent;

            .form-inline {
                .text-location-holder {
                    display: block;
                    border: 0;
                    width: 100%;

                    span.icon {
                        width: 16px;
                        height: 25px;
                        margin-left: 12px;
                        left: 0;
                    }

                    .text-location {
                        padding-left: 40px;
                        font-size: 18px;
                        width: 100%;
                        border-right: 0;
                        border-radius: 8px;
                        margin-right: 0;
                    }

                    .error {
                        left: 0px;
                        z-index: 3;
                    }
                }

                .location-state-holder {
                    display: block;
                    border: 0;
                    width: 100%;
                    margin-top: 15px;

                    .location-state {
                        font-size: 18px;
                        width: 100%;
                        min-height: 0;
                        padding: 10px 15px;
                        line-height: 1.3em;
                        border-radius: 8px;
                    }
                }
            }

            .dates {
                margin-top: 13px;
                width: 100%;
            }

            .date-fields {
                width: 100%;
                justify-content: space-between;
                margin-bottom: 20px;
                border: 1px solid $brand-very-light-gray;
                box-shadow: 0px 2px 5px $brand-very-light-gray;

                > span {
                }

                .separator-arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 50%;
                    margin-left: -12px;
                    margin-top: 2px;
                    z-index: 2;
                    pointer-events: none;
                    width: 25px;
                    padding-left: 5px;
                    padding-right: 5px;

                    .a {
                        fill: $breadcrumb-gray;
                        stroke: $breadcrumb-gray;
                    }
                }

                .date-start-span, .date-end-span {
                    flex: 1 1 50%;
                    max-width: 140px;

                    .fa {
                        margin-left: 12px;
                    }

                    input[type="text"].datepicker {
                        width: 100%;
                        font-size: 16px;
                        padding-left: 40px;
                        max-width: 130px;
                    }
                }
            }

            .mobile-search-dropdown {
                width: 100%;
                position: relative;
                display: none;

                &.start-date-open {
                    display: block;
                }

                .date-actions {
                    width: 100%;
                    display: block;

                    &::after {
                        content: " ";
                        clear: both;
                        display: block;
                    }

                    .close-datepicker {
                        float: left;
                        color: $breadcrumb-gray;
                        font-size: 26px;
                        font-weight: 600;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    .clear-datepicker {
                        float: right;
                        color: $brand-green;
                        font-size: 14px;
                        margin-top: 8px;
                    }
                }

                .mobile-date-both-datepicker-inline {
                    /* Calendar style */
                    .ui-datepicker-inline {
                        width: 100%;
                        padding: 0;
                        border-color: $brand-super-light-gray2;

                        .ui-datepicker-header {
                            padding: 10px 0;
                        }

                        .ui-datepicker-title {
                            font-size: 18px;
                        }

                        .ui-widget-header {
                            background-color: transparent;
                            border: 0;
                            border-bottom: 1px solid $brand-super-light-gray2;
                            margin: 0;
                        }

                        .ui-datepicker-prev, .ui-datepicker-next {
                            height: 100%;
                        }

                        .ui-datepicker-prev > span {
                            background-image: none;


                            &::before {
                                content: "\f104";
                                font-family: FontAwesome;
                                font-size: 18px;
                                font-weight: normal;
                                margin-top: -6px;
                                color: $black;
                                text-indent: 0;
                                display: block;
                                opacity: 0.7;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }

                        .ui-datepicker-next > span {
                            background-image: none;


                            &::before {
                                content: "\f105";
                                font-family: FontAwesome;
                                font-size: 18px;
                                font-weight: normal;
                                margin-top: -6px;
                                color: $black;
                                text-indent: 0;
                                display: block;
                                opacity: 0.7;
                            }
                        }

                        @media (max-width: 320px) {
                            .ui-datepicker-header {
                                padding: 0.2em 0;
                            }

                            .ui-datepicker-next > span, .ui-datepicker-prev > span {
                                margin-top: -3px;
                            }
                        }

                        td {
                            text-align: center;

                            a, span.ui-state-default {
                                height: 41px;
                                /*width: 38px;*/
                                text-align: center;
                                padding: 10px;
                                margin-left: auto;
                                margin-right: auto;

                                @media (max-width: 320px) {
                                    height: 34px;
                                    /*width: 25px;*/
                                    padding: 6px;
                                }
                            }
                        }
                    }
                }
            }

            .actions {
                width: 100%;

                input[type="submit"] {
                    margin-top: 15px;
                    display: block;
                    width: 100%;
                    padding: 10px 15px;
                    font-size: 18px;
                    letter-spacing: 0.05em;
                    text-transform: none;
                    font-weight: 600;
                    font-weight: 600;
                    min-height: 0;
                    border-radius: 8px;

                    &.desktop {
                        display: none;
                    }

                    &.mobile {
                        display: block;
                    }
                }
            }

            .text-location-span {
                position: relative;
            }

            input[type="text"] {
                padding: 10px 6px;
                font-size: 1em;
                min-height: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            display: block;

            .content .search-bar-container {
                .desktop-search-bar {
                    display: none;
                }

                .mobile-search-bar {
                    display: block;
                }
            }
        }

        .banner-button-section {
            @include make-col-ready();
            @include make-col(12);
            color: white;
            background-color: $brand-blue-darkest-blue;
            padding: 32px 15px;

            .banner-button-parent {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }

            .banner-button {
                @include btn-lightblue-outline;
                text-transform: uppercase;
                margin-left: 10px;
                margin-right: 10px;
                width: 330px;
            }

            @include media-breakpoint-down(sm) {
                .banner-button {
                    width: 230px;
                    font-size: 16px;
                    padding: 15px 10px 15px 10px;
                }
            }

            @include media-breakpoint-down(xs) {
                .banner-button {
                    width: 200px;
                }
            }

            @media (max-width: 420px) {
                .banner-button {
                    width: 150px;
                }
            }

            @media (max-width: 320px) {
                .banner-button {
                    width: 125px;
                }
            }
        }
    }
    /* Subpage Banners */
    .subpage-banner {

        .banner-container {
            height: 545px;
            display: flex;
            align-items: center;
            position: relative;
            background-color: $brand-dark-gray;
        }

        .banner-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .banner-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $banner-overlay;
        }

        .container-fluid {
            height: 100%;
        }

        .row {
        }

        .content {
            z-index: 2;
            padding: 15px;
            bottom: 0;
            position: absolute;

            .title-section {
                margin-left: 77px;
                margin-bottom: 77px;
                margin-right: 77px;

                .subtitle {
                    font-family: $font-family-sans-serif;
                    font-size: 26px;
                    font-weight: 300;
                    line-height: 1.4em;
                    color: $white;
                    margin-bottom: -8px;
                    display: block;
                }

                .title {
                    font-size: 50px;
                    line-height: 1.0em;
                    font-weight: 700;
                    color: $white;

                    .title-text {
                        padding-top: 5px;
                        display: block;
                    }
                }
            }



            &.has-subtitle {
            }
        }



        &.has-form {
            .container-fluid {
                @include make-container-max-widths;

                @media (min-width: 1350px) {
                    max-width: 1320px;
                }

                > .row {
                    height: 100%;
                }
            }
            /* Form panel styling */
            .form-panel {
                background-color: $concrete;
                padding: 15px 50px 15px 50px;
                box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);

                .form-title {
                    padding: 15px 0px 15px 0px;

                    p {
                        font-weight: 700;
                        font-size: 24px;
                        text-align: center;
                        line-height: 1.2em;
                        margin-bottom: 0;
                    }
                }

                .form-subtitle {
                    padding-top: 0;

                    p {
                        text-align: center;
                        font-size: 16px;
                        line-height: 1.5em;
                        margin-bottom: 0;
                    }
                }

                .umbraco-forms-page {
                    margin-bottom: 15px;

                    .umbraco-forms-field-wrapper {
                        margin-bottom: 2px;
                    }
                }

                .form-bottom-text {
                    padding-top: 0;

                    p {
                        text-align: center;
                        font-size: 12px;
                        line-height: 1;
                    }
                }
            }

            .content {
                position: relative;
                display: flex;
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                .title-column {
                    @include media-breakpoint-up(xs) {
                        @include make-col(12);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(7);
                    }

                    @include make-col-ready();
                    align-self: flex-end;

                    .title-section {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                .desktop-form-column {
                    @include media-breakpoint-up(xs) {
                        @include make-col(12);
                        display: none;
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(5);
                        display: block;
                    }

                    @include make-col-ready();

                    .form-holder {
                        position: absolute;
                    }
                }
            }

            .mobile-form {
                @include media-breakpoint-up(xs) {
                    @include make-col(12);
                    display: block;
                }

                @include media-breakpoint-up(lg) {
                    @include make-col(5);
                    display: none;
                }
                /* styling */
                margin-top: 35px;
                margin-bottom: 35px;

                @include media-breakpoint-down(xs) {
                    margin-top: 0;

                    .container {
                        padding: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .banner-container {
                height: 350px;
            }

            .content {
                .title-section {
                    margin-left: 15px;
                    margin-bottom: 15px;

                    .subtitle {
                        font-size: 18px;
                    }

                    .title {
                        font-size: 46px;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {

            .banner-container {
                height: auto;
                min-height: 250px;
            }

            .content.has-subtitle {
                margin-top: 0;
            }

            .content .title-section {
                padding: 0;
                margin-right: 15px;

                .subtitle {
                    line-height: 1.3em;
                    padding-bottom: 25px;
                }

                .title {
                    font-size: 38px;
                    padding: 0;
                }
            }
        }

        &.large-banner-style {
            .banner-container {
                height: 650px;
            }

            .content .title {
                margin: 0;
                font-family: $headings-font-family;
                font-weight: 700;
                font-size: 68px;
                line-height: 1.25;
                color: $white;
                text-align: center;
                text-transform: none;
            }

            @include media-breakpoint-down(md) {
                height: 350px;
            }

            @include media-breakpoint-down(sm) {
                height: auto;
                min-height: 250px;

                .content .title {
                    font-size: 38px;
                }
            }
        }
    }
    /* Search Landing Banner*/
    .search-landing-banner {
        .banner-container {
            display: flex;
            align-items: center;
            height: 600px;
            position: relative;
            background-color: $brand-dark-gray;
            margin-bottom: 100px;
        }

        .banner-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .banner-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $banner-overlay;
        }

        .content-container {
            height: 100%;
        }

        .content {
            z-index: 2;
            padding: 15px;
            bottom: 0;
            position: absolute;

            .text-content-holder {
                padding-top: 40px;
                padding-bottom: 40px;
            }

            .title-section {
                margin-top: 100px;
            }

            .title {
                font-size: 42px;
                line-height: 1.0em;
                padding-top: 5px;
                font-weight: 900;
                color: $white;
            }

            .subtitle {
                font-family: $font-family-sans-serif;
                font-size: 20px;
                font-weight: 300;
                line-height: 1.3em;
                color: $white;
                margin-top: 30px;
            }
            /*include media-breakpoint-up(md) {
                .blue-color {
                    white-space: nowrap;
                }
            }*/
        }



        &.has-form {
            .container-fluid {
                @include make-container-max-widths;

                @media (min-width: 1350px) {
                    max-width: 1320px;
                }

                > .row {
                    height: 100%;
                }
            }
            /* Form panel styling */
            .form-panel {
                background-color: $concrete;
                padding: 15px 50px 15px 50px;
                box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);

                .form-title {
                    padding: 15px 0px 15px 0px;

                    p {
                        font-weight: 900;
                        font-size: 24px;
                        text-align: center;
                        line-height: 1.2em;
                        margin-bottom: 0;
                    }
                }

                .form-subtitle {
                    padding-top: 0;

                    p {
                        text-align: center;
                        font-size: 16px;
                        line-height: 1.5em;
                        margin-bottom: 0;
                    }
                }

                .umbraco-forms-page {
                    margin-bottom: 15px;

                    .umbraco-forms-field-wrapper {
                        margin-bottom: 2px;
                    }
                }

                .form-bottom-text {
                    padding-top: 0;

                    p {
                        text-align: center;
                        font-size: 12px;
                        line-height: 1;
                    }
                }
            }

            .desktop-form {
                /*.form-panel {max-width: 510px;}*/
                span.field-validation-error {
                    margin-top: 2px;
                }
            }

            .content {
                position: relative;
                display: flex;
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                .title-column {
                    @include media-breakpoint-up(xs) {
                        @include make-col(12);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(7);
                    }

                    @include make-col-ready();

                    .title-section {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                .desktop-form-column {
                    @include media-breakpoint-up(xs) {
                        @include make-col(12);
                        display: none;
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(5);
                        display: block;
                    }

                    @include make-col-ready();

                    .form-holder {
                        position: absolute;
                    }
                }
            }

            .mobile-form {
                @include media-breakpoint-up(xs) {
                    @include make-col(12);
                    display: block;
                }

                @include media-breakpoint-up(lg) {
                    @include make-col(5);
                    display: none;
                }
                /* styling */
                margin-top: 35px;
                margin-bottom: 35px;

                @include media-breakpoint-down(xs) {
                    margin-top: 0;

                    .container {
                        padding: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .banner-container {
                height: 350px;
            }

            .content-container {
                .row {
                    height: 100%;
                    align-items: center;
                }
            }

            .content {
                margin-left: 15px;
                margin-right: 15px;

                .text-content-holder {
                    padding-top: 0;
                    padding-bottom: 0;

                    .title-section {
                        margin-top: 0px;
                        margin-left: 0px;
                        margin-bottom: 0px;

                        .title {
                            font-size: 42px;
                        }

                        .subtitle {
                            font-size: 18px;
                            margin-top: 0;
                            padding-bottom: 5px;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {

            .banner-container {
                margin-bottom: 0;
                height: auto;
                min-height: 350px;
            }

            .content.has-subtitle {
                margin-top: 0;
            }

            .content .title-section {
                padding: 0;
                margin-right: 15px;

                .title {
                    font-size: 38px;
                    padding: 0;
                }

                .subtitle {
                    line-height: 1.3em;
                    padding-bottom: 25px;
                }
            }
        }
    }
    /* Locations Page Banner */
    .locations-page-banner {
        position: relative;

        .banner-container {
            height: 200px;

            .content-container {
                padding-left: 15px;
                padding-right: 15px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .banner-bg {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
            }

            .bg-color {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;

                &.bg-dcebef {
                    background-color: $brand-blue-lighter-5;
                }
            }

            .row {
                position: relative;
                z-index: 1;

                .content {
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    padding-left: 15px;
                    padding-right: 15px;

                    .title {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

