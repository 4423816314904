﻿///<reference path="../../main.scss" />
// File Name: _squareImage.scss
// Description: Styles for Square Image Grid Editor
// ------------------------------------------------------------

.squareImage {
    .square-image {
        margin-bottom: 50px;
        width: 100%;
        height: auto;

        /*&.with-border {
            img {
                border: 1px solid $black;
            }
        }*/

        img {
            width: 100%;
            height: auto;
        }
    }

    @include media-breakpoint-between(sm, md) {
        .square-image {
            text-align: center;


            img {
                //width: 75%;
                display: inline-block;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .square-image {
            max-width: 600px;
        }
    }

    @include media-breakpoint-up(md) {
        &:first-child .square-image {
            margin-right: auto;
        }

        &:last-child .square-image {
            margin-left: auto;
        }
    }
}