﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

.horizontal-icon-list-cta-widget {
    /* if row fluid on config node, fallback padding */
    padding-top: 20px;
    padding-bottom: 46px;
}
/* styles if no fluid bg set on row config*/
.horizontalIconListCTA-section {
    > .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        > .body {
            margin-left: 0;
            margin-right: 0;

            > .column.horizontalIconListCTA {
                padding-left: 0px;
                padding-right: 0px;

                .horizontal-icon-list-cta-widget {
                    padding-top: 67px;
                    padding-bottom: 70px;

                    &.reduce-padding {
                        padding-bottom: 0;
                        padding-top: 0;
                    }
                }
            }
        }
    }
}

.horizontal-icon-list-cta-widget {
    text-align: center;

    &.has-bg-color {
        h2, h3, .text p, .subheading p {
            color: $white;
        }

        &.bg-12317b, .bg-0e283f {
            background-color: $brand-deep-koamaru;
        }
    }

    .headline {
        font-family: $headings-font-family;
        font-size: 46px;
        font-weight: 800;
        line-height: 52px;
        margin-bottom: 15px;

        &.headline-on-top {
            text-align: center;
        }
    }

    @include media-breakpoint-down(sm) {
        .headline {
            font-size: 38px;
        }
    }

    .subheading {
        display: block;
        text-align: center;
        margin-bottom: 50px;

        p {
            display: inline-block;
            font-size: 30px;
            font-weight: 100;

            @include media-breakpoint-down(sm) {
                font-size: 26px;
            }
        }
    }

    &.reduce-padding .list {
        margin-bottom: 25px;
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: 20px;

        &.has-text {
            margin-bottom: 15px;
        }


        .list-item-wrapper {
            /* default */
            @include media-breakpoint-up(xs) {
                @include make-col(12);
            }

            @include media-breakpoint-up(md) {
                @include make-col(4);
            }

            @media (min-width: 1200px) {
                @include make-col(2);
            }

            @include make-col-ready();

            .list-item {
                min-width: 20%;
            }
        }

        &.count-2, &.count-3, &.count-4, &.count-5, &.count-6 {
            max-width: 1500px;
            margin-left: auto;
            margin-right: auto;
        }

        &.count-7, &.count-8, &.count-9, &.count-10 {
            .list-item-wrapper {
                @include media-breakpoint-up(xs) {
                    @include make-col(12);
                }

                @include media-breakpoint-up(md) {
                    @include make-col(4);
                }

                @media (min-width: 1200px) {
                    @include make-col(3);
                }

                @include make-col-ready();
            }
        }

        .list-item {
            text-align: center;
            margin-bottom: 45px;
            /*max-width: 250px;*/
            @include media-breakpoint-down(sm) {
                max-width: unset;
            }

            .icon {
                margin-left: auto;
                margin-right: auto;
                background-color: $white;
                border-radius: 50%;
                height: 120px;
                width: 120px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 100%;
                    max-width: 55px;
                    max-height: 55px;
                }
            }

            .title {
                font-size: 20px;
                font-weight: 600;
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .text {
        font-size: 22px;
        line-height: 1.7em;
        max-width: 1550px;
        margin-left: auto;
        margin-right: auto;
    }

    .buttons {
        margin-top: 70px;
    }

    @include media-breakpoint-down(md) {
        .list .list-item .title {
            max-width: 120px;
        }
    }

    @include media-breakpoint-down(sm) {
        .list {
            display: flex;
            margin-bottom: 0;

            .list-item-wrapper {
                display: block;
                width: 100%;
                text-align: left;
                margin-bottom: 25px;

                .list-item {
                    display: flex;
                    text-align: left;
                    padding-left: 0;
                    padding-right: 0;
                    margin-bottom: 10px;

                    .icon {
                        margin-left: 0;
                        margin-right: 0;
                        height: 80px;
                        width: 80px;
                        flex: 0 0;
                        flex-basis: 80px;

                        img {
                            max-width: 40px;
                            max-height: 60px;
                        }
                    }

                    .title {
                        max-width: 100%;
                        margin-left: 20px;
                        margin-right: 0;
                        margin-top: 25px;
                    }

                    @include media-breakpoint-down(xs) {
                        .title {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .subheading{
            margin-bottom: 0px;
        }
        .text {
            padding-top: 15px;
            text-align: center;
            font-size: 16px;

            p {
                /*line-height: 1.95em;*/
            }
        }

        .buttons {
            margin-top: 55px;
        }
    }


    @include media-breakpoint-down(xs) {
        .buttons a {
            width: 100%;
        }
    }

    @media (max-width: 1534px) {
        .list .list-item .title {
            max-width: 170px;
        }
    }
}