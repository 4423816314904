﻿///<reference path = "../marathon.scss" />
// File Name: _grid.scss
// Description: Project grid
// ------------------------------------------------------------

.container {
    @include make-container();
}

.container-fluid-full {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
}


.body {
    @include make-row();
}

.row {
    &.googleMap {
        -ms-flex-direction: column;
        -webkit-flex-direction: column;
        flex-direction: column;
        -ms-flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    > .body {
        margin-left: 0;
        margin-right: 0;
    }
}

.column {
    &.column-1 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(1);
        }
    }
    &.column-2 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(2);
        }
    }
    &.column-3 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(3);
        }
    }

    &.column-4 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
    }
    &.column-5 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(5);
        }
    }
    &.column-6 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(6);
        }
    }
    &.column-7 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(7);
        }
    }
    &.column-8 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
    }

    &.column-9 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(9);
        }
    }
    &.column-10 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(10);
        }
    }
    &.column-11 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(11);
        }
    }
    &.column-12 {
        @include make-col-ready();

        @include media-breakpoint-up(lg) {
            @include make-col(12);
        }
    }
}
