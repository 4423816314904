﻿///<reference path="../../main.scss" />
// File Name: _forms.scss
// Description: Styles
// ------------------------------------------------------------

/* Forms */
.umbraco-forms-page {
    max-width: 867px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    line-height: 1.5em;

    .row-fluid {
        display: flex;
        margin-left: -15px;
        margin-right: -15px;

        .umbraco-forms-container.col-md-6 + .col-md-6 {
            padding-left: 0;
        }
    }

    .umbraco-forms-field-wrapper {
        margin-bottom: 15px;

        input[type="text"], textarea {
            width: 100%;
            font-size: 16px;
            padding: 0px 10px;
            border: 2px solid $alto;
            border-radius: 8px;

            &:focus {
                outline-color: transparent;
                border-color: $brand-blue-lighter-3;
            }
        }

        textarea {
            min-height: 70px;
        }

        input[type="text"] {
            padding: 2px 10px;
            height: 40px;

            &::placeholder {
                color: $brand-somewhat-light-gray;
            }

            &.input-validation-error {
                border-color: $red-ada;
            }
        }

        .field-validation-error {
            color: $red-ada;
            font-size: 13px;
            display: block;
            line-height: 1.5em;
            text-align: right;
            margin-top: 5px;
        }
    }

    .umbraco-forms-field:last-child {
        .umbraco-forms-field-wrapper {
            .field-validation-error {
                position: relative;
                top: -4px; // idk why this gap is there.
            }
        }
    }

    label {
        font-size: 16px;
        font-weight: 700;
        color: $black;
        margin-bottom: 0;
        position: relative;
        top: 4px;
    }

    .umbraco-forms-indicator {
        color: $red-ada;
    }

    .umbraco-forms-field.recaptcha {
        iframe, .g-recaptcha > div {
            width: 100%;
        }

        margin-bottom: 1em;
    }

    .umbraco-forms-navigation {
        text-align: right;
        padding-top: 20px;

        input[type="submit"] {
            @include btn-blue;
            font-size: 18px;
            padding: 10px 25px;
            text-transform: none;
            min-height: 50px;
            width: 100%;
            border-radius: 8px;
        }
    }
}
/* end page styles*/

label.error {
    color: $red-ada;
}
.text-location-holder {
    .error {
        z-index: 1002;
    }
}

.form-field-hint {
    display: none;
    border: 1px solid #e7e7e7;
    padding: 10px;
}

ul.validation-hints {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li:before {
        font-family: FontAwesome;
        content: $fa-var-square-o;
        display: inline-block;
        margin-right: 10px;
    }

    li.pass {
        color: green;

        &:before {
            content: $fa-var-check-square-o;
        }
    }
}

input[type="text"], input[type="password"] {
    font-family: $font-family-sans-serif;
    border: 1px solid $brand-very-light-gray;
    padding: 8px 8px;

    /*&:focus {
        outline-color: $brand-very-light-gray;
    }*/
}
select, .checkbox label {
    font-family: $font-family-sans-serif;
    font-size: 14px;
    padding: 8px 10px;
    border-color: $brand-very-light-gray;
}
.checkbox label {
    font-weight: 600;
    padding-top: 0;
}
select {
    padding-right: 20px;
    background-color: $white;
}

.btn, input[type="submit"] {
    white-space: normal;
}

.checkbox .checkbox-holder {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0px;

    input[type=checkbox] {
        -webkit-appearance: none;
        background-color: $white;
        width: 16px;
        height: 16px;
        border: 1px solid $brand-very-light-gray;
        position: relative;

        &:checked {
            background-color: $brand-green;
            /*background-image: url("/dist/img/checked.png");*/
            background-size: 100%;
            image-rendering: -webkit-optimize-contrast;
            background-position: center center;
        }

        &:focus {
            outline-color: $brand-green;
            border-color: $brand-green;
        }

        &:checked::after {
            font-family: FontAwesome;
            content: "\f00c";
            color: $white;
            vertical-align: middle;
            font-size: 10px;
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            overflow: hidden;
        }
    }
}

.blue-checkbox {
    position: relative;
    display: inline-block;
    height: 30px;
    width: 30px;
}

.blue-checkbox .checkbox-holder {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 0px;

    input[type=checkbox] {
        -webkit-appearance: none;
        background-color: $white;
        width: 25px;
        height: 25px;
        border: 2px solid $brand-very-light-gray;
        position: relative;

        &:checked {
            background-size: 100%;
            image-rendering: -webkit-optimize-contrast;
            background-position: center center;
        }

        &:focus {
            outline-color: $brand-blue-headings;
            border-color: $brand-blue-headings;
        }

        &:checked::after {
            font-family: FontAwesome;
            content: "\f00c";
            color: $brand-blue-headings;
            vertical-align: middle;
            font-size: 17px;
            display: block;
            width: 100%;
            height: 100%;
            margin-top: -2px;
            text-align: center;
            overflow: hidden;
        }
    }
}

.select-holder {
    position: relative;

    select {
        -webkit-appearance: none;
        padding-right: 30px;
    }

    &::after {
        position: absolute;
        font-family: FontAwesome;
        content: "\f107";
        color: $brand-dark-gray;
        vertical-align: middle;
        font-size: 18px;
        display: block;
        right: 5px;
        top: 0px;
        width: 20px;
        height: 100%;
        text-align: center;
        overflow: hidden;
        z-index: 2;
        pointer-events: none;
    }
}

/*no zoom on ios text focus please*/
input[type="text"].nozoom, input[type="text"].datepicker {
    @media (max-width: 768px) {
        font-size: 1em;
    }
}



@include media-breakpoint-up(lg)
{
    section .column-5.macro{
        padding-left: 50px;
        padding-bottom: 30px;
    }
}