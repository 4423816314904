﻿///<reference path="../../main.scss" />
// File Name: _explore-locations-grid-editor.scss
// Description: Styles for the Explore Locations Grid Editor
// ------------------------------------------------------------

/* styles if no fluid bg set on row config*/
.exploreLocationsCTA-section {
    > .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        > .body {
            margin-left: 0;
            margin-right: 0;

            > .column.exploreLocationsCTA {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
.explore-locations-grid-editor {
    position: relative;
    padding: 15px;
    min-height: 372px;
    text-align: center;
    background-color: $brand-blue-lighter-5;
    display: flex;
    justify-content: center;
    align-items: center;
    // apparently ie11 is dumb and doesnt like min-height too much
    // so we set a height smaller than the min-height to that ie11 sees it
    // overrides the height with the actual min-height and then things will be back to normal
    // pro dev move
    height: 100px;

    @include media-breakpoint-down(lg) {
        min-height: 372px;
    }

    @include media-breakpoint-down(md) {
        min-height: 372px;
    }

    @include media-breakpoint-down(sm) {
        min-height: 372px;
    }

    .image-bg-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        @include media-breakpoint-down(lg) {
            top: 30px;
            bottom: 30px;
        }

        @include media-breakpoint-down(md) {
            top: 30px;
            bottom: 30px;
        }

        @include media-breakpoint-down(sm) {
            top: 60px;
            bottom: 60px;
        }

        @include media-breakpoint-down(xs) {
            top: 100px;
            bottom: 100px;
        }
    }

    .image-bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .inner-content {
        position: relative;
        z-index: 1;
    }

    .inner-content {
        .headline {
            font-family: $headings-font-family;
            font-size: 58px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(md) {
            .headline {
                font-size: 52px;
            }
        }

        .buttons {
            width: 100%;
            margin-top: 20px;

            div {
                @include make-col-ready();
                margin-bottom: 15px;
                text-align: center;

                a {
                    display: inline-block;
                    transition: 0.3s transform;

                    .arrow {
                        display: inline-block;
                        color: $brand-mariner-blue;
                        font-size: 55px;
                        margin-bottom: 0px;
                        background-image: url("/dist/img/blue-arrow.png");
                        width: 48px;
                        height: 48px;
                        background-position: center center;
                        background-size: contain;
                        transition: 0.3s transform, 0.2s color;
                        -webkit-backface-visibility: hidden;
                    }

                    .name {
                        display: block;
                        font-weight: 600;
                        font-size: 18px;
                        text-transform: uppercase;
                        color: $black;
                        transition: 0.3s color;
                        -webkit-backface-visibility: hidden;
                    }

                    &:hover {
                        text-decoration: none;
                        transform: scale(1.0);

                        .name {
                            color: $brand-mariner-blue;
                        }

                        .arrow {
                            transform: scale(1.12);
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1399px) {
    }

    @include media-breakpoint-down(lg) {
    }
}