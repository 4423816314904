﻿/// <reference path = "../../main.scss" />
// File Name: _search.scss
// Description: Styles for the Locations page (country)
// ------------------------------------------------------------

body.stateLocation {
    main {
        padding-top: 0;

        .mobile-overlay + .breadcrumbs {
            margin-top: 0;
        }
    }

    .headline {
        text-align: center;
    }

    .page-content {
        margin-top: 50px;

        h1 {
            font-size: 30px;
            margin-bottom: 0.5rem;
        }

        .locations-container {
            margin-bottom: 20px;

            .locations-row {
                @include make-row();
                display: flex;
                margin-top: 20px;
                margin-bottom: 80px;

                .locations-column {
                    margin-bottom: 0px;
                    line-height: 1.25em;

                    @include media-breakpoint-up(xs) {
                        @include make-col(6);
                    }

                    @include media-breakpoint-up(md) {
                        @include make-col(6);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(2);
                    }

                    @include make-col-ready();



                    .state-title {
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 1.25em;
                        margin-bottom: 10px;

                        a {
                            color: $black;

                            &:hover {
                                color: $brand-blue;
                                text-decoration: underline;
                            }
                        }
                    }

                    .item-wrapper {
                        font-size: 16px;
                        line-height: 1.5em;

                        a {
                            color: $black;
                            display: inline-block;

                            &:hover {
                                color: $brand-blue;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .recently-viewed-properties-horizontal-grid-editor {
        padding: 80px 70px;

        @include media-breakpoint-down(sm) {
            padding: 30px 10px;
        }
    }
}

/* Tablet sizes, make the most of the real estate */
@media (max-width: 768px) {
}

@include media-breakpoint-down(sm) {
}

@keyframes waterdrop {
    from {
        opacity: 0.75;
        transform: scale(1.0);
    }

    to {
        opacity: 0.0;
        transform: scale(4.5);
    }
}
