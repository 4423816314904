﻿/// <reference path = "../marathon.scss" />
// File Name: _variables.scss
// Description: Project variables
// ------------------------------------------------------------

$spacer:        2rem;
$enable-flex:   true;
$black:         #000;
$white:         #fff;
$empty-gutters: (
  xs: 0px,
  sm: 0px,
  md: 0px,
  lg: 0px,
  xl: 0px
    )