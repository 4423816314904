﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

.horizontalCallout-section {
    > .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        > .body {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.column.column-12.horizontalCallout {
    padding-left: 0;
    padding-right: 0;
}

.horizontal-callout-grid-editor {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &.has-bg-color {
    }

    .inner-content {
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
        min-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(md) {
            & {
                display: block !important;
            }
        }

        > div {
        }

        .heading {
            color: $white;
            font-size: 32px;
            line-height: 1.5em;

            > a:hover {
                text-decoration: none;

                span {
                    border-bottom: 1px dotted #0056b3;

                    &.red-color {
                        border-color: $brand-red;
                    }

                    &.seagull-blue-color {
                        border-color: $brand-seagull-blue;
                    }

                    &.blue-color, &.malibu-blue-color {
                        border-color: $brand-malibu-blue;
                    }

                    &.mariner-blue-color {
                        border-color: $brand-mariner-blue;
                    }

                    &.dark-blue-color, &.deep-koamaru {
                        border-color: $brand-deep-koamaru;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .heading {
                max-width: 100% !important;
                width: 100% !important;
                text-align: center !important;
                flex: unset !important;
            }
        }

        .content {
            color: $white;
            padding-top: 20px;
            padding-bottom: 40px;
            max-width: 690px;
            display: inline-block;
            font-size: 22px;
            line-height: 1.425em;
        }

        .content-spacer {
            padding-bottom: 25px;
        }
    }

    .btn-holder {
        text-align: center;
        padding-bottom: 20px;

        @include media-breakpoint-down(md) {
            & {
                display: block;
                max-width: 100%;
                width: 100%;
            }
        }

        .btn-white {
            @include btn-white;
            padding: 20px 50px;
        }

        .btn-brand-blue-white {
            @include btn-brand-blue-white;
            padding: 20px 50px;
        }

        .btn-btn-blue-white {
            @include btn-btn-blue-white;
            padding: 20px 50px;
        }

        .btn {
            @include media-breakpoint-down(sm) {
                padding: 5px 20px;
                font-size: 16px;
            }
        }
    }

    &.btn-placement-right, &.btn-placement-left {
        .inner-content {
            min-height: 0;

            @include media-breakpoint-down(sm) {
                padding-top: 30px;
            }

            > div {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .heading {
                    max-width: 65%;
                    flex: 0 0 65%;
                    text-align: left;
                }

                .btn-holder {
                    max-width: 35%;
                    flex: 0 0 35%;
                }

                @include media-breakpoint-down(md) {
                    .btn-holder {
                        max-width: unset;
                        flex: unset;
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    &.btn-placement-left {
        .inner-content > div .heading {
            order: 2;
        }
    }

    @include media-breakpoint-down(sm) {
        .inner-content {
            padding-top: 15px;
            padding-bottom: 15px;
            min-height: 0;

            .heading {
                font-size: 26px;
            }

            .content {
                font-size: 14px;
                padding: 15px 30px;
                max-width: 490px;
            }
        }
    }
    // IE11
    &.ie-browser {
        .inner-content {
            display: block !important;

            @include media-breakpoint-down(md) {
                > div {
                    display: block !important;
                }

                .btn-holder {
                    text-align: center !important;
                    display: block !important;
                    max-width: 100% !important;

                    .btn {
                        display: inline-block !important;
                    }
                }
            }
        }
    }
}