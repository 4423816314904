﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.bg-0e283f, .bg-031026, .bg-12317b {
    /*dark override*/
    p, ul li, div {
        color: $white;
    }

    h1, h2, h3, h4, h5, h6 {
        color: $white;
    }
}
.bg-0e283f {
    background-color: $brand-blue-darker-4;
}
.bg-12317b {
    background-color: $brand-deep-koamaru;
}
.bg-031026 {
    background-color: $brand-black-pearl;
}

.bg-f3f3f3 {
    background-color: $concrete;
}
.bg-f5f5f5{
    background-color: $brand-wild-sand-gray;
}

div.container {
    &.max-width-short {
        @include media-breakpoint-between(xl, xl) {
            & {
                max-width: 1050px !important;
            }
        }
    }

    &.max-width-wide {
        @media (min-width: 1535px) {
            max-width: 1505px;
        }

        @media (min-width: 1670px) {
            max-width: 1610px;
        }
    }

    &.max-width-standard {
        @media (min-width: 1350px) {
            max-width: 1320px;
        }
    }
}

.rte {
    p.img-left img {
        float: left;
        margin: 0 30px 30px 0;
        max-width: 50% !important;
        height: auto !important; 
    }

    p.img-right img {
        float: right;
        margin: 0 0 30px 30px;
        max-width: 50% !important;
        height: auto !important;
    }

    @include media-breakpoint-down(sm) {
        p.img-left img {
            margin: 0 15px 15px 0;
        }

        p.img-right img {
            margin: 0 0 15px 15px;
        }
    }
}


p {
    img {
        @extend .img-fluid;
    }

    &.white-color {
        color: $white;
    }

    &.small-p-text {
        font-size: 14px;
        line-height: 1.8em;
    }

    @include media-breakpoint-down(sm) {
        line-height: 1.6em;
    }
}
a.skip-content {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

a.skip-content:focus {
    position: absolute;
    width: auto;
    height: auto;
    left: 5px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    background-color: $black;
    top: 5px;
    z-index: 1032;
}

main {
    margin-top: 105px;
    padding-top: 15px;
    min-height: 400px;
    // Reverse ordering of columns in a row on mobile (from the row config)
    @include media-breakpoint-down(md) {
        .reverse-mobile-order-2 {
            > .body {
                .column {
                    &:nth-child(1) {
                        order: 2;
                    }

                    &:nth-child(2) {
                        order: 1;
                    }
                }
            }
        }

        .reverse-mobile-order-3 {
            > .body {
                .column {
                    &:nth-child(1) {
                        order: 3;
                    }

                    &:nth-child(2) {
                        order: 2;
                    }

                    &:nth-child(3) {
                        order: 1;
                    }
                }
            }
        }

        .reverse-mobile-order-4 {
            > .body {
                .column {
                    &:nth-child(1) {
                        order: 4;
                    }

                    &:nth-child(2) {
                        order: 3;
                    }

                    &:nth-child(3) {
                        order: 2;
                    }

                    &:nth-child(4) {
                        order: 1;
                    }
                }
            }
        }
    }
    // Mobile padding row configuration. This is hacked so it might now work all the time.
    @include media-breakpoint-down(xs) {
        .mobile-padding-15px {
            .container {
                padding: 15px 30px 15px 30px;
            }
        }
    }

    > .content-grid {
        .content-body {
            min-height: 420px;
        }
    }

    .center {
        text-align: center;
    }

    @include media-breakpoint-down(md) {
        margin-top: 43px;
    }

    .btn {
        min-width: 280px;

        @include media-breakpoint-down(sm) {
            padding: 10px 20px;
            min-width: 0;
            font-size: 16px;
        }
    }

    .column.column-12.rte {
        padding-bottom: 35px;

        &.horizontalBreak {
            padding-bottom: 15px;
        }

        img {
            //border: 1px solid $black;
            max-width: 100%;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }

    .column {
        img {
            max-width: 100%;
        }
    }

    .container {
        @media (min-width: 1350px) {
            max-width: 1320px;
        }
    }

    .container-fluid-padding {
        padding-left: 70px;
        padding-right: 70px;
    }

    .container-fluid-bg.light-blue-bg {
        background-color: $brand-blue-light-bg;
        padding-top: 50px;
        padding-bottom: 50px;

        @include media-breakpoint-down(sm) {
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }

    .padded-section {
        padding-top: 50px;
        padding-bottom: 50px;

        @include media-breakpoint-down(sm) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .bp-mediumPadding {
        > .body {
            padding-bottom: 30px;
        }
    }

    .tp-mediumPadding {
        > .body {
            padding-top: 30px;
        }
    }

    .bp-largePadding {
        > .body {
            padding-bottom: 60px;
        }
    }

    .tp-largePadding {
        > .body {
            padding-top: 60px;
        }
    }

    .container-fluid .macro {
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0;
        padding-right: 0;
    }

    .flex-row {
        display: flex;
        flex-wrap: wrap;
    }
    /* bg color column top padding for first headline*/
    .content-body {
        > section.has-bgcolor:first-child {
            .body > .column-12.headline:first-child {
                padding-top: 15px;
            }
        }
    }

    @media (min-width: 1400px) {
        .max-width-large {
            max-width: 1240px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media (min-width: 1199px) {
        .max-width-med {
            max-width: 895px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }

    &.has-banner-form {
        .content-body > section:first-child {
            padding-top: 60px;
        }
    }
}

.breadcrumbs {
    > .container {
        max-width: none;
    }

    > .container > .row {
        margin-left: 0px;
        margin-right: 0px;
    }

    ul {
        list-style-type: none;
        padding: 0px 0px 10px 0;
        margin: 0;
        margin-top: 0;
        margin-left: 15px;
        line-height: 1.6em;

        li {
            display: inline-block;
            color: $black;
            font-size: 12px;
            font-weight: 500;

            a {
                color: $brand-dark-chalk;
            }

            + li {
                padding-left: 0px;

                &:before {
                    content: "/";
                    padding-right: 5px;
                    color: $black;
                }
            }
        }
    }
}

    .fluid-breadcrumb {
        .breadcrumbs {
            padding-left: 0px;
            padding-right: 0px;

            > .container {
                max-width: none;
            }
        }
    }

    .tp {
        padding-top: 40px;
    }
    /* Livechat */
    .livechat {
        position: fixed;
        top: 100vh;
        right: 15px;
        z-index: 2;
        display: none;

        .modal-window {
            margin-top: -47px;
            background-color: $white;
            width: 280px;
            border-radius: 8px;
            transition: 0.3s margin-top;
            border: 1px solid $white;

            .titlebar {
                background-color: $brand-green;
                cursor: pointer;
                border-radius: 8px 8px 0 0;
                padding: 8px 24px;
                color: $white;

                > * {
                    vertical-align: middle;
                }

                .chat-icon {
                    background-image: url("/dist/img/chat.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    height: 30px;
                    width: 33px;
                    margin-right: 15px;
                    display: inline-block;
                }

                .mode-icon {
                    float: right;
                    font-size: 26px;
                }

                .title {
                    font-family: $headings-font-family;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            .content {
                padding: 15px;
                height: 335px;
            }
        }

        &.active .modal-window {
            margin-top: -389px;
        }

        &.active.dont-move .modal-window {
        }
    }
    /* Global slick */
    .slick-prev, .slick-next {
        height: 36px;
        text-shadow: 0 0 10px #000;
    }

    .slick-prev::before {
        content: "\f104";
        font-family: FontAwesome;
        font-size: 36px;
    }

    .slick-next::before {
        content: "\f105";
        font-family: FontAwesome;
        font-size: 36px;
    }
    .slick-prev:focus, .slick-next:focus {
        outline: 1px dotted rgba(255,255,255, 0.50);
    }


    /* Global Loading Vue Loading Ajax*/
    .loading-spinner-container {
        transition: height 0.4s ease, opacity 0.4s ease;
        position: absolute;
        z-index: 10;
        top: 0%;
        justify-content: center;
        align-items: flex-start;
        pointer-events: none;
        opacity: 0;

        &.load {
            display: flex;
            background-color: rgba(255, 255, 255, 0.79);
            height: 100%;
            width: 100%;
            opacity: 1;

            .loading-spinner-wrapper {
                visibility: visible;
                margin-top: 80px;
            }
        }

        &.no-load {
            .loading-spinner-wrapper {
                //display: none;
                visibility: hidden;
            }

            &.was-shown {
                .loading-spinner-wrapper {
                    display: none;
                }
            }
        }

        .loading-spinner-wrapper {
            width: 100%;
            text-align: center;
        }

        .spinner {
            width: 32px;
            display: inline-block;
            padding-bottom: 20px;
        }
    }
    /* Bootstrap */
    .btn {
        cursor: pointer;
        /* close modal */
        &.btn-secondary {
            border-color: $brand-blue-btn-active;
            border-width: 2px;
            border-radius: 0px;
            background-color: $white;
            color: $brand-blue-btn-active;
            min-width: 150px;
            font-weight: bold;
            padding: 0.75rem 1rem;

            &:hover {
                background-color: $brand-blue-btn-active;
                color: $white;
            }
        }
    }
    /* End Bootstrap */