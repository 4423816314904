﻿html.ie {
    .locationsPage .map-banner {
        #google_map {
            #markerLayer {
                > div {
                    &::before {
                        content: "";
                        display: none;
                        border-radius: 50%;
                        animation-name: none;
                    }
                }
            }
        }
     }
}