﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------
/* Modals */
.modal-backdrop.show {
    background-color: $brand-dark-gray;
    opacity: 0.85;
}

.modal.centered {
    text-align: center;

    .modal-header {
        display: block;
        border-bottom: 0px;
        margin-right: 20px;
        margin-left: 20px;

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }

    .modal-footer {
        border-top: 0px;
        justify-content: center;
    }
}
/* Vertical Center Fix */
.modal-content {
    width: 100%;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (1rem * 2));
}

@media (min-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - (2rem * 2));
    }
}
/* End Vertical */

.modal-content {
    border-radius: 0px;

    & {
        code {
            max-height: 80px;
            overflow-y: auto;
            display: block;
            margin-top: 15px;
        }

        .modal-header {
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;

            .close {
                cursor: pointer;
            }
        }

        .modal-title {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.3em;
        }

        .modal-body {
            font-size: 14px;
            margin-top: 15px;
            padding-top: 0;

            .error-style {
                color: $red;
            }

            .error-content {
                padding-bottom: 15px;
            }
        }
    }
}

.modal-full {


    .modal-dialog {
        width: auto;
        max-width: 100%;
        margin-bottom: 0;
        max-height: 200px;
        justify-content: center;

        .modal-content {
            width: 100%; /*calc(100% - 30px);*/
            padding-left: 15px;
            padding-right: 15px;
            height: 100%;
            max-height: 100vh;
            align-items: center;
            background-color: transparent;
            border: 0;
            position: unset;

            .modal-body {
                width: 1200px;
                max-width: 100%;
                /*@media (max-width: 830px) {
                    max-width: calc(100vw - 30px);
                }*/
                /*
                @media (max-width: 576px) {
                    max-width: 400px;
                }*/
            }

            .close {
                color: $white;
                opacity: 1;
                top: 0;
                right: 0;
            }
        }
    }
}

.modal-very-large {
    .modal-dialog {
        max-width: 1160px;
    }

    @media (max-width: 1160px) {
        .modal-dialog {
            margin: 30px;
            transform: translateX(-50%);
        }
    }

    @media (max-width: 768px) {
        .modal-dialog {
            margin: 15px;
        }
    }
}


@media (min-width: 576px) {
    .modal-sm {
        max-width: 400px;
    }
}

.modal.loading {
    max-height: 100vh;
    overflow-y: hidden;

    &::before {
        content: " ";
        position: absolute;
        height: 128px;
        width: 128px;
        background-image: url("/dist/img/loading.gif");
        background-repeat: no-repeat;
        background-position: center center;
        display: inline-block;
        background-color: $white;
        top: 50%;
        left: 50%;
        border-radius: 15px;
        transform: translateY(-50%) translateX(-50%);
    }

    .modal-body {
        visibility: hidden;
    }
}