﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

.horizontalCTAWidget-section {
    .container {
        @media (min-width: 1535px) {
            max-width: 1505px;
        }

        @media (min-width: 1670px) {
            max-width: 1610px;
        }
    }
}

.horizontal-cta-widget {
    padding-top: 72px;
    padding-bottom: 72px;

    @include media-breakpoint-down(sm) {
        padding-top: 35px;
        padding-bottom: 55px;
    }

    .subheadline {
        //padding-left: 15px;
        //padding-right: 15px;
    }

    .subheadline.desktop {
        display: block;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .subheadline.mobile {
        display: none;
        font-family: "Work Sans", Arial, sans-serif;
        font-size: 28px;
        font-weight: 300;
        line-height: 1.1;
        text-align: center;
        color: $black;
        margin-bottom: 24px;

        @include media-breakpoint-down(md) {
            display: block;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .inner-content {
        &.image-left .col-text .inner {
            margin-left: 34px;
        }

        &.image-right .col-text .inner {
            margin-right: 34px;
        }
    }

    .headline-top-holder {
        text-align: center;
        padding-bottom: 40px;

        &.no-top {
            display: none;

            .subheadline {
                margin-top: 25px;
                margin-bottom: 0;
                font-family: "Work Sans", Arial, sans-serif;
                font-size: 28px;
                font-weight: 300;
                line-height: 1.1;
                text-align: center;
                color: $black;
            }

            @include media-breakpoint-down(md) {
                display: block;
            }
        }
    }

    .headline {
        font-family: $headings-font-family;
        font-size: 46px;
        font-weight: 800;
        line-height: 52px;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 42px;

            &.desktop {
                display: none;
            }
        }

        @include media-breakpoint-down(sm) {
            font-size: 38px;
            line-height: 1.1;
        }

        &.headline-on-top {
            text-align: center;
        }
    }

    .headline + .subheadline {
        margin-top: 5px;
    }

    .cta-row {
        @include make-row();

        .col-image {
            &.squareImage {
                position: relative;

                .square-image {
                    margin: 0;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .image-fit {
                        display: none;
                        position: absolute;
                        height: 100%;
                        width: auto;
                        left: 15px;
                        right: 15px;
                        top: 0;
                        background-size: cover;
                        background-position: center center;
                    }
                }
            }

            @include media-breakpoint-up(xs) {
                @include make-col(12);
            }

            @include media-breakpoint-up(lg) {
                @include make-col(5);
            }

            @media (min-width: 1535px) {
                max-width: 600px;
            }

            @include make-col-ready();

            img {
                width: 100%;
            }
        }

        .col-text {
            @include media-breakpoint-up(xs) {
                @include make-col(12);
            }

            @include media-breakpoint-up(lg) {
                @include make-col(7);
                flex-grow: 1;
            }

            @include make-col-ready();

            .subheadline {
                font-family: $font-family-sans-serif;
                font-size: 32px;
                font-weight: 300;
                line-height: 1.3em;
                text-align: left;
                color: $black;
                margin-bottom: 24px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 0;
                }
            }

            .text {
                font-size: 18px;
                font-weight: 400;
                line-height: 1.4em;

                @include media-breakpoint-down(md) {
                    margin-top: 30px;
                }

                ul {
                    margin-top: 55px;

                    li {
                        line-height: 1.05em;
                        margin-bottom: 1.0em;

                        &::before {
                            transform: translateY(-10%);
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .list-as-two-columns-separate {
                    margin-bottom: 2rem;
                    margin-top: 55px;

                    ul.list-column {
                        margin-bottom: 0;
                        width: 50%;
                        float: left;
                        padding-left: 0;
                        margin-top: 0;

                        @include media-breakpoint-down(lg) {
                            width: 50%;
                        }

                        @include media-breakpoint-down(xs) {
                            width: 100%;
                        }

                        li {
                            line-height: 1.05em;
                            margin-bottom: 1.0em;

                            &::before {
                                transform: translateY(-10%);
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    &::after {
                        content: " ";
                        clear: both;
                        display: block;
                    }

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 2rem;
                        margin-top: 2rem;

                        ul.list-column {
                            margin-bottom: 0;
                            margin-top: 0;

                            li {
                                line-height: 1.05em;
                                padding-bottom: 0px;
                                display: block;
                                width: 100%;

                                &:before {
                                    top: 1px;
                                    position: relative;
                                }

                                &:last-child {
                                    margin-bottom: 1.2em;
                                }
                            }
                        }
                    }
                }

                p {
                    margin-bottom: 2em;

                    &:last-child {
                        //margin-bottom: 0;
                    }
                }
            }

            h2 + .text {
                margin-top: 34px;
            }

            @include media-breakpoint-down(sm) {
                .text {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.95em;

                    ul {
                        margin-top: 0px;

                        li {
                            line-height: 1.05em;
                            padding-bottom: 0px;
                            display: block;
                            width: 100%;

                            &:before {
                                top: 1px;
                                position: relative;
                            }

                            &:last-child {
                                margin-bottom: 1.2em;
                            }
                        }
                    }
                }
            }

            .small-column-content {
                @include make-row();
                margin-top: 2.5em;

                .col {
                    margin-right: 2em;
                }

                .headline {
                    font-size: 16px;
                    line-height: 1.4em;
                    font-weight: bold;
                    margin-bottom: 12px;
                    text-transform: uppercase;
                }

                .small-text {
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 1.4em;
                }
            }

            .buttons {
                width: 100%;
                margin-top: 60px;
                display: flex;
                flex-wrap: wrap;
                @include make-row();
                margin-left: 0;
                margin-right: 0;
                text-align: center;

                .btn-fix {
                    text-align: center;

                    > div {
                        width: 90%;
                        display: inline-block;

                        .btn {
                            padding: 10px 40px;
                            font-size: 18px;
                        }
                    }
                }

                &.btns-2 a {
                    display: table-cell;
                    vertical-align: middle;

                    @include media-breakpoint-down(lg) {
                        display: inline-block;
                    }
                }

                @include media-breakpoint-down(sm) {
                    margin-top: 0;

                    div {
                        a {
                            font-size: 16px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                    }
                }

                div {
                    flex: 1 1;
                    @include make-col-ready();
                    padding-left: 0;
                    padding-right: 0;

                    a {
                        margin: 0;
                        min-width: unset;
                        max-width: 396px;
                    }
                }

                &.btns-1 div {
                    text-align: center;

                    a {
                        min-width: 396px;
                        max-width: 396px;

                        @media (max-width: 425px) {
                            min-width: 300px;
                            max-width: 300px;
                        }
                    }
                }
            }

            .small-column-content + .buttons {
                margin-top: 42px;
            }
        }
    }

    @media (max-width: 1600px) {
        .cta-row {
            .col-text {
                .buttons {
                    display: flex;

                    a {
                        width: 90%;
                    }
                }

                .small-column-content .col {
                    margin-right: 0;
                }
            }
        }
    }

    @media (max-width: 1350px) {
        .cta-row {
            .col-text {
                .buttons.btns-2 {
                    display: block;

                    div {
                        margin-bottom: 0;
                    }

                    a {
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .cta-row {
            .col-text {
                .buttons {
                    display: block;

                    a {
                        width: 100%;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .cta-row .col-image {
            margin-bottom: 30px;
        }

        .inner-content.image-left .cta-row, .inner-content.image-right .cta-row {
            .col-text {
                .inner {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .cta-row .col-text .buttons.btns-1 div a {
            min-width: 0;
        }

        .cta-row .col-text .small-column-content {
            display: block;
        }

        .col-image {
            margin-bottom: 15px !important;
        }
    }

    .cta-row {
        @include media-breakpoint-down(md) {
            &.right-img {
                div {
                    &:nth-child(1) {
                        order: 2;
                    }

                    &:nth-child(2) {
                        order: 1;
                    }
                }
            }
        }
    }
}

.horizontalCTAWidget-section.bg-0e283f {
    .horizontal-cta-widget {
        .btn {
            background-color: $brand-anakiwa-blue;
            color: black;

            &:hover {
                color: $white;
                background-color: $brand-mariner-blue;
            }
        }
    }
}