﻿/// <reference path = "../../main.scss" />
// File Name: _search.scss
// Description: Styles for the FAQ Page
// ------------------------------------------------------------
body.faqPage {
    background-color: $brand-light-blue-bg;

    .loading-page-content {
        display: block;
        background-color: $white;
    }

    &.loaded {
        .loading-page-content {
            display: none;
        }
    }
}

.faqs-content {
    margin-bottom: 100px;

    #mobile-content {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .faqs-row {
        @include make-row();

        h2.category-header {
            color: $breadcrumb-gray;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 500;
        }

        .sidebar {
            @include media-breakpoint-up(xs) {
                @include make-col(12);
            }

            @include media-breakpoint-up(lg) {
                flex: 0 0 346px;
                max-width: 346px;
            }

            @include make-col-ready();
        }

        .sidebar .tab-headers, #mobile-content .tab-header, .sidebar .tab-headers-all {

            h2 {
                font-family: $headings-font-family;
                font-size: 16px;
                font-weight: 600;
                line-height: 1.35em;
                margin-bottom: 0;
            }

            a {
                display: block;
                padding: 10px 0px;
                font-family: $headings-font-family;
                font-weight: 600;
                line-height: 1.35em;
                display: flex;
                align-items: center;
                color: $black;
            }

            .section {
                &.active, &:hover {
                    a.tab-link {
                        color: $brand-blue-headings;
                        text-decoration: none;
                    }
                }
            }
        }

        .main-content {
            @include media-breakpoint-up(xs) {
                @include make-col(12);
            }

            @include media-breakpoint-up(md) {
                flex: 1 1;
                max-width: none;
                width: auto;
            }

            @include make-col-ready();

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                h1 {
                    font-size: 30px;
                    font-weight: 500;
                    padding: 0px;
                    line-height: 33px;
                    margin: 0;
                    text-transform: none;
                    color: $black;
                }
            }

            .left-side {
            }

            .right-side {
                position: relative;

                .search-combobox {
                    position: relative;
                }

                input[type=text] {
                    display: block;
                    width: 100%;
                    background-color: $white;
                    border: 0;
                    padding-right: 15px;
                    padding-left: 50px;
                    margin-right: 10px;
                    height: 40px;
                    font-size: 14px;
                    box-shadow: 0px 2px 6px rgba(0,0,0,0.1);

                    &::placeholder {
                        color: $brand-super-light-gray3;
                    }
                }

                button[type="submit"] {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 50px;
                    height: 100%;
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 0;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    vertical-align: middle;

                    span.fa {
                        font-size: 18px;
                        color: $brand-super-light-gray3;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }

                &.active {
                    input[type="submit"] {
                        /*margin-left: 0%;
                        transform: translateX(0%);
                        left: 24px;*/
                    }
                }
            }

            .search-content {
                overflow-y: auto;
            }

            .current-category {
                .all {
                    display: none;
                }
            }

            .current-category .other-headers {
                div {
                    display: none;
                }

                div.active {
                    display: block;
                }
            }


            &.all-mode .current-category {

                .all {
                    display: block;
                }
            }

            .tabs-content, .mobile-tabs-content {

                .section {
                    display: none;
                }

                .section.active {
                    display: block;
                }

                .accordion {
                    border: none;
                    margin-bottom: 10px;
                    background-color: $white;

                    .tab-header h3, .mobile-tab-header h3 {

                        button {
                            border: 1px solid transparent;
                            border-radius: 0;
                            display: block;
                            padding: 10px;
                            font-weight: bold;
                            width: 100%;
                            text-align: left;
                            position: relative;
                            white-space: inherit;
                            color: $black;
                            font-size: 16px;
                            font-weight: 500;

                            &:hover {
                                text-decoration: none;
                                cursor: pointer;
                            }

                            &:focus {
                                border: 1px dotted gray;
                            }

                            .icon {
                                width: 28px;
                                height: 28px;
                                display: block;
                                position: absolute;
                                right: 12px;
                                top: 50%;
                                transform: translateY(-50%);

                                &::after {
                                    font-family: FontAwesome;
                                    content: "\f106";
                                    font-size: 18px;
                                    line-height: 28px;
                                    color: $brand-somewhat-light-gray;
                                }
                            }

                            &.collapsed {
                                .icon {
                                    &::after {
                                        content: "\f107";
                                    }
                                }
                            }
                        }
                    }

                    .card-body {
                        border-top: none;
                        padding: 10px;
                        font-size: 14px;
                        line-height: 1.45em;
                        color: $brand-somewhat-light-gray;
                    }
                }
            }

            .mobile-tabs-content {
                padding: 0;

                > .inner-content {
                    padding: 24px;
                }
            }


            .autocomplete-results {
                background-color: $white;
                display: none;

                .inner-content {
                    padding: 10px 15px;
                    margin-bottom: 15px;

                    > div {
                        max-height: 220px;
                        overflow-y: auto;
                    }

                    .result {
                        display: block;
                        color: $black;
                        font-size: 14px;
                        font-weight: bold;
                        padding: 10px 0 10px 0;

                        .highlighted {
                            background-color: orange;
                        }
                    }
                }
            }

            form.search.autocomplete-open {
                /*.tabs-content {
                    display: none;
                }

                .most-popular-questions {
                    display: block;
                }
                    */
                .autocomplete-results {
                    display: block;
                }
            }

            form.popular-after {
                .search-content {
                    display: flex;
                    flex-direction: column;
                }
            }

            &.all-mode {
            }
        }
    }

    @include media-breakpoint-down(md) {
        .sidebar {
            display: none;
        }

        .current-category {
            display: none;
        }

        .faqs-row .main-content {
            .actual-content {
                display: none;
            }

            form {
                display: block;

                .header {
                    margin-bottom: 15px;
                    display: block;

                    .left-side {
                        margin-bottom: 15px;
                    }
                }
            }
        }


        #mobile-content {

            > .tab-section {
                > .tab-header {
                    position: relative;
                    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
                    background-color: $white;

                    a {
                        padding: 15px 20px;
                    }

                    &::after {
                        font-family: FontAwesome;
                        content: "\f107";
                        font-size: 28px;
                        line-height: 28px;
                        color: $brand-somewhat-light-gray;
                        position: absolute;
                        right: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                        pointer-events: none;
                        font-weight: normal;
                    }
                }

                &.active {
                    a.tab-link {
                        color: $black;
                        font-weight: bold;
                        text-decoration: none;
                    }
                }

                &.open {
                    > .tab-header::after {
                        content: "\f106";
                    }
                }

                .mobile-tabs-content {
                    height: 1px;
                    overflow-y: hidden;

                    .accordion .mobile-tab-header h3 button .icon {
                        right: 5px;

                        &::after {
                            font-weight: normal;
                        }
                    }

                    .accordion {
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }


    @include media-breakpoint-down(sm) {
        padding-top: 0;

        .faqs-row {
            .main-content .header {

                h1 {
                    font-size: 26px;
                    line-height: 1.2;
                    margin: 0;
                    padding: 0;
                }
            }

            .sidebar .tab-headers a {
                padding: 10px 24px;
            }

            #mobile-content {

                .tab-header {
                    position: relative;
                    z-index: 2;

                    a {
                        padding: 15px 20px;
                        font-size: 14px;


                        span.icon {
                            margin-right: 15px;
                            width: 23px;
                            height: 23px;
                            flex: 0 0 23px;
                            justify-content: flex-start;
                        }
                    }

                    &::after {
                        right: 20px;
                    }
                }

                .mobile-tabs-content {
                    z-index: 1;
                    position: relative;

                    > .inner-content {
                        padding: 20px;
                    }
                }

                .accordion {
                    .mobile-tab-header h3 button {
                        font-size: 14px;
                        padding: 10px 50px 10px 10px;
                    }

                    .card-body {
                        padding: 20px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}