﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

.testimonial-grid-editor {
    height: 100%;
    border: 1px solid $black;
    background-color: $white;

    .inner-content {
        padding: 78px 100px 52px 100px;

        blockquote {
            margin-bottom: 0;
        }

        .quote {
            &::before {
                content: "\f10d";
                font-family: FontAwesome;
                color: $brand-red;
                font-size: 42px;
                margin-left: -52px;
                position: absolute;
                margin-top: -8px;
            }

            display: block;
            color: $black;
            font-size: 18px;
            line-height: 1.8em;
        }


        footer {
            margin-top: 50px;

            &::before {
                content: "";
                display: none;
            }

            .author {
                color: $black;
                display: block;
                font-size: 24px;
                line-height: 1.4em;
                font-weight: 300;
            }

            .location {
                color: $black;
                display: block;
                font-size: 16px;
                line-height: 1.5em;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .inner-content {
            padding: 60px;

            .quote {
                font-size: 16px;

                &::before {
                    font-size: 32px;
                    margin-left: -45px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .inner-content {
            .quote {
                &::before {
                    font-size: 24px;
                    margin-left: -40px;
                }

                font-size: 16px;
            }

            footer {
                .author {
                    font-size: 20px;
                }

                .location {
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .inner-content {
            padding: 30px;
            padding-left: 40px;
            .quote{
                &::before 
                {
                    font-size: 24px;
                    margin-left: -30px;
                }
            }
            
        }
    }
}

/*Insert magic here */
section.testimonial-section {

    @include media-breakpoint-up(lg) {
        padding-bottom: 70px;

        .column.column-5:first-child {
            padding-right: 35px;
        }

        .column.column-7:last-child {
            padding-left: 35px;
        }

        .column.column-7:first-child {
            padding-right: 35px;
        }

        .column.column-5:last-child {
            padding-left: 35px;
        }

        .column.column-6:first-child {
            padding-right: 35px;
        }

        .column.column-6:last-child {
            padding-left: 35px;
        }
    }
    @include media-breakpoint-down(md){
        .column{
            margin-bottom: 30px;
        }

    }
}
/*Smaller padding for wide containers, as images are bound to 600px max width*/
section.testimonial-section.squareImage-section {
    div.container {
        &.max-width-wide {
            @media (min-width: 1670px) {
                .column.testimonial.column-7:first-child {
                    padding-right: 17px;
                }

                .column.testimonial.column-7:last-child {
                    padding-left: 17px;
                }
            }
        }
    }
}
/*Magic for square images used with testimonials*/
.testimonial-section.squareImage-section {
    .image-fit {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background-size: cover;
        background-position: center center;
    }
    .with-border {
        .image-fit {
        }
    }
    .squareImage .square-image {
        margin-bottom: 0;
        position: relative;
        height: 100%;
    }
}