﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

.multiColumnBlocks-section {
    > .container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        > .body {
            margin-left: 0;
            margin-right: 0;

            > .column {
                > .mobile-version.exists {
                    /* Undo the padding in the column only for this widget so image row is fluid on mobile */
                    @include media-breakpoint-down(md) {
                        margin-left: -15px;
                        margin-right: -15px;
                    }
                }
            }
        }
    }
}

.multicolumn-blocks-grid-editor {
    padding-top: 72px;
    padding-bottom: 72px;
    display: block;

    &.desktop-version.has-mobile {
        display: block;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &.mobile-version.exists {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .row-blocks {
        @include make-row();

        .multicolumn-block {
            margin-bottom: 30px;
            align-self: center;

            @include media-breakpoint-up(xs) {
                @include make-col(12);
            }

            @include media-breakpoint-up(lg) {
                @include make-col(4);
                margin-bottom: 0;
                margin-top: 0;
            }

            @include make-col-ready();

            .image-block {

                &.squareImage {
                    height: 100%;
                    position: relative;

                    .square-image {
                        margin: 0;

                        img {
                            width: 100%;
                            height: auto;
                        }

                        .image-fit {
                            position: absolute;
                            height: 100%;
                            width: auto;
                            left: 0;
                            right: 0;
                            top: 0;
                            background-size: cover;
                            background-position: center center;
                        }
                    }
                }
            }

            .text-block {
                text-align: center;
                padding-left: 30px;
                padding-right: 30px;

                .headline {
                    margin-bottom: 10px;
                }

                .subheadline {
                    font-size: 32px;
                    line-height: 1.25em;
                    font-weight: 300;
                    margin-bottom: 30px;
                }

                .text {
                    font-size: 18px;
                    line-height: 1.95em;
                }

                .buttons {
                    width: 100%;
                    margin-top: 40px;
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .row-blocks .multicolumn-block .text-block {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

/* Mobile Version */
.multicolumn-blocks-grid-editor.mobile-version {
    padding-top: 55px;
    padding-bottom: 80px;

    .headline {
        text-align: center;
        font-size: 38px;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;
    }

    .subheadline {
        font-size: 30px;
        line-height: 1.25em;
        font-weight: 300;
        margin-bottom: 20px;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
    }

    .image-row {
        display: flex;

        .image-square {
            display: inline-block;
            @include make-col(6);

            &.right-side {
                border-left: none;
            }

            &.left-side {
                border-right: none;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .text {
        font-size: 18px;
        padding: 15px;
        margin-top: 10px;
        margin-bottom: 15px;
        text-align: center;

        p {
            line-height: 1.4em;
        }
    }

    .buttons {
        div {
            padding-left: 15px;
            padding-right: 15px;
            text-align: center;

            .btn {
                padding: 10px 20px;
                min-width: 250px;
            }
        }
    }
}
