﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------
.inline-search-bar-container {
    display: flex;
    justify-content: center;

    .desktop-search-bar, .mobile-search-bar {
        display: flex;
        align-items: center;
        background-color: $white;
        max-width: 100%;
        box-shadow: 0px 2px 6px $brand-dark-gray-25;
        border-radius: 8px;

        .form-inline {
            max-width: 100%;
        }

        .dates, .date-fields {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
        }

        .date-start-span, .date-end-span {
            position: relative;
            display: flex;
            align-items: center;

            .fa {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $brand-very-light-gray;
                font-size: 20px;
                margin-left: 22px;
                pointer-events: none;
                transition: 0.3s color;
            }

            &:hover {
                .fa {
                    color: $brand-mid-gray;
                }
            }

            &.selected {
                .fa-calendar {
                    color: $brand-green;
                }
            }
        }

        input[type="text"] {
            padding: 24px 20px;
            min-height: 75px;
            border: 0;
            margin: 0;
            font-size: 20px;
            color: $brand-dark-gray;
            line-height: 1.3em;
            border-radius: 8px 0 0 8px;

            &::placeholder {
                color: $black;
            }

            &.text-location {
                padding-left: 60px;
                /*background-image: url("/dist/img/map-point.svg");*/
                background-size: 20px;
                background-position: 20px center;
                background-repeat: no-repeat;
                width: 450px;
                border-right: 2px solid $brand-super-light-gray6;
            }

            &:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba(77, 144, 254, 0.5);
                border-radius: 7px 0 0 7px;
            }

            &.datepicker {
                padding-left: 60px;
                width: 190px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .text-location-holder {
            position: relative;

            span.icon {
                position: absolute;
                font-size: 20px;
                margin-left: 20px;
                pointer-events: none;
                transition: 0.3s color;
                height: 30px;
                width: 20px;
                top: 50%;
                transform: translateY(-50%);

                svg {
                    width: 100%;

                    .a {
                        fill: $brand-very-light-gray;
                    }
                }
            }

            &.selected {
                span.icon svg .a {
                    fill: $brand-blue;
                }
            }
        }

        .location-state-holder {
            position: relative;

            .location-state {
                background-size: 20px;
                background-position: 20px center;
                background-repeat: no-repeat;
                height: 100%;
                -webkit-appearance: none;
                appearance: none;
                padding: 5px 35px 5px 20px;
                font-size: 20px;
                color: $brand-dark-gray;
                border: none;
                margin-left: 2px;
                margin-right: 2px;

                &::-ms-expand {
                    display: none;
                }

                &:focus {
                    outline: none;
                    box-shadow: 0 0 0 2px rgba(77, 144, 254, 0.5);
                }
            }

            .arrow {
                position: absolute;
                top: 50%;
                right: 0;
                display: inline-block;
                transform: translateY(-50%);
                margin-right: 15px;
                pointer-events: none;

                &::after {
                    display: inline-block;
                    font-family: "FontAwesome";
                    font-size: 14px;
                    content: "\f0d7";
                    vertical-align: middle;
                }
            }
        }

        .separator-arrow {
            height: 22px;
            width: 48px;
            padding-left: 10px;
            padding-right: 10px;
            margin-top: 2px;

            svg {
                width: 100%;
            }

            .a {
                fill: #d4d9dd;
                stroke: #d4d9dd;
            }
        }

        input[type="submit"], .btn-green, .btn-orange {
            padding: 20px 30px;
            min-height: 75px;
            font-size: 20px;
            min-width: 0;
            text-transform: none;
            font-weight: 600;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
        /*errors*/
        label.error {
            position: absolute;
            background-color: white;
            padding: 5px 15px;
            border-radius: 0px 0px 5px 5px;
            box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.28);
            border-top: 1px solid gainsboro;
            top: 100%;
            font-size: 1rem;
            line-height: 1.4em;
            text-align: center;
        }
    }
    /* Mobile Search styles */
    .mobile-search-bar {
        font-size: 16px;
        padding: 15px;
        display: none;
        width: 100%;
        border-radius: 0px;

        .form-inline {
            .text-location-holder {
                display: block;
                border: 1px solid $brand-very-light-gray;
                box-shadow: 0px 2px 5px $brand-very-light-gray;
                width: 100%;

                span.icon {
                    width: 16px;
                    height: 25px;
                    margin-left: 12px;
                }

                .text-location {
                    padding-left: 40px;
                    font-size: 1em;
                    width: 100%;
                    border-right: 0;
                }

                .error {
                    left: 0px;
                    z-index: 3;
                }
            }

            .location-state-holder {
                display: block;
                border: 1px solid $brand-very-light-gray;
                box-shadow: 0px 2px 5px $brand-very-light-gray;
                width: 100%;
                margin-top: 10px;

                .location-state {
                    font-size: 1em;
                    width: 100%;
                    min-height: 0;
                    padding: 10px 15px;
                    line-height: 1.3em;
                }
            }
        }

        .dates {
            margin-top: 13px;
            width: 100%;
        }

        .date-fields {
            width: 100%;
            justify-content: space-between;
            margin-bottom: 20px;
            border: 1px solid $brand-very-light-gray;
            box-shadow: 0px 2px 5px $brand-very-light-gray;

            > span {
            }

            .separator-arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 50%;
                margin-left: -12px;
                margin-top: 2px;
                z-index: 2;
                pointer-events: none;
                width: 25px;
                padding-left: 5px;
                padding-right: 5px;

                .a {
                    fill: $breadcrumb-gray;
                    stroke: $breadcrumb-gray;
                }
            }

            .date-start-span, .date-end-span {
                flex: 1 1 50%;
                max-width: 140px;

                .fa {
                    margin-left: 12px;
                }

                input[type="text"].datepicker {
                    width: 100%;
                    font-size: 1em;
                    padding-left: 40px;
                    max-width: 130px;
                }
            }
        }

        .mobile-search-dropdown {
            width: 100%;
            position: relative;
            display: none;

            &.start-date-open {
                display: block;
            }

            .date-actions {
                width: 100%;
                display: block;

                &::after {
                    content: " ";
                    clear: both;
                    display: block;
                }

                .close-datepicker {
                    float: left;
                    color: $breadcrumb-gray;
                    font-size: 26px;
                    font-weight: 600;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .clear-datepicker {
                    float: right;
                    color: $brand-green;
                    font-size: 14px;
                    margin-top: 8px;
                }
            }

            .mobile-date-both-datepicker-inline {
                /* Calendar style */
                .ui-datepicker-inline {
                    width: 100%;
                    padding: 0;
                    border-color: $brand-super-light-gray2;

                    .ui-datepicker-header {
                        padding: 10px 0;
                    }

                    .ui-datepicker-title {
                        font-size: 18px;
                    }

                    .ui-widget-header {
                        background-color: transparent;
                        border: 0;
                        border-bottom: 1px solid $brand-super-light-gray2;
                        margin: 0;
                    }

                    .ui-datepicker-prev, .ui-datepicker-next {
                        height: 100%;
                    }

                    .ui-datepicker-prev > span {
                        background-image: none;


                        &::before {
                            content: "\f104";
                            font-family: FontAwesome;
                            font-size: 18px;
                            font-weight: normal;
                            margin-top: -6px;
                            color: $black;
                            text-indent: 0;
                            display: block;
                            opacity: 0.7;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }

                    .ui-datepicker-next > span {
                        background-image: none;


                        &::before {
                            content: "\f105";
                            font-family: FontAwesome;
                            font-size: 18px;
                            font-weight: normal;
                            margin-top: -6px;
                            color: $black;
                            text-indent: 0;
                            display: block;
                            opacity: 0.7;
                        }
                    }

                    @media (max-width: 320px) {
                        .ui-datepicker-header {
                            padding: 0.2em 0;
                        }

                        .ui-datepicker-next > span, .ui-datepicker-prev > span {
                            margin-top: -3px;
                        }
                    }

                    td {
                        text-align: center;

                        a, span.ui-state-default {
                            height: 41px;
                            /*width: 38px;*/
                            text-align: center;
                            padding: 10px;
                            margin-left: auto;
                            margin-right: auto;

                            @media (max-width: 320px) {
                                height: 34px;
                                /*width: 25px;*/
                                padding: 6px;
                            }
                        }
                    }
                }
            }
        }

        .actions {
            width: 100%;

            input[type="submit"] {
                margin-top: 10px;
                display: block;
                width: 100%;
                padding: 10px 15px;
                font-size: 1em;
                letter-spacing: 0.05em;
                text-transform: none;
                font-weight: normal;
                min-height: 0;
                border-radius: 0;

                &.desktop {
                    display: none;
                }

                &.mobile {
                    display: block;
                }
            }
        }

        .text-location-span {
            position: relative;
        }

        input[type="text"] {
            padding: 10px 6px;
            font-size: 1em;
            min-height: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        display: block;

        .desktop-search-bar {
            display: none;
        }

        .mobile-search-bar {
            display: block;
        }
    }
}