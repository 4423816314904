﻿/// <reference path = "../../main.scss" />
// File Name: _nav-utility.scss
// Description: Utility navigation styles
// ------------------------------------------------------------
header {
    > .nav-utility {
        margin-top: 0px;
        height: 45px;
        display: flex;
        align-content: center;
        z-index: 2;

        .navbar {
            padding: 10px;
            padding-right: 0;
        }

        .navbar-nav {
            padding-right: 0;
            align-items: center;
        }

        .nav-utility {
            font-size: 13px;
            flex: 0 1 100%;

            ul li {
                padding: 0;
                vertical-align: middle;
                display: inline-block;

                > a {
                    color: $black;
                    font-weight: 400;
                    font-size: 16px;
                    padding: 0px 10px;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .contact-phone {
                    position: relative;
                    margin-left: 28px;
                    margin-right: 40px;

                    a {
                        font-size: 20px;
                        font-weight: 700;
                        text-align: center;
                        color: $brand-mariner-blue;
                    }
                }

                &.utility-btn a {
                    @include btn-green;
                    font-size: 14px;
                    padding: 4px 14px;
                    letter-spacing: 0.05em;
                    min-width: 82px;
                    text-align: center;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        @media (max-width: 1280px) {
            .nav-utility ul {
                li.recently-viewed {
                    padding-right: 15px;

                    a {
                        font-size: 14px;
                    }
                }

                li .contact-phone {
                    margin-left: 15px;
                    margin-right: 15px;

                    a {
                        font-size: 16px;
                    }
                }
            }
        }

        @media (max-width: 1158px) {
            .nav-utility ul {
                li.recently-viewed {
                    padding-right: 5px;

                    &::after {
                        top: 4px;
                    }
                }

                li .contact-phone {
                    margin-left: 5px;
                    margin-right: 15px;

                    a {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}