﻿/// <reference path = "../../main.scss" />
// File Name: _footer.scss
// Description: footer view styles
// ------------------------------------------------------------

.footer-main {
    padding: 0;
    background-color: $brand-footer-bg;
    color: $white;

    .footer-container {
        /*@include make-container();
        @include make-container-max-widths();*/
        padding-top: 64px;
        padding-bottom: 64px;
        padding-left: 32px;
        padding-right: 32px;

        .footer-title, h4.umbraco-forms-caption, .footer-form-title {
            /*font-family: $headings-font-family;*/
            font-size: 20px;
            font-weight: 600;
            color: $brand-footer-text-color;
            margin-bottom: 15px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 5px;
            }
        }

        .footer-logo {
            width: 181px;
            margin-bottom: 26px;
        }

        a {
            color: $white;
            transition: 0.3s all;

            &:hover {
                color: $brand-footer-text-color;
            }
        }

        .footer-row-main {
            display: flex;
            justify-content: space-between;
            font-weight: 300;

            @include media-breakpoint-down(sm) {
                & {
                    padding: 15px;
                }
            }

            .column {
                &.footer-navigation-row {
                    display: flex;
                    flex-wrap: wrap;
                }

                ul.footer-main-navigation {
                    color: $white;
                    list-style: none;
                    margin-left: 15px;
                    margin-right: 15px;
                    padding-left: 0;

                    .foot-main-navigation-item {
                        a, &.header {
                            display: inline-block;
                            font-size: 18px;
                            color: $white;
                            font-weight: 300;
                            line-height: 36px;
                            line-height: 1.5em;
                            margin-bottom: 0.5em;
                        }

                        &.header {
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .column-first {
                width: 230px;

                .contact-address a {
                    border-bottom: 1px dotted $brand-somewhat-light-gray;

                    &:focus, &:hover, &:active {
                        text-decoration: none;
                        color: $white;
                        border-bottom: 1px solid $white;
                    }
                }
            }

            .column-last {
                width: 350px;

                .stay-informed-content {
                    .stay-informed-header {
                        font-size: 24px;
                        color: $white;
                        line-height: 24px;
                        font-weight: 600;

                        .blue-color {
                            color: $brand-blue-lighter-3;
                        }

                        .red-color {
                            color: $brand-red;
                        }
                    }

                    .stay-informed-sub {
                        font-size: 16px;
                        color: $white;
                        font-weight: 300;
                        margin-bottom: 18px;
                        display: block;
                    }
                }

                .stay-informed-form {
                    display: flex;
                    align-items: center;
                    //background-color: $white;
                    border-radius: 8px 8px 8px 8px;
                    max-width: 100%;
                    padding-top: 15px;

                    .form-inline {
                        width: 100%;
                    }

                    input[type="email"] {
                        padding: 6px 10px 6px 10px;
                        border: 0;
                        margin: 0;
                        font-size: 20px;
                        color: $brand-dark-gray;
                        border-radius: 8px 0px 0px 8px;
                        width: 100%;
                        height: 42px;

                        &::placeholder {
                            color: $black;
                        }
                    }

                    .email-span {
                        width: 80%;
                    }

                    .umbraco-forms-form {
                        margin-top: -15px;
                        width: 100%;
                    }

                    .umbraco-forms-page {
                        margin-bottom: 0;

                        label {
                            color: $white;
                            top: 0;
                        }

                        .umbraco-forms-indicator {
                            color: $brand-blue-lighter-3;
                        }

                        .umbraco-forms-field-wrapper {
                            .field-validation-error {
                                color: $brand-blue-lighter-3;
                                top: 0;
                            }

                            .input-validation-error {
                                border-color: $brand-blue-lighter-3 !important;
                                top: 0;
                            }
                        }

                        input[type="text"]:focus, input[type="password"]:focus {
                            outline-color: transparent;
                            border-color: $brand-blue-lighter-3;
                        }

                        .umbraco-forms-navigation {
                            padding-top: 0;

                            input, button {
                                &[type="submit"] {
                                    min-height: 40px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                }
                            }
                        }
                    }
                }

                .btn-red {
                    padding: 6px 15px 6px 15px;
                    font-size: 18px;
                    min-width: unset;
                    border-radius: 0px 8px 8px 0px;
                    color: $white;
                    background-color: $brand-red;
                    height: 42px;
                    width: 20%;

                    &.desktop {
                        display: block;
                    }

                    &.mobile {
                        display: none;
                    }

                    &:hover {
                        background-color: $brand-red-btn-hover;
                    }
                }

                .stay-informed-buttons {
                    width: 100%;
                    display: flex;
                    padding-top: 100px;

                    .btn {
                        flex-grow: 1;
                        font-size: 14px;
                        color: $brand-blue-lighter-3;
                        border-color: white;
                        border-style: solid;
                        border-width: 2px;

                        &.first {
                            border-radius: 8px 0px 0px 8px;
                        }

                        &.second {
                            border-radius: 0px 8px 8px 0px;
                        }
                    }
                }

                @include media-breakpoint-down(sm) {
                    .stay-informed-buttons {
                        display: block;

                        .btn {
                            flex-grow: unset;
                            font-size: 20px;

                            &.first {
                                width: 100%;
                                border-radius: 8px 8px 0px 0px;
                            }

                            &.second {
                                width: 100%;
                                border-radius: 0px 0px 8px 8px;
                                position: relative;
                                bottom: 2px;
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-down(md) {
                //display: flex !important;
                .column-first {
                    @include make-col(12);
                    text-align: center;
                    margin-bottom: 75px;
                }

                .column-first, .column-last {
                    display: block !important;
                    width: 100% !important;
                    flex: none !important;
                }

                .column-last {
                    margin-top: 75px;
                }
            }
        }
        /* media rules */
        @media(max-width: 1368px) {
            .footer-row-main {
                flex-wrap: wrap;

                .column {
                    min-width: 50%;
                    flex: 0 0 50%;

                    ul.footer-main-navigation {
                        margin-left: 0;
                        margin-right: 0;
                        margin-top: 10px;
                    }

                    &.column-last {
                        min-width: 100%;
                        flex: 0 0 100%;

                        .stay-informed {
                            margin-top: 30px;
                            max-width: 468px;
                            margin-left: auto;
                            margin-right: auto;
                            min-width: 625px;
                            display: inline-block;

                            .stay-informed-buttons {
                                padding-top: 0px;
                            }
                        }
                    }
                }
            }

            .column-last .stay-informed-form .umbraco-forms-page label {
                margin-bottom: 0.5em;
            }
        }

        @include media-breakpoint-down(lg) {
            .footer-row-main {
                .column {
                    &.column-last {
                        .stay-informed {
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .footer-row-main {
                .column {
                    ul.footer-main-navigation {
                        margin-left: 0;
                        margin-right: 0;
                        margin-top: 30px;
                    }

                    &.column-last {
                        .stay-informed {
                            margin-top: 30px;
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }

                .footer-form {
                    margin-top: 0;
                    max-width: 100%;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 30px 15px;

            .footer-row-main {
                display: block;

                .column {
                    ul.footer-main-navigation {
                        text-align: center;
                        margin-bottom: 65px;

                        li {
                            a {
                                margin-bottom: 0.25em;
                            }
                        }

                        li:last-child a {
                            margin-bottom: 0;
                        }
                    }

                    &.column-last {
                        .stay-informed {
                            max-width: 100%;
                            min-width: 0;
                        }
                    }
                }
            }
        }
    }

    .footer-utility {
        background-color: $brand-footer-bg;
        color: $brand-footer-text-color-darker;
        padding: 50px 32px 42px 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;

        a {
            color: $brand-footer-text-color-darker;
            //text-decoration: underline;
            transition: 0.3s all;

            &:hover {
                color: $brand-footer-text-color;
                text-decoration: none;
            }
        }

        .disclaimer {
            font-size: 12px;
            color: $brand-footer-text-color-darker;
            margin-right: 60px;
        }

        .footer-nav {

            .footer-nav-wrapper {
                &.desktop {
                    display: block;

                    @include media-breakpoint-down(sm) {
                        & {
                            display: none;
                        }
                    }
                }

                &.mobile {
                    display: none;

                    @include media-breakpoint-down(sm) {
                        & {
                            display: block;
                        }
                    }

                    .footer-end-nav-mobile {
                        display: flex;
                        padding-top: 20px;

                        .footer-nav-col {
                            display: inline-block;
                            width: 50%;
                            text-align: left;

                            &:first-child {
                                text-align: right;
                            }

                            .inner-col {
                                display: inline-block;
                                width: 80%;
                                text-align: center;
                            }

                            a {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            ul {
                margin: 0;
                padding: 0;
                display: flex;

                li {
                    font-size: 12px;
                    list-style-type: none;
                    margin-right: 60px;

                    a {
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            padding: 0 30px;
            text-align: center;
            display: block;
            margin-top: 35px;

            .disclaimer {
                margin-right: 0;

                &::after {
                    content: "";
                    border: 0;
                    display: none;
                }
            }

            .separator {
                border-left: 1px solid $brand-footer-text-color-darker;
                height: 3em;
                width: 1px;
                margin-left: 5px;
                padding-left: 5px;
            }

            .footer-nav {
                padding-bottom: 25px;

                ul {
                    display: flex;
                    margin-top: 1em;
                    margin-bottom: 1em;

                    li {
                        margin-right: 0;
                        display: inline-block;

                        &:first-child {
                            text-align: right;
                            padding-right: 25px;
                        }

                        &:last-child {
                            text-align: left;
                            padding-left: 25px;
                        }

                        &::after {
                            content: "";
                            border: 0;
                            margin: 0;
                        }
                    }
                }
            }

            .footer-nav .footer-nav-wrapper.mobile .footer-end-nav-mobile {
                display: block;

                .footer-nav-col .inner-col {
                    width: 100%;
                }
            }
        }
    }

    .social-media {
        display: flex;
        background-color: $brand-footer-utility-bg;
        justify-content: center;

        &.mobile {
            display: none;
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;

            li {
                font-size: 22px;
                list-style-type: none;
                margin-left: 1em;
            }
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
            padding-top: 10px;

            &.mobile {
                display: flex;
            }

            &.desktop {
                display: none;
            }

            ul {
                display: block;
                width: 100%;

                li {
                    display: inline-block;
                    margin-left: 1.5em;

                    &:last-child {
                        padding-right: 1.5em;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        /*a {
            color: $brand-footer-text-color-darker;
            transition: 0.3s all;

            &:hover {
                color: $brand-footer-text-color;
            }
        }*/
    }
}
