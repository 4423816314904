﻿/// <reference path = "../../main.scss" />
// File Name: _variables.scss
// Description: Project variables
// ------------------------------------------------------------

$font-family-sans-serif:    'Work Sans', Arial, sans-serif !default;
$headings-font-family:      'Work Sans', Arial, sans-serif !default;
$testimonial-font-family: 'Lato', Arial, sans-serif !default;
//$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
//$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
//$font-family-base:       $font-family-sans-serif !default;

$white: #FFFFFF;
$black: #000000;

$concrete: #F3F3F3;
$alto: #bdbdbd;
$medium-gray: #B7B7B7;
$light-translucent-black: rgba(0, 0, 0, .2);

$sample-site-blue: #1b32db;
$sample-site-green: #1bdb76;

$map-indicator-anim-color: #34738A;

//new blue colors 5/11/2020
$brand-mariner-blue: #2f80d2;
$brand-deep-koamaru: #12317b;
$brand-mercury-gray: #e8e8e8;
$brand-wild-sand-gray: #f5f5f5;
$brand-malibu-blue: #80bfff;
$brand-anakiwa-blue: #74E0FF;
$brand-seagull-blue: #90d9ee;
$brand-black-pearl: #031026;
$brand-gray: #7f7f7f;

/*GRG*/
$brand-orange: #EE7103;
$banner-overlay: rgba(0,0,0,0.65);

/*Search*/
$brand-blue-form-selected: #06A3BF; 
$brand-blue-form-selected-faded: rgba(10,182,216,0.10);
$brand-dark-gray-faded: rgba(23,26,33,0.45);
$brand-dark-gray-faded-on-lb: #526a7b;
$brand-dark-gray-25: rgba(23,26,33,0.25);
$shadow-color: #326488;
$brand-slight-blue-white: #F2F7FC;
$brand-heart-red: #FC5353;
$brand-very-light-gray: #D4D9DD;
$brand-super-light-gray: #e9ecee;
$brand-super-light-gray2: #EFF2F7;
$brand-super-light-gray3: #C1C8C9;
$brand-super-light-gray4: #F0F0F7;
$brand-super-light-gray5: #F4F4F4;
$brand-super-light-gray6: #e4e4e4;
$brand-somewhat-light-gray: #617073;
$brand-mid-gray: #8d8e92;
$brand-mid-gray-ada: #71768B;
$brand-dark-gray: #171A21;
$brand-dark-gray-50: #727780; /* non-ada: #8a8c8f*/
$brand-footer-text-color: #D4D9DD; /* */
$brand-footer-text-color-darker: #7C828C; /*non-ada: #73777c*/
$brand-footer-utility-bg: #13151B;

/* property page */
$brand-green-on-lb: #3E7D1F; /*ada friendly green*/
$brand-jquery-ui-blue: #0981A1; /*non-ada: #0AB6D8*/
$brand-jquery-ui-light-blue: #d1f5fb;

//red
$red-error-text: #721c24;
$red-ada: #da0000;


/* Express Corporate Housing */
$brand-blue: #0D71BA;
$brand-dark-chalk: #1574B6; /* old non-ada: #399be0*/
$brand-blue-lighter: #64C6E2;
$brand-blue-lighter-2: #6FCCDD;
$brand-blue-lighter-3: #90D9EE;
$brand-blue-lighter-4: #92D8ED;
$brand-blue-lighter-5: #DCEBEF;
$brand-blue-mint-tulip: #C3E9F3;
$brand-blue-darker-1: #18456D;
$brand-blue-darker-2: #0F5A75;
$brand-blue-darker-3: $brand-mariner-blue; /* non ada: #1988B0*/
$brand-blue-darker-4: #0e283f;
$brand-blue-darkest-blue: #0E1A24;

$brand-red: #D93831; /* ada: #FA3B2A;*/ /* on white bg, original non-ada: #FB4D46 */
$brand-red-btn-hover: #FF5F58;
$brand-red-on-gray-blue: #FA3B2A; /* ada: #D30A06; */ /* on gray / blue bg, original non-ada: #FB4D46*/
$brand-red-on-gray-blue-hover: #CF1B0D; /* original non-ada: #FB4D46*/
$brand-form-gray: #C9C9C9;

$brand-footer-bg: $brand-black-pearl;
$brand-dark-gray-50: #8a8c8f;
$brand-footer-text-color: #D4D9DD;
$brand-footer-text-color-darker: #E8E3DD;
$brand-footer-utility-bg: #13151B;


$brand-blue-headings: #2A74AC;
$brand-blue-darker: #0AA6C5;

$brand-blue-light-bg: #F2F7FC;
$brand-light-blue-bg: #F4F6F9;
$brand-pale-blue: #d5f5fc;
$brand-blue-other: #2A74AC;
$brand-blue-other-hover: #205a86;
$brand-blue-btn: #0AB6D8;
$brand-blue-btn-text: #235F8D;
$brand-blue-btn-hover: #0AA6C5;
$brand-blue-btn-active: #1FCCEF;

/* GOROVERGO*/

$brand-green-o: #79A448;
$brand-green: #3E8415;
$brand-green-opacity: rgba(101, 152, 41, 0.25);
$brand-green-darker: #659829;
$brand-green-nav-hover: #3E8415;
$brand-green-lighter: #7BA23F;


$brand-orange: #FA8334;

/*Search*/
$brand-blue-form-selected: #0AB6D8;
$brand-blue-form-selected-faded: rgba(10,182,216,0.10);
$brand-dark-gray-faded: rgba(23,26,33,0.45);
$brand-dark-gray-50: rgba(23,26,33,0.5);
$shadow-color: #326488;
$brand-slight-blue-white: #F2F7FC;
$brand-very-light-gray: #D4D9DD;
$brand-super-light-gray: #e9ecee;
$brand-super-light-gray2: #EFF2F7;
$brand-super-light-gray3: #C1C8C9;
$brand-super-light-gray4: #F0F0F7;
$brand-somewhat-light-gray: #617073;
$brand-mid-gray: #8d8e92;


//approved site theme colors so we can use this in sass loops with our approved color module
$approved-colors-map: ( blue: $sample-site-blue, green: $sample-site-green );




//red
$red-error-text: #721c24;
//Breadcrumbs
$breadcrumb-gray: #9AA4A5;
$facebook-blue: #3B5998;