﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------

.subpage {
    main {
        /* default subpage rte text*/
        font-size: 18px;
        line-height: 1.4em;

        h1, h2, h3, h4, h5, h6 {
            text-transform: none;
        }

        h1 {
        }

        h2 {
        }

        h3 {
        }

        h4 {
        }

        h5 {
        }

        h6 {
        }
        /*no subpage banner, remove main top margin*/
        .mobile-overlay + .breadcrumbs {
            margin-top: -17px;
        }

        &.has-banner .mobile-overlay + .breadcrumbs {
            margin-top: 0;
        }
        /*bg color encompass breadcrumbs*/
        .mobile-overlay + section.section-0, .breadcrumbs + section.section-0 {
            padding-top: 25px;

            &.has-bgcolor {
                margin-top: -43px;
                padding-top: 0px;

                .container-fluid-bg {
                    padding-top: 68px;
                }

                > .container {
                    padding-top: 68px;
                }
            }
        }

        .bg-0e283f, .bg-12317b {
            /*dark override*/
            p, ul li, div {
                color: $white;
            }

            h1, h2, h3, h4, h5, h6 {
                color: $white;
            }
        }
    }

    .container-inline {
        padding: 1.25rem;
    }

    .column.column-12.rte {
        padding-bottom: 35px;

        &.horizontalBreak {
            padding-bottom: 15px;
        }
    }


    main section.headline-section {
        h1 {
            font-size: 46px;
            margin-bottom: 36px; /*48px*/
            @include media-breakpoint-down(md) {
                line-height: 1.1;
                font-size: 42px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 38px;
            }
        }

        h1 + h2 {
            margin-top: -24px;
        }
    }

    @include media-breakpoint-down(md) {
        main {
            p, li {
                line-height: 1.65em;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        main {
            p, li {
                font-size: 16px;
                line-height: 1.5em;
            }

            h2 {
                font-size: 28px;
            }

            h1 {
                font-size: 38px;
            }

            .breadcrumbs li {
                font-size: 12px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        main {
        }
    }
}
