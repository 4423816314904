﻿///<reference path="../../main.scss" />
// File Name: _faqSection.scss
// Description: Styles for FAQ Section Grid Editor
// ------------------------------------------------------------

.faqSection {
    .faq-section {
        margin-top: 30px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            .faq-section-inner {
            }
        }

        .faq-img {
            width: 40%;
            padding-top: 10px;
            padding-bottom: 10px;

            img {
                width: 100%;
                height: auto;
            }

            &.align-right {
                float: right;
                padding-left: 30px;
            }

            &.align-left {
                float: left;
                padding-right: 30px;
            }

            &.mobile {
                display: none;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
                padding-top: 15px;
                padding-bottom: 15px;

                &.align-right,
                &.align-left {
                    float: none;
                    padding-left: 0;
                    padding-right: 0;
                }

                &.mobile {
                    display: block;
                }

                &.desktop {
                    display: none;
                }
            }
        }

        .faq-item {
            margin-bottom: 60px;

            .question {
                font-size: 20px;
                font-weight: 900;
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            .answer {
                font-size: 16px;
                line-height: 1.8em;
                padding-left: 25px;
                font-weight: 300;
                display: table-cell;

                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                    line-height: 1.5em;
                }
            }
        }

        .faq-item:last-child {
            margin-bottom: 0;
        }
    }
}