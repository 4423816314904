﻿///<reference path="../../main.scss" />
// File Name: _banner.scss
// Description: Styles for the Subpage Banner
// ------------------------------------------------------------

.widgetPicker {
    .flex-row {
        display: flex;
        flex-wrap: wrap;

        .column-6 {
            @include make-col(6);

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        .column-3 {
            @include make-col(3);

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        .column-4 {
            @include make-col(4);

            @include media-breakpoint-down(md) {
                @include make-col(12);
            }
        }

        .inner-content {
            padding: 15px;
        }
    }
}