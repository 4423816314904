﻿/// <reference path = "../../main.scss" />
// File Name: _layout.scss
// Description: Layout/Master view styles
// ------------------------------------------------------------
///<reference path="../../main.scss" />
// File Name: _error.scss
// Description: Styles Error Page
// ------------------------------------------------------------

.subpage.errorPage {
    background-color: $brand-super-light-gray4;

    .rte-section {
        text-align: center;

        p {
            color: $brand-dark-gray-50;
            font-size: 18px;
        }
    }

    h1, h1.giant-error {
        font-weight: 600;
        font-size: 280px;
        text-align: center;
        color: $brand-super-light-gray3;
        line-height: 1.2;
        margin-bottom: -40px;
    }

    h2 {
        color: $black;
    }

    .button-actions {
        margin-top: 60px;
        margin-bottom: 60px;

        a.btn {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    @include media-breakpoint-down(sm) {
        h1, h1.giant-error {
            font-size: 100px;
            margin-bottom: 0;
        }

        .button-actions {
            margin-top: 40px;

            a.btn {
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 15px;
                min-width: 180px;
            }
        }
    }
}