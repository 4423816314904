﻿/// <reference path = "../../main.scss" />
// File Name: _nav-utility.scss
// Description: Utility navigation styles
// ------------------------------------------------------------

.login-section, .create-section, .activate-section, .reset-section, .set-section {
    .login-box, .create-box, .activate-box, .reset-box, .set-box {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        max-width: 510px;
        text-align: center;
        margin-bottom: 200px;
        margin-top: 75px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;

        h1.title, h2.likeH1 {
            color: $brand-dark-gray;
            font-weight: 600;
            text-transform: none;
            font-size: 48px;
            margin-bottom: 5px;

            @include media-breakpoint-down(md) {
                font-size: 40px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 28px;
            }
        }

        .intro {
            margin-bottom: 40px;

            p {
                color: $brand-somewhat-light-gray;
                font-size: 14px;
                margin-bottom: 5px;
            }

            p.required-note {
                font-size: 12px;
                font-weight: 600;
            }
        }

        form {
            .show-hide-password {
                position: absolute;
                right: -40px;
                top: 30px;
                color: $brand-somewhat-light-gray;
                font-size: 13px;

                &.show {
                    .hide {
                        display: none;
                    }
                }

                &.hide {
                    .show {
                        display: none;
                    }
                }
            }

            .create-password-row {
                position: relative;
            }

            .field {
                text-align: left;
                margin-bottom: 10px;
                font-size: 16px;

                label {
                    font-size: 13px;
                    color: $brand-dark-gray-50;
                    display: block;
                    margin-bottom: 0;
                    font-weight: 500;
                }

                label.error {
                    font-size: 1rem;
                    color: red;
                    padding: 5px 0;
                }

                .valid > label.error {
                    padding: 0;
                }



                input[type="text"], input[type="password"] {
                    display: block;
                    width: 100%;
                }

                input[type="text"]:disabled {
                    background-color: transparent;
                    border: none;
                    border-bottom: 1px solid $brand-very-light-gray;
                    color: $brand-somewhat-light-gray;
                    font-size: 14px;
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                div.valid, div.input-wrapper {
                    position: relative;
                }

                div.valid::after {
                    content: "\f00c";
                    font-family: FontAwesome;
                    color: $brand-green;
                    display: inline-block;
                    position: absolute;
                    padding-left: 5px;
                    padding-right: 5px;
                    border-radius: 5px;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                    background-color: $white;
                }
            }

            .links {
                text-align: left;
                font-size: 13px;
            }





            .btn-green {
                &:focus, &:active {
                    box-shadow: 0 0 0 0.2rem $brand-green-opacity;
                }
            }



            ul.validation-hints {
            }
        }

        .or {
            margin-top: 30px;
            margin-bottom: 40px;
            color: $brand-super-light-gray3;
            font-size: 14px;
            font-family: $headings-font-family;

            .text {
                position: relative;
                background-color: $white;
                padding: 0 15px;
                display: inline-block;
                z-index: 2;
            }

            .line {
                display: block;
                height: 1px;
                width: 100%;
                border-top: 1px solid $brand-super-light-gray3;
                margin-top: -12px;
            }
        }

        .third-party-signin-actions {
            a.sign-up-facebook {
                background-color: $facebook-blue;
                color: $white;
                font-size: 14px;
                font-family: $headings-font-family;
                border-radius: 0px;
                vertical-align: middle;
                padding: 10px;
                min-width: 240px;
            }

            .fa {
                margin-right: 10px;
                font-size: 18px;
                margin-top: 2px;
                vertical-align: middle;
            }

            a.sign-up-google {
                background-color: $white;
                border: 1px solid $brand-footer-text-color;
                color: $black;
                font-weight: 600;
                font-size: 14px;
                font-family: $headings-font-family;
                border-radius: 0px;
                vertical-align: middle;
                padding: 10px;
                min-width: 240px;

                .google-icon {
                    margin-top: 0;
                    height: 21px;
                    width: 21px;
                    background-image: url("/dist/img/google-favicon.png");
                    background-size: contain;
                }

                &:hover {
                    background-color: $brand-dark-gray;
                    border-color: $brand-dark-gray;
                    color: $white;
                }
            }
        }

        .end-link-actions {
            font-size: 14px;
            font-weight: 600;
            margin-top: 30px;
        }

        .button-actions {
            margin-top: 30px;

            input[type="submit"], a.btn {
                font-size: 16px;
                padding: 10px 30px;
                min-width: 175px;

                &[disabled] {
                    background-color: $brand-mid-gray;
                }
            }


            &.left-align {
                text-align: left;
            }

            &.top {
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }

    .login-box {
        position: relative;
    }

    .create-box .step-box {
        display: none;

        a.back {
            font-size: 14px;
            padding: 5px 10px;
            margin: 10px;
            display: inline-block;

            span {
                padding-right: 5px;
            }

            margin-left: 0;
            margin-top: 0;
            margin-bottom: 15px;
        }


        &.step1 {
            display: block;
        }

        &.hide {
            display: none;
        }

        &.active {
            display: block;
            opacity: 1;
        }


        &.select-type {
            .form-area {
                @include make-row();
            }

            .field.account-type {

                @include media-breakpoint-up(xs) {
                    @include make-col(12);
                }

                @include media-breakpoint-up(lg) {
                    @include make-col(6);
                }

                @include make-col-ready();


                span.p {
                    color: $brand-somewhat-light-gray;
                    font-size: 14px;
                    display: block;
                    color: $breadcrumb-gray;
                    /*@include media-breakpoint-up(lg) {
                        padding: 0 15px;
                    }*/
                }

                a.type-choice {
                    display: block;
                    color: $black;
                    text-align: center;
                    padding: 15px;
                    height: 100%;
                    border: 2px solid $brand-super-light-gray2;
                    transition: margin 0.1s;

                    .title {
                        font-family: $headings-font-family;
                        font-weight: 700;
                        font-size: 20px;
                        display: block;
                        color: $breadcrumb-gray;
                        margin-bottom: 5px;
                    }

                    .icon {
                        background-color: $brand-very-light-gray;
                        height: 100px;
                        width: 100px;
                        border-radius: 50px;
                        display: inline-block;
                        margin-bottom: 10px;
                    }

                    &:hover, &.active {
                        border-color: $brand-blue-btn;
                        text-decoration: none;
                        background-color: $white;
                        box-shadow: 0 3px 8px $brand-very-light-gray;
                        margin-top: -1px;
                        margin-bottom: 1px;

                        .title {
                            color: $black;
                        }

                        span.p {
                            color: $brand-somewhat-light-gray;
                        }
                    }

                    &.individual .icon {
                        background-image: url("/dist/img/individual.svg");
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-color: transparent;
                    }

                    &.company .icon {
                        background-image: url("/dist/img/company.svg");
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-color: transparent;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .intro {
                    margin-bottom: 100px;

                    a {
                        font-weight: bold;
                        text-decoration: underline;
                    }
                }

                .button-actions {
                    margin-top: 60px;
                }

                .end-link-actions {
                    margin-top: 40px;
                }
            }
        }

        &.account-information {
            &.individual {
                .field.company-name {
                    display: none;
                }
            }

            .button-actions {
                a.back {
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                input[type="submit"] {
                    width: 100%;
                }
            }
        }
        /*&.step3 {
            &.individual {
                .field.company-name {
                    display: none;
                }
            }
        }*/
        &.step4 {
            .step-icon {
                img {
                    width: 118px;
                    margin-bottom: 34px;
                }
            }

            .intro {
                margin-top: 30px;
                margin-bottom: 30px;

                .enteredEmail {
                    font-weight: bold;
                    color: $brand-blue-other;
                }
            }

            .form-area {

                a.resend-email-btn {
                    color: $brand-green;

                    .icon {
                        width: 22px;
                        height: 24px;
                        margin-right: 10px;
                        display: inline-block;
                        background-image: url("/dist/img/icons/resend-icon@2x.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        vertical-align: middle;
                    }

                    &:hover {
                        text-decoration: none;

                        .text {
                            border-bottom: 1px solid $brand-green;
                        }
                    }

                    &:focus, &:active {
                        box-shadow: 0 0 0 0.2rem $brand-green-opacity;
                    }
                }

                .resend-notification {
                    opacity: 0;
                    transition: 0.3s opacity;

                    .alert {
                        display: inline-block;
                        margin-top: 15px;
                    }

                    &.show {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .create-box {
        position: relative;

        @include media-breakpoint-up(lg) {
            max-width: 700px;

            .deskBreak {
                display: block;
            }
        }
    }
    /*Activate styles*/
    .activate-box {
        max-width: 100%;


        .success {
            .step-icon {
                width: 64px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 40px;

                img {
                    width: 100%;
                }
            }
        }

        .failure {
            .button-actions {
                margin-top: 20px;
            }
        }

        .form {
            max-width: 510px;
            margin-left: auto;
            margin-right: auto;

            .resend-notification {
                opacity: 0;
                transition: 0.3s opacity;

                .alert {
                    display: inline-block;
                    margin-top: 15px;
                }

                &.show {
                    opacity: 1;
                }
            }
        }
    }
    /*Reset styles*/
    .reset-box {
        .alert-danger {
            p {
                color: $red-error-text;
                font-size: 14px;
            }

            margin-top: 20px;
            margin-bottom: 20px;
        }

        .form {
            .links {
                margin-top: 30px;
                text-align: center;

                a {
                    font-size: 14px;
                }
            }
        }

        .success {
            display: none;

            .loading-redirecting {
                display: none;
            }

            &.active {
                display: block;

                &.redirect-url {
                    .loading-redirecting {
                        display: block;
                    }
                }
            }

            .loading-redirecting {
                .loading-icon {
                    margin: 40px;
                }

                p {
                    font-weight: bold;
                }
            }

            &.non-redirect {
                .loading-redirecting {
                    display: none;
                }
            }

            .button-actions {
                a {
                    font-size: 16px;
                    padding: 10px 30px;
                    width: 100%;
                }

                .resend-notification {
                    margin-top: 30px;
                    opacity: 0;
                    transition: 0.3s opacity;

                    .alert {
                        display: inline-block;
                        margin-top: 15px;
                    }

                    &.show {
                        opacity: 1;
                    }
                }
            }
        }
    }
    /*Set styles*/
    .set-box {
        .alert-danger {
            p {
                color: $red-error-text;
                font-size: 14px;
            }

            margin-top: 20px;
            margin-bottom: 20px;
        }

        .form {
            .links {
                margin-top: 30px;
                text-align: center;

                a {
                    font-size: 14px;
                }
            }
        }

        .success {
            display: none;

            .loading-redirecting {
                display: none;
            }

            &.active {
                display: block;

                &.redirect-url {
                    .loading-redirecting {
                        display: block;
                    }
                }
            }

            .button-actions {
                a {
                    font-size: 16px;
                    padding: 10px 30px;
                    width: 100%;
                }

                .resend-notification {
                    margin-top: 30px;
                    opacity: 0;
                    transition: 0.3s opacity;

                    .alert {
                        display: inline-block;
                        margin-top: 15px;
                    }

                    &.show {
                        opacity: 1;
                    }
                }
            }

            .loading-redirecting {
                .loading-icon {
                    margin: 40px;
                }

                p {
                    font-weight: bold;
                }
            }

            &.non-redirect {
                .loading-redirecting {
                    display: none;
                }
            }
        }
    }

    &.loading {
        .loading {
            display: block;
        }
    }

    .loading {
        position: absolute;
        background-color: rgba(255,255,255, 0.85);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: none;

        .icon {
            background-image: url("/dist/img/loading.gif");
            width: 32px;
            height: 32px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: 50%;
            left: 50%;
            /* width: 100%; */
            transform: translateX(-50%) translateY(-50%);
        }
    }
}
#login-modal {
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 535px;
        }
    }

    .modal-content {
        .modal-header {
            .close {
                margin: 0;
                top: 0;
                right: 0;
                padding: 8px 10px;
            }
        }

        .modal-title {
            font-size: 28px;
            margin-top: 40px;
        }


        .modal-body {
            padding: 15px 40px;
            padding-top: 0;

            .links {
                text-align: left;
                font-size: 13px;
            }

            .button-actions{
                margin-top: 30px;
            } 
            .or {
                margin-top: 30px;
                margin-bottom: 40px;
                color: $brand-super-light-gray3;
                font-size: 14px;
                font-family: $headings-font-family;

                .text {
                    position: relative;
                    background-color: $white;
                    padding: 0 15px;
                    display: inline-block;
                    z-index: 2;
                }

                .line {
                    display: block;
                    height: 1px;
                    width: 100%;
                    border-top: 1px solid $brand-super-light-gray3;
                    margin-top: -12px;
                }
            }

            .btn-green {
                @include btn-green;
                font-family: $headings-font-family;
                font-size: 16px;
                text-transform: none;
                font-weight: normal;
                padding: 10px;
                width: 100%;

                &:focus, &:active {
                    box-shadow: 0 0 0 0.2rem $brand-green-opacity;
                }
            }

            .end-link-actions {
                font-size: 14px;
                font-weight: 600;
                margin-top: 30px;
            }

            .third-party-signin-actions {
                a.sign-up-facebook {
                    background-color: $facebook-blue;
                    color: $white;
                    font-size: 16px;
                    font-family: $headings-font-family;
                    border-radius: 0px;
                    vertical-align: middle;
                    padding: 10px;
                    width: 100%;
                    margin-bottom: 15px;
                }

                a.sign-up-google {
                    background-color: $white;
                    border: 1px solid $brand-footer-text-color;
                    color: $black;
                    font-weight: 600;
                    font-size: 16px;
                    font-family: $headings-font-family;
                    border-radius: 0px;
                    vertical-align: middle;
                    padding: 10px;
                    width: 100%;

                    &:hover {
                        background-color: $brand-dark-gray;
                        border-color: $brand-dark-gray;
                        color: $white;
                    }
                }

                .fa {
                    margin-right: 10px;
                    font-size: 18px;
                    margin-top: 2px;
                    vertical-align: middle;
                }

                .google-icon {
                    margin-top: 0;
                    height: 21px;
                    width: 21px;
                    background-image: url("/dist/img/google-favicon.png");
                    background-size: contain;
                }
            }
        }

        .teaser-section {
            border-top: 1px solid $brand-super-light-gray2;
            margin-top: 20px;
            padding-top: 20px;

            .property-teaser {
                text-align: left;
                display: flex;

                .image {
                    width: 110px;
                    height: 110px;
                    flex: 0 0 110px;
                    position: relative;
                    margin-right: 10px;

                    .img {
                        width: 100%;
                        height: 100%;
                        padding-right: 10px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-size: cover;
                        background-position: center center;

                        &.no-image {
                            background-image: url("/dist/img/no-image-icon.png");
                        }
                    }
                }

                .text-data {
                    .name {
                        margin-top: 15px;
                        font-size: 20px;
                        color: $brand-green;
                        font-family: $headings-font-family;
                        font-weight: 600;
                    }

                    .units-available {
                        font-weight: 600;
                    }


                    .address {
                        display: flex;

                        .address-value {
                            display: block;
                            padding-left: 0px;
                        }

                        .map-icon {
                            width: 18px;
                            height: 22px;
                            display: inline-block;
                            vertical-align: middle;
                            flex: 0 0 18px;

                            svg {
                                width: 100%;
                                height: 100%;
                                overflow: visible;
                                transform: scale(0.75);

                                .map-point-reuse {
                                    fill: transparent;
                                    stroke: #000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}