﻿body {
    color: $brand-dark-gray;
    .no-break {
        white-space: nowrap;
    }
}
a {
    color: $brand-blue-other;
}
main, body.subpage main, .content-menu-column {
    h1, h2, h3, h4, h5, h6 {
        font-family: $headings-font-family;
        color: $black;
        font-weight: 800;

        @include media-breakpoint-down(sm) {
            line-height: 1;
        }
    }

    h1, h2.h1-style {
        font-size: 46px;
    }

    .h2-style-black {
        font-size: 46px;
        text-transform: none;
        color: $black;
    }

    h2 {
        font-size: 46px;
    }

    h3 {
        font-size: 34px;
    }

    h4 {
        font-size: 26px;
    }

    h5 {
    }

    h6 {
    }
    /* headline grid editor always styles*/
    .headline-section {

        h1, h2, h3, h4, h5, h6 {
            &.light-medium {
                font-size: 32px;
                font-weight: 300;
                margin-bottom: 45px;
            }

            &.large {
                font-size: 46px;

                @include media-breakpoint-down(sm) {
                    font-size: 38px;
                }
            }

            &.medium {
                font-size: 34px;
            }
        }

        h1 + h2 {
            margin-top: -22px;
        }
    }

    @include media-breakpoint-down(md) {
        h1, h2.h1-style, h2 {
            font-size: 38px;
        }
    }

    @include media-breakpoint-down(sm) {
        h1, h2.h1-style, h2 {
            font-size: 38px;
        }

        h1.h2-style-black {
            font-size: 38px;
        }

        h1, h2.h1-style, h2, h3, h4, h5, h6 {
            text-transform: none;
        }
    }

    .header-white {
        color: $white !important;
    }

    .mariner-blue-color, .dark-blue-color {
        color: $brand-mariner-blue !important;
    }

    p {
        font-family: $headings-font-family;
        color: $black;
    }

    section ul {

        li {
            font-weight: 400;
            list-style-type: none;
            line-height: 42px;
            padding-left: 50px;
            padding-right: 30px;

            &::before {
                font-family: FontAwesome;
                content: "\f00c";
                height: 20px;
                width: 20px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;
                color: $brand-blue-lighter;
                transform: translateY(-50%);
                margin-left: -35px;
            }
        }

        &.listAs2Columns {
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding-left: 0px;

            > li {
                width: 50%;
                float: left;

                @include media-breakpoint-down(lg) {
                    width: 50%;
                }

                @include media-breakpoint-down(xs) {
                    width: 100%;
                }
            }

            &::after {
                content: " ";
                display: block;
                clear: both;
            }
        }
    }

    .LargeText {
        font-size: 18px;

        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }

    p.MediumText {
        font-size: 18px;
        line-height: 1.7em;
        margin-bottom: 1.75em;
    }
}

.blue-color, .malibu-blue-color {
    color: $brand-malibu-blue !important;
}

.seagull-blue-color {
    color: $brand-seagull-blue !important;
}

.red-color {
    color: $brand-red;
}

.bg-f3f3f3, .bg-0e283f {
    .red-color {
        color: $brand-red-on-gray-blue;
    }
}