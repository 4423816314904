﻿///<reference path="../../main.scss" />
// File Name: _blog-grid-editor.scss
// Description: Blog Grid Editor
// ------------------------------------------------------------

.card.blog-widget {
    border: 0;

    .items {
        display: flex;

        .column {
            flex: 1 1 100%;
        }

        .post {
            padding: 15px 30px;

            .inner-content {
                box-shadow: 0 0 20px rgba(23, 26, 33, 0.2);


                .post-image {
                    overflow: hidden;

                    img {
                        width: 100%;
                        transition: 0.3s transform;
                        transform: translate3d(0,0,0);

                        &.mobile {
                            display: none;
                        }
                    }
                }

                .text {
                    padding: 15px;

                    .post-title {
                        color: $black;

                        a {
                            color: $black;
                        }
                    }

                    .post-summary {
                        font-size: 18px;
                        /*line-height: 1.45;*/
                    }

                    .post-read-more a {
                        color: $brand-blue-headings;
                        display: inline-block;
                        font-weight: bold;
                        font-size: 20px;
                        margin-top: 15px;

                        .icon-arrow {
                            width: 22px;
                            display: inline-block;
                            margin-left: 15px;
                            vertical-align: middle;

                            svg {
                                width: 100%;
                                margin-top: 3px;

                                .a {
                                    stroke: $brand-blue-headings;
                                    fill: $brand-blue-headings;
                                }
                            }
                        }

                        &:hover {
                            text-decoration: none;
                            color: $brand-blue-btn-text;
                            /*border-bottom: 1px dotted $brand-blue-btn;*/
                            .icon-arrow {
                                svg .a {
                                    stroke: $brand-blue-btn-text;
                                    fill: $brand-blue-btn-text;
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .post-image img {
                        transform: scale(1.08);
                    }
                }
            }
        }

        &.count-3 {
            margin-left: -30px;
            margin-right: -30px;

            .column.item-2 {
                .post {
                }
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .items .column .post {
            padding: 15px;

            .inner-content {
                .text {
                    .post-title {
                        font-size: 22px;
                        margin-bottom: 0.25em;
                    }

                    .post-summary {
                        font-size: 15px;
                    }

                    .post-read-more a {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .items .column .post {
            padding: 10px;
        }
    }

    @include media-breakpoint-down(sm) {

        .items {
            display: block;

            &.count-3 {
                margin-left: 4px;
                margin-right: 4px;
            }

            margin-left: 4px;
            margin-right: 4px;

            .slick-list {
                overflow: visible;
            }

            .column .post {
                padding: 6px;

                .inner-content {
                    .post-image {
                        img.desktop {
                            display: none;
                        }

                        img.mobile {
                            display: block;
                        }
                    }

                    .text {
                        .post-title {
                            font-size: 20px;
                            margin-bottom: 0.25em;
                        }

                        .post-summary {
                            font-size: 14px;
                        }

                        .post-read-more a {
                            display: block;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 450px) {
        .items .post .inner-content .text .post-read-more a {
            .icon-arrow {
                float: right;
            }
        }
    }
}
.card.blog-widget +.button-wrapper{
    padding-top: 35px;
}

.blogWidget-section {
    overflow-x: hidden;
}
