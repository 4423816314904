﻿/// <reference path = "../../main.scss" />
// File Name: _image-gallery.scss
// Description: Styles for the Image Gallery Grid Editor
// ------------------------------------------------------------

[id^="modal_"].modal.in {
    .modal-dialog {
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        top: 45%;
    }

    .carousel-indicators {
        li {
            margin-bottom: 1px;

            + li {
                margin-left: 3px;
            }

            &.active {
                height: 10px;
                width: 10px;
            }
        }
    }

    .carousel-item {
        img {
            width: 100%;
            @media (max-width: 400px){
                //width: 75%;
            }
        }
    }
}

.carousel-wrap {
    .gallery-thumbnails {
        padding: 0 0 1rem;

        .column {
            padding: 8px 15px;
            @include make-col-ready();
            @include make-col(6);
            text-align: center;

            @media (max-width: 400px) {
                -ms-flex: 0 0 100%;
                -webkit-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
                text-align: center;
            }

            @include media-breakpoint-up(sm) {
                @include make-col(4);
            }

            @include media-breakpoint-up(md) {
                @include make-col(3);
            }
        }

        img {
            cursor: pointer;
        }
    }
}
