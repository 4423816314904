﻿///<reference path="../../main.scss" />
// File Name: _blog.scss
// Description: Styles for the Blog
// ------------------------------------------------------------

//Specific Sidebar styles for blog pages
.ArticulateArchive, .ArticulateTags, .ArticulateRichText, .ArticulateMarkdown, .ArticulateSearch {
    .breadcrumbs {
        > .container > .row {
            margin-left: -15px;
            margin-right: -15px;
        }

        ul {
            padding-left: 0px;
        }
        /* .container .row {
            position: absolute;
        }*/
    }

    main {
        padding-top: 0px;
    }

    .sidebar {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(4);
        }

        background-color: $brand-light-blue-bg;

        h4 {
            color: $black;
            font-size: 20px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 0;
        }

        .sidenav {
            //border: 1px solid $mediumgray;
            padding: 20px;

            .widget {
                padding-bottom: 20px;
            }
        }

        .widget {
            padding-top: 50px;
            padding-bottom: 50px;

            .content {

                ul {
                    margin-bottom: 0;
                    padding-left: 18px;

                    li {
                        font-size: 16px;
                    }
                }

                &::before {
                    content: " ";
                    border-top: 1px solid $brand-very-light-gray;
                    width: 60%;
                    height: 1px;
                    display: block;
                    margin-top: 15px;
                    padding-top: 25px;
                }
            }

            &.newsletter {
                padding-top: 40px;

                .newsletter fieldset .newsletter {
                    display: flex;
                    align-items: stretch;

                    input {
                        flex: 46px 1 0;
                    }

                    button {
                        width: 125px;
                        min-width: 0;
                        font-size: 16px;
                        padding: 10px 5px;
                    }

                    @include media-breakpoint-down(lg) {
                        button {
                            width: 90px;
                            flex: 90px 0 0;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        display: block;

                        input {
                            width: 100%;
                            display: block;
                        }

                        button {
                            width: 100%;
                            display: block;
                            padding: 5px 5px;
                        }
                    }
                }
            }

            &.extra-content .container {
                padding-left: 0;
                padding-right: 0;

                .content {
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            &.search-box .searchBar {
                .search-bar {
                    display: flex;

                    input {
                        width: 85%;
                        height: 46px;
                    }

                    button {
                        height: 46px;
                        width: 15%;
                        background-color: $brand-blue-btn;
                        color: $white;
                        border-radius: 0;

                        &:hover {
                            background-color: $brand-blue-btn-hover;
                        }
                    }

                    @include media-breakpoint-down(lg) {
                        align-items: stretch;

                        input {
                            flex: 1 1 0;
                        }

                        button {
                            width: 40px;
                        }
                    }
                }

                .col-s-9 {
                    @include media-breakpoint-up(xs) {
                        @include make-col(9);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(9);
                    }

                    @include make-col-ready();
                }

                .col-s-3 {
                    @include media-breakpoint-up(xs) {
                        @include make-col(3);
                    }

                    @include media-breakpoint-up(lg) {
                        @include make-col(3);
                    }

                    @include make-col-ready();
                }

                input {
                    width: 100%;
                }

                .btn {
                    min-width: 0;
                    width: 100%;
                }
            }

            &.recentposts {

                ul li {
                    margin-bottom: 16px;

                    a {
                        color: $black;
                        font-weight: bold;

                        &:hover {
                            color: $brand-blue-other;
                        }
                    }

                    .time {
                        font-size: 13px;
                    }
                }
            }

            &.categorylist {
                ul {
                    padding-left: 0;

                    li {
                        list-style-type: none;
                        margin-bottom: 8px;


                        &::before {
                            font-family: "FontAwesome";
                            content: "\f105";
                            font-size: 16px;
                            margin-right: 10px;
                            color: $brand-super-light-gray3;
                        }
                    }
                }
            }
        }
    }

    .content-wrapper {
        @include make-col-ready();
        @include make-col(12);

        @include media-breakpoint-up(md) {
            @include make-col(8);
        }
    }
    /*detail page*/
    &.ArticulateRichText, &.ArticulateMarkdown {
        img.img-fluid.banner-top {
            margin-bottom: 15px;
        }

        h1 {
            font-size: 28px;
            color: $black;
            text-transform: none;
            margin-bottom: 0;
        }

        .date {
            font-size: 14px;
        }

        .postcontent {
            font-size: 18px;

            p {
                margin-bottom: 2rem;
            }
        }
    }
}

//Blog Index Styles
.ArticulateArchive, .ArticulateTags, .ArticulateSearch {

    .content-wrapper {
        .title h1 {
            margin-bottom: -5px;
        }

        .subtitle {
            font-size: 28px;
            color: $brand-dark-gray-50;
            font-family: $headings-font-family;
            font-weight: 600;
        }

        > .row {
            margin-right: 15px;
        }
    }



    .filter-wrapper {
        padding-bottom: 30px;

        .filter {
            margin-bottom: 0;
        }
    }

    .pagination-wrapper {
        text-align: center;
        -ms-flex: 0 0 100%;
        -webkit-flex: 0 0 100%;
        flex: 0 0 100%;
        justify-content: center;
        margin-bottom: 30px;

        .pagination {
            justify-content: center;

            .page-item .page-link {
                border-color: transparent;
                color: $black;
                font-weight: bold;
            }

            .page-item.active .page-link {
                background-color: $brand-blue-btn;
                border-color: $brand-blue-btn;
                color: $white;
            }

            .page-item .fa {
                font-size: 20px;
            }
        }
    }

    .posts {
        padding-top: 35px;

        .post-content {
            &:first-child {
            }

            display: flex;
            -ms-flex-direction: row;
            -webkit-flex-direction: row;
            flex-direction: row;

            @include make-col-ready();
            @include make-col(12);
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid rgba(0,0,0,0.2);

            .summary {
                padding-top: 30px;
                padding-bottom: 30px;

                p {
                    margin-bottom: 0;
                }
            }

            .author, .categories, .time {
                margin-bottom: 0;

                p {
                    margin-bottom: inherit;
                }
            }

            .img-wrapper {
                @include make-col-ready();
                @include make-col(6);
                padding-left: 0;

                + .content-wrapper {
                    @include make-col(6);
                }
            }

            .content-wrapper {
                @include make-col-ready();
                @include make-col(12);

                h3.third-level-heading {
                    font-size: 28px;
                    margin-bottom: 0;

                    a {
                        color: $black;
                    }
                }

                .time {
                    font-size: 14px;
                    font-weight: 500;
                }

                .summary {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27px;
                }

                .post-read-more {
                    a {
                        color: $brand-blue-other;
                        display: inline-block;
                        font-weight: bold;
                        font-size: 20px;
                        margin-top: 15px;

                        .icon-arrow {
                            width: 22px;
                            display: inline-block;
                            margin-left: 15px;

                            svg {
                                width: 100%;


                                .a {
                                    stroke: $brand-blue-other;
                                    fill: $brand-blue-other;
                                }
                            }
                        }

                        &:hover {
                            text-decoration: none;
                            color: $brand-blue-btn-text;
                            /*border-bottom: 1px dotted $brand-blue-btn;*/
                            .icon-arrow {
                                svg .a {
                                    stroke: $brand-blue-btn-text;
                                    fill: $brand-blue-btn-text;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        .content-wrapper > .row {
            margin-right: -15px;
        }

        .posts .post-content {
            display: block;
            padding-left: 15px;
            padding-right: 15px;

            & > .img-wrapper{
                img{
                    width: 100%;
                }
            } 
            
            & > .img-wrapper, & > .img-wrapper + .content-wrapper {
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
            }

            & > .img-wrapper + .content-wrapper {
                padding: 0;
                padding-top: 15px;
            }
        }
    }
}
