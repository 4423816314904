﻿/// <reference path = "../../main.scss" />
// File Name: _nav-utility.scss
// Description: infowindow styles
// ------------------------------------------------------------
#google_map {

    .infowindow-content {
        display: flex;
        /*margin-top: 10px;*/
        .no-image {
            width: 10px;
            height: 10px;
        }

        .image-col {
            width: 110px;
            flex: 0 0 110px;
            min-height: 80px;
            height: 100%;
            margin-right: 10px;
            /*margin-left: -10px;
                        margin-top: -10px;*/
            img {
                width: 100%;
            }

            .img-cell-link {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            .img {
                width: 110px;
                height: calc(100%);
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                background-color: lightgray;
            }
        }

        .content-col {
            line-height: 1.4em;

            .units {
                color: $brand-dark-gray;
                font-size: 13px;
                font-weight: 600;
                padding-bottom: 15px;
                min-height: 15px;
            }


            a {
                color: $black;

                &:hover {
                    text-decoration: none;
                }
            }

            .name {
                font-family: $headings-font-family;
                display: block;
                color: $brand-green;
                font-size: 20px;
                line-height: 1.2em;
                font-weight: bold;
                margin-bottom: 0px;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            .address {
                display: block;
                font-family: $font-family-sans-serif;
                font-size: 14px;
                vertical-align: middle;
                position: relative;
                text-decoration: none;
                color: $brand-green;
                margin-top: 2px;

                .map-icon {
                    width: 16px;
                    height: 22px;
                    display: inline-block;
                    vertical-align: top;
                    margin-top: -2px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    overflow: visible;

                    svg {
                        width: 100%;
                        height: 100%;
                        padding-left: 1px;
                        padding-top: 1px;
                        transform: scale(0.75);
                        overflow: visible;

                        .map-point-reuse {
                            fill: transparent;
                            stroke: $black;
                        }
                    }
                }

                .address-value {
                    margin-left: 20px;
                    display: inline-block;
                    vertical-align: top;
                }
            }
        }

        @media (min-width: 991px) and (max-width: 1230px) {
            .content-col {
                a {
                    .name {
                        font-size: 16px;
                        padding-bottom: 5px;
                    }

                    .address .address-value {
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .content-col .name {
                font-size: 16px;
            }
        }

        @media (max-width: 480px) {
            margin-top: 0;

            .image-col {
                margin-right: 0px;
                width: 80px;
                flex: 0 0 80px;

                .img {
                    width: 80px;
                }
            }

            .content-col {
                .name {
                    margin-bottom: 5px;
                    line-height: 18px;
                }

                .units {
                    margin-bottom: 3px;
                }

                .address .address-value {
                    line-height: 16px;
                    font-size: 13px;
                    margin-bottom: 5px;
                }
            }
        }
    }
}