﻿///<reference path="../../main.scss" />
// File Name: _pika-ui.scss
// Description: Styles for the Datepicker
// ------------------------------------------------------------
.pika-single {
    .pika-title, .pika-title select {
        color: $black;
    }
    table{
        border-spacing: 0;
    }
    .pika-table th {
        color: $black
    }

    .pika-button {
        text-align: center;
        background-color: transparent;
        border-radius: 0;
        color: $black;
        line-height: 12px;
        padding: 10px 5px;

        &:hover {
            background-color: $brand-jquery-ui-light-blue !important;
            border-radius: 0 !important;
            color: $black !important;
        }
    }

    .is-today .pika-button {
        color: $black;
        font-weight: bold;
    }

    .is-selected .pika-button {
        background-color: $brand-jquery-ui-blue;
        border-radius: 0;
        font-weight: 400;
    }

    .is-selected.is-today {
        .pika-button {
            color: $white;
        }
    }
}
